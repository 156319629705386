/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import {
  SURVEY_ACTION_ERROR,
  GET_ALL_SURVEYS,
  GET_ALL_SURVEYS_SUCCESS,
  ADD_SURVEY,
  ADD_SURVEY_SUCCESS,
  DELETE_SURVEY,
  DELETE_SURVEY_SUCCESS,
  UPDATE_SURVEY,
  UPDATE_SURVEY_SUCCESS,
} from "../saga/survey/actions";
import { SurveyReducer } from "../interfaces/survey/SurveyReducer";
import { SurveyActionTypes } from "../interfaces/survey/SurveyActionTypes";

export const initialState: SurveyReducer = {
  isFetching: false,
  error: null,
  surveys: [],
  surveyPages: 0,
};

export const surveyReducer = (
  state: SurveyReducer = { ...initialState },
  action: SurveyActionTypes
): SurveyReducer => {
  switch (action.type) {
    case GET_ALL_SURVEYS:
      return { ...state, isFetching: true, error: null };
    case GET_ALL_SURVEYS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        surveys: action.payload.surveys,
        surveyPages: action.payload.pages,
      };
    case SURVEY_ACTION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ADD_SURVEY:
      return { ...state, isFetching: true, error: null };
    case ADD_SURVEY_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case DELETE_SURVEY:
      return { ...state, isFetching: true, error: null };
    case DELETE_SURVEY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        surveys: state.surveys.filter((item) => item.id !== action.payload),
      };
    case UPDATE_SURVEY:
      return { ...state, isFetching: true, error: null };
    case UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        surveys: state.surveys.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    default:
      return state;
  }
};
