/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { store } from "../store/store";
import { JWTUtil } from "../utils/jwt.util";
import { ExceptionType } from "../enums/ExceptionType";
import authService from "./auth.service";
import { networkActionError } from "../saga/network/actions";
import { clearAuthenticatedUser } from "../saga/user/actions";
import { appConstants } from "../constants/app.constants";
import { Cookies as ReactCookies } from "react-cookie";

export const requiredHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

async function logoutNow(error: any) {
  const { dispatch } = store;
  await dispatch(clearAuthenticatedUser());
  await dispatch(
    networkActionError({ message: ExceptionType.TokenExpiredException })
  );
  return Promise.reject(error);
}

function useRequestConfig(requestConfig: AxiosRequestConfig) {
  const cookies = new ReactCookies();
  const refreshToken = cookies.get(appConstants.REFRESH_TOKEN_KEY);
  const token = store?.getState().userReducer.token;
  return JWTUtil.checkTokenExists(token)
    .then((token) => {
      if (!JWTUtil.isTokenExpired(token)) {
        return Promise.resolve(token);
      }
      return JWTUtil.checkTokenExists(refreshToken).then((refreshToken) => {
        if (!JWTUtil.isTokenExpired(refreshToken)) {
          return Promise.resolve(authService.refreshTokenRequest(refreshToken));
        } else {
          return Promise.reject(ExceptionType.RefreshTokenExpiredException);
        }
      });
    })
    .then((finalToken) => {
      requestConfig!.headers!.Authorization = `Bearer ${finalToken}`;
      return Promise.resolve(requestConfig);
    })
    .catch((error) => logoutNow(error));
}

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: requiredHeaders,
  timeout: 10000,
});

axiosService.interceptors.request.use(
  (config: AxiosRequestConfig) => useRequestConfig(config),
  (error: AxiosError) => {
    if (!error.response) {
      store.dispatch(networkActionError(error));
    } else {
      store.dispatch(networkActionError(JSON.stringify(error.response.data)));
    }
    return Promise.reject(error);
  }
);

const axiosServiceWithoutToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: requiredHeaders,
  timeout: 5000,
});

axiosServiceWithoutToken.interceptors.response.use(
  undefined,
  (error: AxiosError) => {
    if (!error.response) {
      store.dispatch(networkActionError(error));
    } else {
      store.dispatch(networkActionError(JSON.stringify(error.response.data)));
    }
    return Promise.reject(error);
  }
);

export { axiosService, axiosServiceWithoutToken, logoutNow };
