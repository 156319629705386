/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import images from "../../../assets/images/images";
import { productTableOptions } from "../../../constants/table.constants";
import LocalizationContext from "../../../context/LocalizationContext";
import { PageableQuery } from "../../../interfaces/pagination/PageableQuery";
import ProductType from "../../../interfaces/payment/ProductType";
import { deleteProduct, getAllProducts } from "../../../saga/payment/actions";
import { AppState } from "../../../store/store";
import { StyledSectionWrapper } from "../../components/StyledSectionWrapper";
import { StyledTable } from "../../components/StyledTable";
import colors from "../../../assets/styles/_colors.module.scss";
import { StyledModal } from "../../components/StyledModal";
import { SubscriptionModal } from "./SubscriptionModal";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";

export const SubscriptionsScreen = () => {
  const dispatch: Dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [productLimit, setProductLimit] = useState(10);
  const [productSearchQuery, setProductSearchQuery] = useState("");
  const [modalType, setModalType] = useState<"ADD" | "EDIT" | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<ProductType | null>(
    null
  );

  const isFetching: boolean = useSelector(
    (state: AppState) => state.paymentReducer.isFetching
  );

  const error: string | null = useSelector(
    (state: AppState) => state.paymentReducer.error
  );

  const products: ProductType[] | null = useSelector(
    (state: AppState) => state.paymentReducer.products
  );

  const fetchProducts = (page: number, limit: number) => {
    let query: PageableQuery = {
      page,
      limit,
    };

    if (productSearchQuery && productSearchQuery.length > 0) {
      query = {
        ...query,
        search: productSearchQuery,
      };
    }

    dispatch(getAllProducts(query));
  };

  const gaEventTracker = useAnalyticsEventTracker('Subscription');
  
  useEffect(() => {
    const timeout = setTimeout(
      () => {
          fetchProducts(1, productLimit);
          gaEventTracker("Product search");
      },
      productSearchQuery && productSearchQuery.length > 0 ? 500 : 0
    );
    
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productSearchQuery]);

  useEffect(() => {
    if (!isFetching && !error) {
      setModalType(null);
    }

    if (!isFetching) {
      setProductLimit(10);
    }
  }, [isFetching]);

  const onProductDelete = (productId: string) => {
    setIsDeleteModalOpen(false);
    setSelectedProduct(null);
    dispatch(deleteProduct(productId));
    gaEventTracker("Delete product");
  };

  const addSubscription = () => {
    if(!!products && products.length >= 10){
      setModalVisible(true);
    } else {
      setModalType("ADD");
    }
  }

  return (
    <div id="subscriptionScreen">
      <StyledSectionWrapper
        id="subscriptionsListContainer"
        label={t("subscriptionScreen.subscriptionsList")}
        withSearch={true}
        searchQuery={productSearchQuery}
        onSearchQueryChange={setProductSearchQuery}
      >
        {isFetching ? (
          <div className="progressWrapper">
            <CircularProgress style={{ color: colors.primary }} />
          </div>
        ) : (
          <div className="tableWrapper">
            <StyledTable
              tableOptions={productTableOptions()}
              data={products ? products : []}
              emptyInfo={t("subscriptionScreen.noProducts")}
              onDelete={(item) => {
                setSelectedProduct(item);
                setIsDeleteModalOpen(true);
              }}
              onEdit={(item) => {
                setSelectedProduct(item);
                setModalType("EDIT");
              }}
            />
            <img
              className="fabButtonIcon"
              src={images.fabButtonIcon}
              alt=""
              onClick={() => addSubscription()}
            />
          </div>
        )}
      </StyledSectionWrapper>
      {modalType && (
        <SubscriptionModal
          product={selectedProduct}
          modalType={modalType}
          onCloseButtonClick={() => {
            setModalType(null);
            setSelectedProduct(null);
          }}
          error={error}
        />
      )}
      {isDeleteModalOpen && (
        <StyledModal
          header={t("subscriptionScreen.deleteSubscriptionLabel")}
          onAcceptButtonClick={() => onProductDelete(selectedProduct!.id!)}
          acceptButtonLabel={t("global.delete")}
          acceptButtonType="button"
          onCloseButtonClick={() => setIsDeleteModalOpen(false)}
        >
          <div className="deleteModalContent">
            <span>{t("subscriptionScreen.deleteSubscriptionInfo")}</span>
          </div>
        </StyledModal>
      )}
      {modalVisible && (
        <StyledModal
          header={t("appBar.subscriptions")}
          onAcceptButtonClick={() => {
            setModalVisible(false);
          }}
          acceptButtonLabel={t("global.ok")}
          acceptButtonType="button"
          withoutCancelButton={true}
        >
          <div className="deleteModalContent">
            <span>{t("global.numberOfSubscriptions")}</span>
          </div>
        </StyledModal>
      )}
    </div>
  );
};
