/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { TrainingTypeType } from "../../enums/TrainingTypeType";

interface Props {
  label?: string;
  value: TrainingTypeType;
  onChange: (newValue: TrainingTypeType) => void;
  data: { value: string; label: string }[];
}

export const ToggleButtons = (props: Props) => {
  const { label, value, onChange, data } = props;

  const handleTrainingType = (event: any, type: TrainingTypeType) => {
    if (type !== null) {
      onChange(type);
    }
  };

  return (
    <div className="toggleButtonsWrapper">
      {label && <span>{label}</span>}
      <ToggleButtonGroup
        size="small"
        value={value}
        exclusive
        onChange={handleTrainingType}
        aria-label="text alignment"
      >
        {data.map((item, idx) => (
          <ToggleButton
            key={`tb${idx}`}
            value={item.value}
            aria-label="left aligned"
          >
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};
