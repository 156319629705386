/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { PageableQuery } from "../../interfaces/pagination/PageableQuery";
import { AddTrainingUserRequest } from "../../interfaces/training/AddTrainingUserRequest";
import GenerateManyCertificatesType from "../../interfaces/training/GenerateManyCertificatesType";
import GenerateOneCertificateType from "../../interfaces/training/GenerateOneCertificateType";
import { SendLinkRequestType } from "../../interfaces/training/SendLinkRequestType";
import { TrainingActionTypes } from "../../interfaces/training/TrainingActionTypes";
import TrainingType from "../../interfaces/training/TrainingType";
import TrainingUserType from "../../interfaces/training/TrainingUserType";
import TrainingWithTrainingUsersType from "../../interfaces/training/TrainingWithTrainingUsersType";
import { UpdateTrainingUserRequest } from "../../interfaces/training/UpdateTrainingUserRequest";

export const GET_ALL_TRAININGS = "GET_ALL_TRAININGS";
export const GET_ALL_TRAININGS_SUCCESS = "GET_ALL_TRAININGS_SUCCESS";
export const TRAINING_ACTION_ERROR = "TRAINING_ACTION_ERROR";
export const ADD_TRAINING = "ADD_TRAINING";
export const ADD_TRAINING_SUCCESS = "ADD_TRAINING_SUCCESS";
export const DELETE_TRAINING = "DELETE_TRAINING";
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS";
export const UPDATE_TRAINING = "UPDATE_TRAINING";
export const UPDATE_TRAINING_SUCCESS = "UPDATE_TRAINING_SUCCESS";
export const ADD_TRAINING_USER = "ADD_TRAINING_USER";
export const ADD_TRAINING_USER_SUCCESS = "ADD_TRAINING_USER_SUCCESS";
export const DELETE_TRAINING_USER = "DELETE_TRAINING_USER";
export const DELETE_TRAINING_USER_SUCCESS = "DELETE_TRAINING_USER_SUCCESS";
export const UPDATE_TRAINING_USER = "UPDATE_TRAINING_USER";
export const UPDATE_TRAINING_USER_SUCCESS = "UPDATE_TRAINING_USER_SUCCESS";
export const GET_TRAINING_USERS = "GET_TRAINING_USERS";
export const GET_TRAINING_USERS_SUCCESS = "GET_TRAINING_USERS_SUCCESS";
export const GENERATE_ONE_CERTIFICATE = "GENERATE_ONE_CERTIFICATE";
export const GENERATE_ONE_CERTIFICATE_SUCCESS = "GENERATE_ONE_CERTIFICATE_SUCCESS";
export const GET_ALL_TRAININGS_WITH_TRAINING_USERS = "GET_ALL_TRAININGS_WITH_TRAINING_USERS";
export const GET_ALL_TRAININGS_WITH_TRAINING_USERS_SUCCESS = "GET_ALL_TRAININGS_WITH_TRAINING_USERS_SUCCESS";
export const GENERATE_MANY_CERTIFICATES = "GENERATE_MANY_CERTIFICATES";
export const GENERATE_MANY_CERTIFICATES_SUCCESS = "GENERATE_MANY_CERTIFICATES_SUCCESS";
export const DOES_LINK_EXIST = "DOES_LINK_EXIST";
export const DOES_LINK_EXIST_SUCCESS = "DOES_LINK_EXIST_SUCCESS";
export const CREATE_LINK = 'CREATE_LINK';
export const SEND_LINK = 'SEND_LINK';
export const SEND_LINK_SUCCESS = 'SEND_LINK_SUCCESS';


export function getAllTrainings(
  userId: string,
  query: PageableQuery
): TrainingActionTypes {
  return {
    type: GET_ALL_TRAININGS,
    payload: {
      userId,
      query,
    },
  };
}

export function getAllTrainingsSuccess(
  trainings: TrainingType[],
  pages: number
): TrainingActionTypes {
  return {
    type: GET_ALL_TRAININGS_SUCCESS,
    payload: {
      trainings,
      pages,
    },
  };
}

export function getAllTrainingsWithTrainingUsers(
  userId: string,
  query: PageableQuery
): TrainingActionTypes {
  return {
    type: GET_ALL_TRAININGS_WITH_TRAINING_USERS,
    payload: {
      userId,
      query,
    },
  };
}

export function getAllTrainingsWithTrainingUsersSuccess(
  trainings: TrainingWithTrainingUsersType[],
  pages: number
): TrainingActionTypes {
  return {
    type: GET_ALL_TRAININGS_WITH_TRAINING_USERS_SUCCESS,
    payload: {
      trainings,
      pages,
    },
  };
}


export function trainingActionError(error: string | null): TrainingActionTypes {
  return {
    type: TRAINING_ACTION_ERROR,
    payload: error,
  };
}

export function addTraining(data: TrainingType): TrainingActionTypes {
  return {
    type: ADD_TRAINING,
    payload: data,
  };
}

export function addTrainingSuccess(
  training: TrainingType
): TrainingActionTypes {
  return {
    type: ADD_TRAINING_SUCCESS,
    payload: training,
  };
}

export function addTrainingUser(
  data: AddTrainingUserRequest
): TrainingActionTypes {
  return {
    type: ADD_TRAINING_USER,
    payload: data,
  };
}

export function addTrainingUserSuccess(
  user: TrainingUserType
): TrainingActionTypes {
  return {
    type: ADD_TRAINING_USER_SUCCESS,
    payload: user,
  };
}

export function getTrainingUsers(
  trainingId: string,
  query: PageableQuery
): TrainingActionTypes {
  return {
    type: GET_TRAINING_USERS,
    payload: {
      trainingId,
      query,
    },
  };
}

export function getTrainingUsersSuccess(
  trainingUsers: TrainingUserType[],
  pages: number
): TrainingActionTypes {
  return {
    type: GET_TRAINING_USERS_SUCCESS,
    payload: {
      trainingUsers,
      pages,
    },
  };
}

export function deleteTrainingUser(
  trainingUserId: string
): TrainingActionTypes {
  return {
    type: DELETE_TRAINING_USER,
    payload: trainingUserId,
  };
}

export function deleteTrainingUserSuccess(
  trainingUserId: string
): TrainingActionTypes {
  return {
    type: DELETE_TRAINING_USER_SUCCESS,
    payload: trainingUserId,
  };
}

export function deleteTraining(trainingId: string): TrainingActionTypes {
  return {
    type: DELETE_TRAINING,
    payload: trainingId,
  };
}

export function deleteTrainingSuccess(trainingId: string): TrainingActionTypes {
  return {
    type: DELETE_TRAINING_SUCCESS,
    payload: trainingId,
  };
}

export function updateTrainingUser(
  data: UpdateTrainingUserRequest
): TrainingActionTypes {
  return {
    type: UPDATE_TRAINING_USER,
    payload: data,
  };
}

export function updateTrainingUserSuccess(
  user: TrainingUserType
): TrainingActionTypes {
  return {
    type: UPDATE_TRAINING_USER_SUCCESS,
    payload: user,
  };
}

export function updateTraining(training: TrainingType): TrainingActionTypes {
  return {
    type: UPDATE_TRAINING,
    payload: training,
  };
}

export function updateTrainingSuccess(
  training: TrainingType
): TrainingActionTypes {
  return {
    type: UPDATE_TRAINING_SUCCESS,
    payload: training,
  };
}

export function generateOneCertificate(certificate: GenerateOneCertificateType): TrainingActionTypes {
  return {
    type: GENERATE_ONE_CERTIFICATE,
    payload: certificate,
  };
}

export function generateOneCertificateSuccess(certificate: string): TrainingActionTypes {
  return {
    type: GENERATE_ONE_CERTIFICATE_SUCCESS,
    payload: certificate,
  };
}

export function generateManyCertificates(certificate: GenerateManyCertificatesType): TrainingActionTypes {
  return {
    type: GENERATE_MANY_CERTIFICATES,
    payload: certificate,
  };
}

export function generateManyCertificatesSuccess(zip: string): TrainingActionTypes {
  return {
    type: GENERATE_MANY_CERTIFICATES_SUCCESS,
    payload: zip,
  };
}

export function doesLinkExist(link: string): TrainingActionTypes {
  return {
    type: DOES_LINK_EXIST,
    payload: link
  };
}

export function doesLinkExistSuccess(doesLinkExist: boolean): TrainingActionTypes {
  return {
    type: DOES_LINK_EXIST_SUCCESS,
    payload: doesLinkExist,
  };
}

export function createLink(link: string, withEmailSend?: boolean,  withOpenNewMeetTab?: boolean): TrainingActionTypes {
  return {
    type: CREATE_LINK,
    payload: {link, withEmailSend, withOpenNewMeetTab},
  };
}

export function sendLink(payload: SendLinkRequestType): TrainingActionTypes {
  return {
    type: SEND_LINK,
    payload,
  };
}