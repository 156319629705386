/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import jwtDecode from "jwt-decode";
import authService from "../services/auth.service";
import { ExceptionType } from "../enums/ExceptionType";
import { logoutNow } from "../services/axios.service";
import { store } from "../store/store";
import { useCookies } from "react-cookie";
import { appConstants } from "../constants/app.constants";
import { Cookies as ReactCookies } from "react-cookie";

interface JWTTokenType {
  email: string;
  exp: number;
  iat: number;
  privileges: any[];
  tenantId: string;
  userId: string;
}

const decode = (token: string) => {
  let decoded: JWTTokenType = jwtDecode(token);
  return decoded;
};

const isTokenExpired = (token: string) => {
  const expiresMillis = decode(token).exp * 1000;
  const nowMillis = Date.now();
  return expiresMillis < nowMillis;
};

function checkTokenExists(token: string) {
  if (token) {
    return Promise.resolve(token);
  }
  return Promise.reject(new Error(ExceptionType.NoTokenException));
}

async function retrieveToken(getToken: (token: string | undefined) => void) {
  const token = store?.getState().userReducer.token;
  const cookies = new ReactCookies();
  const refreshToken = cookies.get(appConstants.REFRESH_TOKEN_KEY);
  await JWTUtil.checkTokenExists(token)
    .then((token) => {
      if (!JWTUtil.isTokenExpired(token)) {
        getToken(token);
        return Promise.resolve(token);
      }
      return JWTUtil.checkTokenExists(refreshToken).then((refreshToken) => {
        if (!JWTUtil.isTokenExpired(refreshToken)) {
          return Promise.resolve(authService.refreshTokenRequest(refreshToken));
        } else {
          return Promise.reject(ExceptionType.RefreshTokenExpiredException);
        }
      });
    })
    .then((finalToken) => {
      getToken(finalToken);
      return Promise.resolve(finalToken);
    })
    .catch((error) => logoutNow(error));
}

export const JWTUtil = {
  decode,
  isTokenExpired,
  checkTokenExists,
  retrieveToken,
};
