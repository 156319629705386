/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { PageableQuery } from "../../interfaces/pagination/PageableQuery";
import AddProductRequest from "../../interfaces/payment/AddProductRequest";
import { PaymentActionTypes } from "../../interfaces/payment/PaymentActionTypes";
import PaymentType from "../../interfaces/payment/PaymentType";
import ProductType from "../../interfaces/payment/ProductType";
import SubscriptionType from "../../interfaces/payment/SubscriptionType";

export const GET_ACTIVE_SUBSCRIPTION = "GET_ACTIVE_SUBSCRIPTION";
export const GET_ACTIVE_SUBSCRIPTION_SUCCESS =
  "GET_ACTIVE_SUBSCRIPTION_SUCCESS";
export const PAYMENT_ACTION_ERROR = "PAYMENT_ACTION_ERROR";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const GET_PAYMENTS_BY_USER_ID = "GET_PAYMENTS_BY_USER_ID";
export const GET_PAYMENTS_BY_USER_ID_SUCCESS = "GET_PAYMENTS_BY_USER_ID_SUCCESS"

export function getActiveSubscription(stylistId: string): PaymentActionTypes {
  return {
    type: GET_ACTIVE_SUBSCRIPTION,
    payload: stylistId,
  };
}

export function getActiveSubscriptionSuccess(
  subscription: SubscriptionType
): PaymentActionTypes {
  return {
    type: GET_ACTIVE_SUBSCRIPTION_SUCCESS,
    payload: subscription,
  };
}

export function paymentActionError(error: string | null): PaymentActionTypes {
  return {
    type: PAYMENT_ACTION_ERROR,
    payload: error,
  };
}

export function getAllProducts(query: PageableQuery): PaymentActionTypes {
  return {
    type: GET_ALL_PRODUCTS,
    payload: query,
  };
}

export function getAllProductsSuccess(
  products: ProductType[],
  pages: number
): PaymentActionTypes {
  return {
    type: GET_ALL_PRODUCTS_SUCCESS,
    payload: {
      products,
      pages,
    },
  };
}

export function addProduct(product: AddProductRequest): PaymentActionTypes {
  return {
    type: ADD_PRODUCT,
    payload: product,
  };
}

export function addProductSuccess(): PaymentActionTypes {
  return {
    type: ADD_PRODUCT_SUCCESS,
  };
}

export function updateProduct(product: ProductType): PaymentActionTypes {
  return {
    type: UPDATE_PRODUCT,
    payload: product,
  };
}

export function updateProductSuccess(): PaymentActionTypes {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
  };
}

export function deleteProduct(productId: string): PaymentActionTypes {
  return {
    type: DELETE_PRODUCT,
    payload: productId,
  };
}

export function deleteProductSuccess(): PaymentActionTypes {
  return {
    type: DELETE_PRODUCT_SUCCESS,
  };
}

export function getPaymentsByUserId(userId: string, query: PageableQuery): PaymentActionTypes {
  return {
    type: GET_PAYMENTS_BY_USER_ID,
    payload: {
      userId,
      query
    }
  };
}

export function getPaymentsByUserIdSuccess(
  payments: PaymentType[],
  pages: number
): PaymentActionTypes {
  return {
    type: GET_PAYMENTS_BY_USER_ID_SUCCESS,
    payload: {
      payments,
      pages,
    },
  };
}