/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

export enum DurationType {
  MONTHS = "MONTHS",
  YEARS = "YEARS",
  DAYS = "DAYS",
  WEEKS = "WEEKS"
}
