/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyleft 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LocalizationContext from "../../../../context/LocalizationContext";
import TableOptionsType from "../../../../interfaces/TableOptionsType";
import { DateTime } from "luxon";

interface Props {
  tableOptions: TableOptionsType;
  data: any[];
  emptyInfo: string;
}

export const UserPaymentsTable = (props: Props) => {
  const { tableOptions, data, emptyInfo } = props;
  const { t } = React.useContext(LocalizationContext);

  return (
    <>
      {data && data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{}} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableOptions.headers.map((item, idx) => (
                  <TableCell key={`tableHead${idx}`} align="left">
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idx) => (
                <TableRow
                  key={`tableRow-${idx}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    key={'tableCell-1-productName'}
                    component="th"
                    scope="row"
                  >
                    {row.product.name}
                  </TableCell>
                  <TableCell
                    key={'tableCell-1-creationDate'}
                    component="th"
                    scope="row"
                  >
                    {DateTime.fromISO(row.creationDate).toFormat("dd.LL.yyyy")}
                  </TableCell>
                  <TableCell
                    key={'tableCell-1-price'}
                    component="th"
                    scope="row"
                    align="left"
                    width={"100%"}
                  >
                    {Number(row.product.price).toFixed(2) + ' ' + t("subscriptionScreen.currency")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="emptyTable">{emptyInfo}</div>
      )}
    </>
  );
};
