import React from "react";
import images from "../../assets/images/images";

export const DefaultAvatar = () => (
  <img
    style={styles.defaultAvatar}
    src={images.defaultAvatar}
    alt=""
  />
)

const styles = {
  defaultAvatar: {
    width: '108.2px',
    height: '90.8px',
    borderRadius: 0,
  }
}