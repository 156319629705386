/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import LocalizationContext from "../../context/LocalizationContext";
import { QuestionWithHyperLink } from "../components/QuestionWithHyperLink";
import { StyledButton } from "../components/StyledButton";
import images from "../../assets/images/images";
import { useNavigate } from "react-router";

interface Props {
  children: any;
  header: string;
  buttonText: string;
  type: "login" | "register" | "resetPassword";
}

export const PreAuthFormWrapper = (props: Props) => {
  const { t } = React.useContext(LocalizationContext);
  const { children, header, buttonText, type } = props;
  const navigate = useNavigate();

  return (
    <div className="authFormContainer">
      <div id="contentStyle">
        <img src={images.logo} id="logo" alt="" />
        <span className="formHeader">{header}</span>
        <div id="form" style={{ marginBottom: 26 }}>
          {children}
        </div>
        <StyledButton buttonText={buttonText} type="submit" />
        {type === "login" ? (
          <QuestionWithHyperLink
            questionText={t("loginScreen.noAccount")}
            linkText={t("loginScreen.register")}
            onClick={() => {
              navigate("/registration", { replace: true });
            }}
          />
        ) : (
          <QuestionWithHyperLink
            questionText={type === "register" ? t("registrationScreen.alreadyHaveAccount"): t("resetPasswordScreen.alreadyPasswordChanged")}
            linkText={t("loginScreen.logIn")}
            onClick={() => {
              navigate("/login", { replace: true });
            }}
          />
        )}
      </div>
    </div>
  );
};
