/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import Resizer from "react-image-file-resizer";

export async function urlToBase64(
  url: string,
  callback: (base64data: string) => void
) {
  await fetch(url)
    .then((r) => r.blob())
    .then((blob) => {
      console.log(blob);
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        const base64data = reader.result;
        //@ts-ignore
        callback(base64data);
      };
    });
}

export const resizeFile = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

//Converts to color HSB object (code from here http://www.csgnetwork.com/csgcolorsel4.html with some improvements)
const rgb2hsb = (r: number, g: number, b: number) => {
  r /= 255; g /= 255; b /= 255; // Scale to unity.
  var minVal = Math.min(r, g, b),
    maxVal = Math.max(r, g, b),
    delta = maxVal - minVal,
    HSB = { hue: 0, sat: 0, bri: maxVal },
    del_R, del_G, del_B;

  if (delta !== 0) {
    HSB.sat = delta / maxVal;
    del_R = (((maxVal - r) / 6) + (delta / 2)) / delta;
    del_G = (((maxVal - g) / 6) + (delta / 2)) / delta;
    del_B = (((maxVal - b) / 6) + (delta / 2)) / delta;

    if (r === maxVal) { HSB.hue = del_B - del_G; }
    else if (g === maxVal) { HSB.hue = (1 / 3) + del_R - del_B; }
    else if (b === maxVal) { HSB.hue = (2 / 3) + del_G - del_R; }

    if (HSB.hue < 0) { HSB.hue += 1; }
    if (HSB.hue > 1) { HSB.hue -= 1; }
  }

  HSB.hue *= 360;
  HSB.sat *= 100;
  HSB.bri *= 100;

  return HSB;
}

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);
    return { r: r, g: g, b: b } as { r: number, g: number, b: number }
  }
  return { r: 0, g: 0, b: 0 };
}

export const colorHex2Hsb = (hex: string) => {
  let { r, g, b } = hexToRgb(hex);
  return rgb2hsb(r, g, b);
}


const valueToHex = (value: number) => {
  let hex = value.toString(16);
  if (hex.length < 2) {
    hex = '0' + hex;
  }
  return hex;
};

export const rgbToHex = (r: number, g: number, b:  number) => {
  return `#${valueToHex(Math.round(r))}${valueToHex(Math.round(g))}${valueToHex(Math.round(b))}`;
}

