/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { usersTableOptions } from "../../../constants/table.constants";
import LocalizationContext from "../../../context/LocalizationContext";
import { PageableQuery } from "../../../interfaces/pagination/PageableQuery";
import { AppState } from "../../../store/store";
import { StyledSectionWrapper } from "../../components/StyledSectionWrapper";
import colors from "../../../assets/styles/_colors.module.scss";
import { StyledModal } from "../../components/StyledModal";
import UserType from "../../../interfaces/user/UserType";
import {
  deleteUser,
  getAllUsers,
  toggleUserStatus,
} from "../../../saga/user/actions";
import { UserStatusType } from "../../../enums/UserStatusType";
import { UserTable } from "./UserTable";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";

export const UsersScreen = () => {
  const dispatch: Dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [userPage, setUserPage] = useState(0);
  const [userLimit, setUserLimit] = useState(10);
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isToggleStatusModalOpen, setIsToggleStatusModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const navigation = useNavigate();

  const isFetching: boolean = useSelector(
    (state: AppState) => state.userReducer.isFetching
  );

  const users: UserType[] | null = useSelector(
    (state: AppState) => state.userReducer.users
  );

  const userPages: number = useSelector(
    (state: AppState) => state.userReducer.userPages
  );

  const gaEventTracker = useAnalyticsEventTracker('User');
  
  const fetchUsers = (page: number, limit: number) => {
    setUserPage(page - 1);
    setUserLimit(limit);
    let query: PageableQuery = {
      page,
      limit,
      sortBy: 'subscriptionStatus:ASC'
    };

    if (userSearchQuery && userSearchQuery.length > 0) {
      query = {
        ...query,
        search: userSearchQuery,
      };
    }

    dispatch(getAllUsers(query));
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => {
          fetchUsers(1, userLimit);
          gaEventTracker("User search");
      },
      userSearchQuery && userSearchQuery.length > 0 ? 500 : 0
    );

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userSearchQuery]);

  const onProductDelete = (userId: string) => {
    setIsDeleteModalOpen(false);
    setSelectedUser(null);
    setUserSearchQuery("");
    setUserPage(0);
    setUserLimit(10);
    dispatch(deleteUser(userId));
    gaEventTracker("Delete")
  };

  const onToggleStatus = (userId: string, currentStatus: string) => {
    setIsToggleStatusModalOpen(false);
    setSelectedUser(null);
    dispatch(toggleUserStatus(userId, currentStatus));
    gaEventTracker(selectedUser!.status === UserStatusType.ACTIVE? "Deactivate": "Activate" )
  };

  return (
    <div id="usersScreen">
      <StyledSectionWrapper
        id="usersListContainer"
        label={t("usersScreen.usersList")}
        withPagination={true}
        pages={userPages}
        onLoadMore={(page: number, limit: number) => fetchUsers(page, limit)}
        withSearch={true}
        page={userPage}
        limit={userLimit}
        searchQuery={userSearchQuery}
        onSearchQueryChange={setUserSearchQuery}
      >
        {isFetching ? (
          <div className="progressWrapper">
            <CircularProgress style={{ color: colors.primary }} />
          </div>
        ) : (
          <div className="tableWrapper">
            <UserTable
              tableOptions={usersTableOptions()}
              data={users ? users : []}
              emptyInfo={t("usersScreen.noUsers")}
              onDelete={(item) => {
                setSelectedUser(item);
                setIsDeleteModalOpen(true);
              }}
              onToggle={(item) => {
                setSelectedUser(item);
                setIsToggleStatusModalOpen(true);
              }}
              redirectTo={(item) =>
                navigation(`/payments/${item.id}`, {
                  state: { name: item.firstName + " " + item.lastName },
                })
              }
            />
          </div>
        )}
      </StyledSectionWrapper>
      {isDeleteModalOpen && (
        <StyledModal
          header={t("usersScreen.areYouSureToDelete")}
          onAcceptButtonClick={() => onProductDelete(selectedUser!.id!)}
          acceptButtonLabel={t("global.delete")}
          acceptButtonType="button"
          onCloseButtonClick={() => setIsDeleteModalOpen(false)}
        >
          <div className="deleteModalContent">
            <span>{t("usersScreen.deleteUserInfo")}</span>
          </div>
        </StyledModal>
      )}
      {isToggleStatusModalOpen && (
        <StyledModal
          header={
            selectedUser!.status === UserStatusType.ACTIVE
              ? t("usersScreen.deactivateUser")
              : t("usersScreen.activateUser")
          }
          onAcceptButtonClick={() =>
            onToggleStatus(selectedUser!.id!, selectedUser!.status)
          }
          acceptButtonLabel={
            selectedUser!.status === UserStatusType.ACTIVE
              ? t("usersScreen.deactivate")
              : t("usersScreen.activate")
          }
          acceptButtonType="button"
          onCloseButtonClick={() => setIsToggleStatusModalOpen(false)}
        >
          <div className="deleteModalContent">
            <span>
              {selectedUser!.status === UserStatusType.ACTIVE
                ? `${t("usersScreen.areYouSureToDeactivate")} ${
                    selectedUser?.firstName
                  } ${selectedUser?.lastName}?`
                : `${t("usersScreen.areYouSureToActivate")} ${
                    selectedUser?.firstName
                  } ${selectedUser?.lastName}?`}
            </span>
          </div>
        </StyledModal>
      )}
    </div>
  );
};
