/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import TablePagination from "@mui/material/TablePagination";
import LocalizationContext from "../../context/LocalizationContext";

interface Props {
  onLoadMore?: (page: number, limit: number) => void;
  pages: number;
  page: number;
  limit: number;
}

export const PaginationBar = (props: Props) => {
  const { onLoadMore, pages = 0, page, limit } = props;
  const { t } = React.useContext(LocalizationContext);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (onLoadMore) {
      onLoadMore(newPage + 1, limit);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onLoadMore) {
      onLoadMore(1, parseInt(event.target.value, 10));
    }
  };

  const renderDisplayedRowsLabel = () => {
    return (
      <span>{`${t("trainingsScreen.page")} ${page + 1} ${t(
        "trainingsScreen.of"
      )} ${pages}`}</span>
    );
  };

  return (
    <div>
      <TablePagination
        component="div"
        count={pages * limit}
        page={page}
        rowsPerPageOptions={[10, 20, 30]}
        className="paginationBar"
        labelRowsPerPage={t("trainingsScreen.rowsPerPage")}
        labelDisplayedRows={renderDisplayedRowsLabel}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
