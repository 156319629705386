/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

 import { CircularProgress } from "@mui/material";
 import { useContext, useEffect, useState } from "react";
 import { useDispatch, useSelector } from "react-redux";
 import { Dispatch } from "redux";
 import { userPaymentsTableOptions } from "../../../../constants/table.constants";
 import LocalizationContext from "../../../../context/LocalizationContext";
 import { PageableQuery } from "../../../../interfaces/pagination/PageableQuery";
 import { AppState } from "../../../../store/store";
 import { StyledSectionWrapper } from "../../../components/StyledSectionWrapper";
 import colors from "../../../../assets/styles/_colors.module.scss";
import { UserPaymentsTable } from "./UserPaymentsTable";
import { getPaymentsByUserId } from "../../../../saga/payment/actions";
import PaymentType from "../../../../interfaces/payment/PaymentType";
import { useParams, useLocation, Link, NavLink } from "react-router-dom";
import useAnalyticsEventTracker from "../../../../hooks/useAnaliticsTracker";
 
 export const UserPaymentsScreen = () => {
   const dispatch: Dispatch = useDispatch();
   const { t } = useContext(LocalizationContext);
   const [paymentPage, setPaymentPage] = useState(0);
   const [paymentLimit, setPaymentLimit] = useState(10);
   const [paymentSearchQuery, setPaymentSearchQuery] = useState("");
   let { id } = useParams()
   const location: any = useLocation();

   const isFetching: boolean = useSelector(
     (state: AppState) => state.paymentReducer.isFetching
   );
 
   const payments: PaymentType[] | null = useSelector(
     (state: AppState) => state.paymentReducer.payments
   );
 
   const paymentPages: number = useSelector(
     (state: AppState) => state.paymentReducer.paymentPages
   );
 
   const gaEventTracker = useAnalyticsEventTracker('Payment');
   
   const fetchPaymentsByUserId = (userId: string, page: number, limit: number) => {
     setPaymentPage(page - 1);
     setPaymentLimit(limit);
     let query: PageableQuery = {
       page,
       limit,
     };
 
     if (paymentSearchQuery && paymentSearchQuery.length > 0) {
       query = {
         ...query,
         search: paymentSearchQuery,
       };
     }
 
     dispatch(getPaymentsByUserId(userId, query));
   };
 
   useEffect(() => {
     const timeout = setTimeout(
      () => {
          fetchPaymentsByUserId(id!, 1, paymentLimit);
          gaEventTracker("Payment search");
      },
      paymentSearchQuery && paymentSearchQuery.length > 0 ? 500 : 0
    );
     return () => {
       clearTimeout(timeout);
     };
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dispatch, paymentSearchQuery]);
 
   useEffect(() => {
     if (!isFetching) {
       setPaymentLimit(10);
     }
   }, [isFetching]);
 
 
   return (
     <div id="userPaymentsScreen">
       <StyledSectionWrapper
         id="usersListContainer"
         label={<><Link to="/users" style={{ textDecoration: "none", color: "black" }}>{t("usersScreen.usersList")}</Link><span>{` > ${location.state.name}`}</span></>}
         withPagination={true}
         pages={paymentPages}
         onLoadMore={(page: number, limit: number) => fetchPaymentsByUserId(id!, page, limit)}
         withSearch={true}
         page={paymentPage}
         limit={paymentLimit}
         searchQuery={paymentSearchQuery}
         onSearchQueryChange={setPaymentSearchQuery}
       >
         {isFetching ? (
           <div className="progressWrapper">
             <CircularProgress style={{ color: colors.primary }} />
           </div>
         ) : (
           <div className="tableWrapper">
             <UserPaymentsTable
               tableOptions={userPaymentsTableOptions()}
               data={payments ? payments : []}
               emptyInfo={t("usersScreen.noPayments")}
             />
           </div>
         )}
       </StyledSectionWrapper>
     </div>
   );
 };
 