/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { Link as MUILink } from "@mui/material";
import React from "react";

interface Props {
  questionText: string;
  linkText: string;
  onClick: () => void;
}

export const QuestionWithHyperLink = (props: Props) => {
  const { linkText, questionText, onClick } = props;

  return (
    <div className="hyperLinkComponent">
      <span>
        {questionText} <MUILink onClick={onClick}>{linkText}</MUILink>
      </span>
    </div>
  );
};
