/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import images from "../../../assets/images/images";
import LocalizationContext from "../../../context/LocalizationContext";
import SurveyType from "../../../interfaces/survey/SurveyType";
import UserType from "../../../interfaces/user/UserType";
import { AppState } from "../../../store/store";
import colors from "../../../assets/styles/_colors.module.scss";
import { StyledSectionWrapper } from "../../components/StyledSectionWrapper";
import { PageableQuery } from "../../../interfaces/pagination/PageableQuery";
import { deleteSurvey, getAllSurveys } from "../../../saga/survey/actions";
import { Dispatch } from "redux";
import { SurveyModal } from "./SurveyModal";
import classNames from "classnames";
import { SurveyInfo } from "./SurveyInfo";
import { StyledModal } from "../../components/StyledModal";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";

export const SurveysScreen = () => {
  const dispatch: Dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [selectedSurvey, setSelectedSurvey] = useState<SurveyType | null>(null);
  const [surveyPage, setSurveyPage] = useState(0);
  const [surveyLimit, setSurveyLimit] = useState(10);
  const [surveySearchQuery, setSurveySearchQuery] = useState("");
  const [surveyModalType, setSurveyModalType] = useState<"ADD" | "EDIT" | null>(
    null
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker('Survey');
  
  const authenticatedUser: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  const surveys: SurveyType[] = useSelector(
    (state: AppState) => state.surveyReducer.surveys
  );

  const surveysPages: number = useSelector(
    (state: AppState) => state.surveyReducer.surveyPages
  );

  const isFetching: boolean = useSelector(
    (state: AppState) => state.surveyReducer.isFetching
  );

  const error: string | null = useSelector(
    (state: AppState) => state.surveyReducer.error
  );

  const fetchSurveys = (page: number, limit: number) => {
    setSurveyPage(page - 1);
    setSurveyLimit(limit);
    let query: PageableQuery = {
      page,
      limit,
      sortBy: 'title:ASC',
    };

    if (surveySearchQuery && surveySearchQuery.length > 0) {
      query = {
        ...query,
        search: surveySearchQuery,
      };
    }

    dispatch(getAllSurveys(authenticatedUser!.id, query));
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => {
          fetchSurveys(1, surveyLimit);
          gaEventTracker("Survey search");
      },
      surveySearchQuery && surveySearchQuery.length > 0 ? 500 : 0
    );

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, surveySearchQuery]);

  useEffect(() => {
    if (selectedSurvey && !isFetching) {
      const survey = surveys.find((s) => s.id! === selectedSurvey.id!);
      if (survey) setSelectedSurvey(survey);
    }

    if (!isFetching && !error && (isDeleteModalOpen || !!surveyModalType)) {
      setIsDeleteModalOpen(false);
      setSurveyModalType(null);
    }
  }, [isFetching]);

  const onSurveyClick = (survey: SurveyType) => {
    if (selectedSurvey && selectedSurvey.id === survey.id) {
      setSelectedSurvey(null);
    } else {
      setSelectedSurvey(survey);
    }
  };

  const onSurveyDelete = (id: string) => {
    dispatch(deleteSurvey(id));
    setSelectedSurvey(null);
    setSurveySearchQuery("");
    setSurveyLimit(10);
    setSurveyPage(0);
    gaEventTracker("Delete Survey");
  };

  return (
    <div
      className={classNames("surveyScreen", {
        withSelectedSurvey: !!selectedSurvey,
      })}
    >
      <StyledSectionWrapper
        id="surveysListContainer"
        label={t("surveyScreen.surveysList")}
        withPagination={true}
        pages={surveysPages}
        onLoadMore={(page: number, limit: number) => fetchSurveys(page, limit)}
        withSearch={true}
        page={surveyPage}
        limit={surveyLimit}
        searchQuery={surveySearchQuery}
        onSearchQueryChange={setSurveySearchQuery}
      >
        {isFetching ? (
          <div className="progressWrapper">
            <CircularProgress style={{ color: colors.primary }} />
          </div>
        ) : (
          <>
            <div className="surveyRows">
              {surveys && surveys.length > 0 ? (
                surveys.map((survey, idx) => (
                  <div
                    className={classNames("trainingItemRow", {
                      selected:
                        selectedSurvey && selectedSurvey.id === survey.id,
                    })}
                    onClick={() => onSurveyClick(survey)}
                  >
                    <span>{survey.title}</span>
                  </div>
                ))
              ) : (
                <div className="emptyTable">{t("surveyScreen.noSurveys")}</div>
              )}
              <img
                className="fabButtonIcon"
                src={images.fabButtonIcon}
                alt=""
                onClick={() => setSurveyModalType("ADD")}
              />
            </div>
          </>
        )}
      </StyledSectionWrapper>
      {selectedSurvey && (
        <StyledSectionWrapper
          id="surveyDetailsContainer"
          label={t("surveyScreen.surveyInfoTitle")}
        >
          {isFetching ? (
            <div className="progressWrapper">
              <CircularProgress style={{ color: colors.primary }} />
            </div>
          ) : (
            <SurveyInfo
              survey={selectedSurvey}
              onDelete={() => setIsDeleteModalOpen(true)}
              onEdit={() => setSurveyModalType("EDIT")}
            />
          )}
        </StyledSectionWrapper>
      )}
      {!!surveyModalType && (
        <SurveyModal
          survey={surveyModalType === "EDIT" ? selectedSurvey : null}
          error={error}
          onCloseButtonClick={() => setSurveyModalType(null)}
        />
      )}
      {isDeleteModalOpen && (
        <StyledModal
          header={t("surveyScreen.deleteSurveyLabel")}
          onAcceptButtonClick={() => onSurveyDelete(selectedSurvey!.id!)}
          acceptButtonLabel={t("global.delete")}
          acceptButtonType="button"
          onCloseButtonClick={() => setIsDeleteModalOpen(false)}
        >
          <div className="deleteModalContent">
            <span>{t("surveyScreen.deleteSurveyInfo")}</span>
          </div>
        </StyledModal>
      )}
    </div>
  );
};
