import { t } from 'i18next';
import { AsyncPaginate } from 'react-select-async-paginate'
import Select from 'react-select'

interface Props {
  value: string | null;
  onChange: any;
  loadOptions: any;
  isMulti?: boolean;
  isDisabled?: boolean;
  label: string;
  additional?: any;
  error: string | null;
  isAsync?: boolean;
}
const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ae5a6f' : state.isFocused ? 'rgba(174, 90, 111, 0.4)' : state.isActive ? 'rgba(174, 90, 111, 0.4)' : 'inherit',
  }),
}

export const StyledDropdownSelectWithPagination = (props: Props) => {
  const { value, onChange, loadOptions, isMulti, isDisabled, label, additional, error, isAsync } = props;
  console.log(value)
  return (
    <div style={{ marginBottom: '1em' }}>
      {isAsync ?
        <AsyncPaginate
          menuPlacement="top"
          value={value}
          className="select"
          classNamePrefix="select"
          loadOptions={loadOptions}
          styles={customStyles}
          isMulti={isMulti}
          isDisabled={isDisabled}
          placeholder={label}
          onChange={onChange}
          additional={additional}
          noOptionsMessage={({ inputValue }) => !inputValue ? t("certificatesScreen.noOptions") : t("certificatesScreen.noOptions")}
        /> :
        <Select
          menuPlacement="top"
          value={value}
          className="select"
          classNamePrefix="select"
          options={loadOptions}
          styles={customStyles}
          isMulti={isMulti}
          isDisabled={isDisabled}
          placeholder={label}
          onChange={onChange}
          noOptionsMessage={({ inputValue }) => !inputValue ? t("certificatesScreen.noOptions") : t("certificatesScreen.noOptions")}
        />}
      <div style={{ height: 10 }}>
        {error && <p className="errorMessage">{error}</p>}
      </div>
    </div>
  )
}
