/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyleft 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableOptionsType from "../../interfaces/TableOptionsType";
import { StyledButton } from "./StyledButton";
import LocalizationContext from "../../context/LocalizationContext";
import images from "../../assets/images/images";

interface Props {
  tableOptions: TableOptionsType;
  data: any[];
  onDelete: (item: any) => void;
  emptyInfo: string;
  isUserTable?: boolean;
  onEdit: (item: any) => void;
}

export const StyledTable = (props: Props) => {
  const { tableOptions, data, onDelete, onEdit, emptyInfo } = props;
  const { t } = React.useContext(LocalizationContext);
  return (
    <>
      {data && data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{}} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableOptions.headers.map((item, idx) => (
                  <TableCell key={`tableHead${idx}`} align="left">
                    {item}
                  </TableCell>
                ))}
                <TableCell className="buttonsHeader" />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idx) => (
                <TableRow
                  key={`tableRow-${idx}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tableOptions.keys.map((key) => (
                    <TableCell
                      key={`tableCell-${idx}-${key}`}
                      component="th"
                      scope="row"
                    >
                      {tableOptions.customComponents &&
                      tableOptions.customComponents[key]
                        ? tableOptions.customComponents[key](row)
                        : row[key]
                        ? row[key]
                        : "–"}
                    </TableCell>
                  ))}
                  <TableCell
                    component="th"
                    scope="row"
                    className={"buttonsCell"}
                  >
                    <StyledButton
                      buttonText={t("global.delete")}
                      type="button"
                      onClick={() => onDelete(row)}
                    />
                    <StyledButton
                      buttonText={t("global.edit")}
                      type="button"
                      onClick={() => onEdit(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="emptyTable">{emptyInfo}</div>
      )}
    </>
  );
};
