/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import images from "../../../assets/images/images";
import LocalizationContext from "../../../context/LocalizationContext";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";
import SurveyType from "../../../interfaces/survey/SurveyType";
import { showMessage } from "../../../saga/network/actions";
import { StyledButton } from "../../components/StyledButton";

interface Props {
  survey: SurveyType;
  onDelete: () => void;
  onEdit: () => void;
}

export const SurveyInfo = (props: Props) => {
  const { survey, onDelete, onEdit } = props;
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(survey.link);
    dispatch(showMessage(t("surveyScreen.linkCopied")));
  };

  return (
    <div id="surveyInfoContainer">
      <span id="surveyTitleSpan">{survey.title}</span>
      <div className="descriptionContainer">
        <span>{t("surveyScreen.surveyInfo")}</span>
        <p>{survey.description}</p>
      </div>
      {survey.link && (
        <div className="contactRow">
          <img src={images.wwwIcon} alt="link" />
          <span>{survey.link}</span>
          <img
            className="copyIcon"
            src={images.copyIcon}
            alt="copy"
            onClick={copyToClipboard}
          />
        </div>
      )}
      <div className="buttonsContainer">
        <StyledButton
          buttonText={t("global.delete")}
          type="button"
          onClick={onDelete}
        />
        <StyledButton
          buttonText={t("global.edit")}
          type="button"
          onClick={onEdit}
        />
      </div>
    </div>
  );
};
