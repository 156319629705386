/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/
import i18next from "i18next";

export const STYLIST_TABS = [
  {
    label: i18next.t("appBar.courses"),
    path: "/trainings",
  },
  {
    label: i18next.t("appBar.surveys"),
    path: "/surveys",
  },
  {
    label: i18next.t("appBar.certificates"),
    path: "/certificates",
  },
  {
    label: i18next.t("appBar.salonInfo"),
    path: "/salon",
  },
];

export const ADMIN_TABS = [
  {
    label: i18next.t("appBar.userList"),
    path: "/users",
  },
  {
    label: i18next.t("appBar.subscriptions"),
    path: "/subscriptions",
  },
];
