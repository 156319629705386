/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React, { useLayoutEffect } from "react";
import LocalizationContext from "../../../context/LocalizationContext";
import { useForm, Controller } from "react-hook-form";
import { PreAuthFormWrapper } from "../PreAuthFormWrapper";
import { QuestionWithHyperLink } from "../../components/QuestionWithHyperLink";
import { StyledTextInput } from "../../components/StyledTextInput";
import { emailPattern, passwordPattern } from "../../../utils/validation.utils";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { login, verifyEmail } from "../../../saga/user/actions";
import images from "../../../assets/images/images";
import { useLocation, useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";

type FormValues = {
  email: string;
  password: string;
};

export const LoginScreen = (props: any) => {
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const location: any = useLocation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues
  } = useForm<FormValues>();

  useLayoutEffect(() => {
    if (location.state && location.state.token) {
      dispatch(verifyEmail(location.state.token))
    }
  }, [])

  const onKeySubmit = (e: React.KeyboardEvent) => {
    if(e.code === 'Enter' || e.code === 'NumpadEnter'){
      e.preventDefault();
      handleSubmit((data) => dispatch(login(data)))();
    }
  }

  const gaEventTracker = useAnalyticsEventTracker('Login');

  return (
    <div
      id="screenContainer"
      style={{
        backgroundImage: `url(${images.authBackground})`,
      }}
    >
      <form
        className={"loginForm"}
        onSubmit={handleSubmit((data) => {
          dispatch(login(data));
          gaEventTracker('Login');
        })}
        onKeyPress={(e) => onKeySubmit(e)}
      >
        <PreAuthFormWrapper
          header={t("loginScreen.title")}
          buttonText={t("loginScreen.logIn")}
          type="login"
        >
          <div className="formWrapper">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledTextInput
                  error={errors.email && errors.email.message}
                  value={value}
                  width={511}
                  onChange={onChange}
                  label={t("loginScreen.email")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                pattern: {
                  value: emailPattern,
                  message: t("formValidation.emailPattern"),
                },
              }}
            />
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.password && errors.password.message}
                  value={value}
                  width={511}
                  type="password"
                  onChange={onChange}
                  label={t("loginScreen.password")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                pattern: {
                  value: passwordPattern,
                  message: t("formValidation.passwordPattern"),
                },
              }}
            />
            <QuestionWithHyperLink
              questionText={t("loginScreen.forgotPassword")}
              linkText={t("loginScreen.resetPassword")}
              onClick={() => {
                navigate("/reset-password", { replace: true });
              }}
            />
          </div>
        </PreAuthFormWrapper>
      </form>
    </div>
  );
};
