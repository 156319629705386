/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { ReactNode } from "react";

export interface LocalizationContextType {
  t: (key: string, data?: {}) => string;
  i18n: {};
}

const LocalizationContext = React.createContext<LocalizationContextType>({
  t: () => "",
  i18n: {},
});

export default LocalizationContext;
