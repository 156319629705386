/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import pl from './locales/pl.json';

export const resources = {
  pl: {
    translation: pl,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'pl',
  compatibilityJSON: 'v3',
  fallbackLng: 'pl',
  debug: true,
  interpolation: {
    escapeValue: false, // react is already safe from xss
  },
});

export default i18n;
