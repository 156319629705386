/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React, { useContext } from "react";
import images from "../../assets/images/images";
import LocalizationContext from "../../context/LocalizationContext";
import SubscriptionType from "../../interfaces/payment/SubscriptionType";
import { DateTime } from "luxon";

interface Props {
  subscription: SubscriptionType | null;
}

export const SubscriptionPopup = (props: Props) => {
  const { subscription } = props;
  const { t } = useContext(LocalizationContext);

  const getDate = () => {
    if (subscription)
      return DateTime.fromISO(subscription.subscriptionEndDate).toFormat(
        "dd.LL.yyyy"
      );
    else return "";
  };

  return (
    <div className="subscriptionPopup">
      <img src={images.subscriptionStarIcon} alt="" />
      <div className="textContainer">
        <span className="activeSub">
          {t("salonInfoScreen.activeSubscription")}
        </span>
        {subscription && (
          <span className="activeTo">{`${t(
            "salonInfoScreen.to"
          )} ${getDate()} ${t("salonInfoScreen.yearShortcut")}`}</span>
        )}
      </div>
    </div>
  );
};
