/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import LocalizationContext from "../../../context/LocalizationContext";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";
import TrainingType from "../../../interfaces/training/TrainingType";
import TrainingUserType from "../../../interfaces/training/TrainingUserType";
import {
  addTrainingUser,
  updateTrainingUser,
} from "../../../saga/training/actions";
import { emailPattern } from "../../../utils/validation.utils";
import { StyledModal } from "../../components/StyledModal";
import { StyledTextInput } from "../../components/StyledTextInput";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

interface Props {
  onCloseButtonClick: () => void;
  training: TrainingType;
  user?: TrainingUserType;
  resetPage?: () => void;
}

export const AddStudentModal = (props: Props) => {
  const { onCloseButtonClick, training, user, resetPage } = props;
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    //@ts-ignore
    defaultValues: {
      ...user,
    },
  });

  const onSubmit = (data: FormValues) => {
    if (resetPage) resetPage();

    if (user) {
      dispatch(
        updateTrainingUser({
          ...data,
          id: user.id,
        })
      );
    } else {
      dispatch(
        addTrainingUser({
          ...data,
          training: training.id!,
        })
      );
    }
  };

  const gaEventTracker = useAnalyticsEventTracker('Training');
  
  return (
    <form onSubmit={handleSubmit((data) => {
      onSubmit(data);
      gaEventTracker(user? "Edit training user": "Add training user")
    })}>
      <StyledModal
        header={user ? t("trainingsScreen.editStudent") : t("trainingsScreen.addNewStudent")}
        onAcceptButtonClick={() => {}}
        acceptButtonType="submit"
        acceptButtonLabel={user ? t("global.save") : t("global.add")}
        onCloseButtonClick={onCloseButtonClick}
      >
        <div className="formWrapper">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.firstName && errors.firstName.message}
                value={!!value ? value.charAt(0).toUpperCase() + value.slice(1) : value}
                width={511}
                onChange={onChange}
                label={t("global.firstName")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 35,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${35} ${t("formValidation.characters")}`,
              },
            }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.lastName && errors.lastName.message}
                value={!!value ? value.charAt(0).toUpperCase() + value.slice(1) : value}
                width={511}
                onChange={onChange}
                label={t("global.lastName")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 35,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${35} ${t("formValidation.characters")}`,
              },
            }}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.email && errors.email.message}
                value={value}
                width={511}
                onChange={onChange}
                label={t("global.email")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 255,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${255} ${t("formValidation.characters")}`,
              },
              pattern: {
                value: emailPattern,
                message: t("formValidation.emailPattern"),
              },
            }}
          />
        </div>
      </StyledModal>
    </form>
  );
};
