/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import LocalizationContext from "../../context/LocalizationContext";
import { AppBar, Tab, Tabs, Box } from "@mui/material";
import { UserRoleType } from "../../enums/UserRoleType";
import { AppState } from "../../store/store";
import { StyledButton } from "../components/StyledButton";
import UserType from "../../interfaces/user/UserType";
import { logout } from "../../saga/user/actions";
import images from "../../assets/images/images";
import { useNavigate } from "react-router-dom";
import { ADMIN_TABS, STYLIST_TABS } from "../../constants/tabs.constants";

interface Props {
  children: any;
}

export const PageTemplate = (props: Props) => {
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>(0);

  const userRole: UserRoleType | null = useSelector(
    (state: AppState) => state.userReducer.userRole
  );

  const authenticatedUser: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  const userProfile: UserType | null = useSelector(
    (state: AppState) => state.userReducer.userProfile
  );

  const avatar: string | null = useSelector(
    (state: AppState) => state.userReducer.avatar
  );

  useEffect(() => {
    const path = window.location.pathname;
    if (path && path !== "/") {
      if (userRole === UserRoleType.STYLIST) {
        setTab(
          STYLIST_TABS.findIndex((t) => t.path === window.location.pathname)
        );
      } else {
        setTab(
          ADMIN_TABS.findIndex((t) => t.path === window.location.pathname)
        );
      }
    }
  }, []);

  const handleTabChange = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const getFirmName = () => {
    if (userRole === UserRoleType.STYLIST) {
      if (userProfile) {
        return userProfile.firmName;
      } else if (authenticatedUser) {
        return authenticatedUser.firmName;
      }
    }
    return "";
  };

  const renderTabs = () => {
    if (userRole === UserRoleType.STYLIST) {
      return (
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label={t("appBar.courses")} href="/trainings" />
          <Tab label={t("appBar.surveys")} href="/surveys" />
          <Tab label={t("appBar.certificates")} href="/certificates" />
          <Tab label={t("appBar.salonInfo")} href="/salon" />
        </Tabs>
      );
    } else {
      return (
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label={t("appBar.userList")} href="/users" />
          <Tab label={t("appBar.subscriptions")} href="/subscriptions" />
        </Tabs>
      );
    }
  };

  return (
    <div
      id="pageTemplate"
      style={{
        backgroundImage: `url(${images.backgroundGradient})`,
      }}
    >
      <AppBar id="appBar" position="static">
        <div className="logoWrapper">
          <img id="logo2" src={images.logo} alt="" />
        </div>
        <Box sx={{ borderBottom: 1, borderColor: "divider", height: "56px" }}>
          {renderTabs()}
        </Box>
        <div id="appBar_rightside">
          {userRole === UserRoleType.STYLIST && (
            <div id="appBar_avatar">
              <img
                id="appBar_avatar_photo"
                src={avatar ? `data:image/png;base64,${avatar}` : ""}
                alt=""
              />
              <img
                className="icon"
                src={images.subscriptionStarIcon}
                alt="sub_icon"
              />
            </div>
          )}
          <span>
            {userRole === UserRoleType.STYLIST
              ? getFirmName()
              : t("appBar.admin")}
          </span>
          <StyledButton
            type="button"
            buttonText={t("appBar.logout")}
            onClick={() => {
              dispatch(logout());
              navigate("/login");
            }}
          />
        </div>
      </AppBar>
      <div className="tabsContent">
        {props.children}
        <div id="version">
          <span>{`${t("global.version")} ${
            process.env.REACT_APP_VERSION
          }`}</span>
        </div>
      </div>
    </div>
  );
};
