import React from "react";
import images from "../../assets/images/images";

interface Props {
  children: any;
  onAdd?: () => void;
}

export const SectionWithFabButton = (props: Props) => {
  const { children, onAdd } = props;

  return (
    <div>
      {children}
      <div style={{ height: 56, width: '100%' }}>
        <img
          className="fabButtonIcon"
          src={images.fabButtonIcon}
          alt=""
          onClick={onAdd}
        />
      </div>
    </div>
  )
}