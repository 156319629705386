/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

const emailPattern = new RegExp("^[a-z0-9._%+-]+@[a-z0-9-]+([.][a-z]{2,4})+$");
const passwordPattern = new RegExp(
  "(?=^.{8,16}$)((?=.*\\d)(?=.*\\W)|(?=.*_))(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$"
);
const zipPattern = new RegExp("^[0-9]{2}-[0-9]{3}$");
const pricePattern = new RegExp("^([0]{1}|([1-9]{1}[0-9]*))([.][0-9]{2})?$");

const phonePattern = new RegExp("^[+]?[0-9]+$")

export { emailPattern, passwordPattern, zipPattern, pricePattern, phonePattern };
