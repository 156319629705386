/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { ImageUploadComponent } from "./ImageUploadComponent";
import LocalizationContext from "../../context/LocalizationContext";
import { urlToBase64 } from "../../utils/image.util";

interface Props {
  images: any[];
  selectedImage: any;
  onChange: (image: any) => void;
  style?: any;
}

export const ImagePicker = (props: Props) => {
  const { selectedImage, images, onChange, style } = props;
  const { t } = React.useContext(LocalizationContext);
  return (
    <div className="imagePicker_mainComponent" style={style}>
      <span>{t("trainingsScreen.selectOrAddImage")}</span>
      <div className="imagePicker">
        <img
          className="selectedImage"
          src={`data:image/png;base64,${selectedImage}`}
          alt=""
        />
        <div className={"minImagesContainer"}>
          {images.map((image, idx) => (
            <img
              key={`image${idx}`}
              className="minImage"
              src={image}
              alt=""
              onClick={() =>
                urlToBase64(image, (base64) => {
                  onChange(base64.split(",")[1]);
                })
              }
            />
          ))}
        </div>
        <ImageUploadComponent onChange={onChange} />
      </div>
    </div>
  );
};
