/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { put, takeLatest, select, takeEvery } from "redux-saga/effects";
import trainingService from "../../services/training.service";
import * as actions from "./actions";
import * as networkActions from "../network/actions";
import TrainingType from "../../interfaces/training/TrainingType";
import {
  AddTraining,
  AddTrainingUser,
  CreateLink,
  DeleteTraining,
  DeleteTrainingUser,
  DoesLinkExist,
  GenerateManyCertificates,
  GenerateOneCertificate,
  GetAllTrainings,
  GetAllTrainingsWithTrainingUsers,
  GetTrainingUsers,
  SendLink,
} from "../../interfaces/training/TrainingActionTypes";
import TrainingUserType from "../../interfaces/training/TrainingUserType";
import { PageableQuery } from "../../interfaces/pagination/PageableQuery";
import { AppState } from "../../store/store";
import UserType from "../../interfaces/user/UserType";
import { ExceptionUtil } from "../../utils/exception.util";
// @ts-ignore
import { triggerBase64Download } from 'common-base64-downloader-react';
import TrainingWithTrainingUsersType from "../../interfaces/training/TrainingWithTrainingUsersType";
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import i18next from "i18next";
import { DateTime } from "luxon";

function* getAllTrainings(action: GetAllTrainings) {
  try {
    // @ts-ignore
    const trainingsResponse: any = yield trainingService.getAllTrainings(
      action.payload
    );

    if (trainingsResponse.data.meta.totalItems === 0) {
      yield handleGetAllTrainingsSuccess(
        [],
        trainingsResponse.data.meta.totalPages
      );
      return;
    }

    yield handleGetAllTrainingsSuccess(
      trainingsResponse.data.data,
      trainingsResponse.data.meta.totalPages
    );
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleGetAllTrainingsSuccess(data: TrainingType[], pages: number) {
  yield put(actions.getAllTrainingsSuccess(data, pages));
}

export function* watchGetAllTrainingsSaga() {
  yield takeLatest(actions.GET_ALL_TRAININGS, getAllTrainings);
}

function* getAllTrainingsWithTrainingUsers(action: GetAllTrainingsWithTrainingUsers) {
  try {
    // @ts-ignore
    const trainingsResponse: any = yield trainingService.getAllTrainingsWithTrainingUsers(
      action.payload
    );

    if (trainingsResponse.data.meta.totalItems === 0) {
      yield handleGetAllTrainingsSuccess(
        [],
        trainingsResponse.data.meta.totalPages
      );
      return;
    }

    yield handleGetAllTrainingsWithTrainingUsers(
      trainingsResponse.data.data,
      trainingsResponse.data.meta.totalPages
    );
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleGetAllTrainingsWithTrainingUsers(data: TrainingWithTrainingUsersType[], pages: number) {
  yield put(actions.getAllTrainingsWithTrainingUsersSuccess(data, pages));
}

export function* watchGetAllTrainingsWithTrainingUsersSaga() {
  yield takeLatest(actions.GET_ALL_TRAININGS_WITH_TRAINING_USERS, getAllTrainingsWithTrainingUsers);
}

function* addTraining(action: AddTraining) {
  try {
    // @ts-ignore
    const trainingResponse: any = yield trainingService.addTraining(
      action.payload
    );

    yield handleAddTrainingSuccess(trainingResponse.data.training);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleAddTrainingSuccess(data: TrainingType) {
  let user: UserType = yield select(
    (state: AppState) => state.userReducer.user
  );

  let query: PageableQuery = {
    page: 1,
    limit: 10,
  };
  yield put(actions.addTrainingSuccess(data));
  yield put(actions.getAllTrainings(user.id, query));
}

export function* watchAddTrainingSaga() {
  yield takeLatest(actions.ADD_TRAINING, addTraining);
}

function* addTrainingUser(action: AddTrainingUser) {
  try {
    // @ts-ignore
    const trainingResponse: any = yield trainingService.addTrainingUser(
      action.payload
    );

    yield handleAddTrainingUserSuccess(trainingResponse.data.trainingUser);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleAddTrainingUserSuccess(data: TrainingUserType) {
  let selectedTraining: string = yield select(
    (state: AppState) => state.trainingReducer.selectedTraining
  );

  let query: PageableQuery = {
    page: 1,
    limit: 10,
    sortBy: 'lastName:ASC',
  };
  yield put(actions.getTrainingUsers(selectedTraining, query));
  yield put(actions.addTrainingUserSuccess(data));
}

export function* watchAddTrainingUserSaga() {
  yield takeLatest(actions.ADD_TRAINING_USER, addTrainingUser);
}

function* getTrainingUsers(action: GetTrainingUsers) {
  try {
    // @ts-ignore
    const trainingResponse: any = yield trainingService.getTrainingUsers(
      action.payload
    );

    if (trainingResponse.data.meta.totalItems === 0) {
      yield handleGetTrainingUsersSuccess(
        [],
        trainingResponse.data.meta.totalPages
      );
      return;
    }

    yield handleGetTrainingUsersSuccess(
      trainingResponse.data.data,
      trainingResponse.data.meta.totalPages
    );
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleGetTrainingUsersSuccess(
  data: TrainingUserType[],
  pages: number
) {
  yield put(actions.getTrainingUsersSuccess(data, pages));
}

export function* watchGetTrainingUsersSaga() {
  yield takeLatest(actions.GET_TRAINING_USERS, getTrainingUsers);
}

function* deleteTrainingUser(action: DeleteTrainingUser) {
  try {
    // @ts-ignore
    yield trainingService.deleteTrainingUser(action.payload);

    yield handleDeleteTrainingUserSuccess(action.payload);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleDeleteTrainingUserSuccess(data: string) {
  let selectedTraining: string = yield select(
    (state: AppState) => state.trainingReducer.selectedTraining
  );

  let query: PageableQuery = {
    page: 1,
    limit: 10,
    sortBy: 'lastName:ASC',
  };
  yield put(actions.getTrainingUsers(selectedTraining, query));
  yield put(actions.deleteTrainingUserSuccess(data));
}

export function* watchDeleteTrainingUserSaga() {
  yield takeLatest(actions.DELETE_TRAINING_USER, deleteTrainingUser);
}

function* deleteTraining(action: DeleteTraining) {
  try {
    // @ts-ignore
    yield trainingService.deleteTraining(action.payload);

    yield handleDeleteTrainingSuccess(action.payload);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleDeleteTrainingSuccess(data: string) {
  let user: UserType = yield select(
    (state: AppState) => state.userReducer.user
  );

  let query: PageableQuery = {
    page: 1,
    limit: 10,
  };
  yield put(actions.getAllTrainings(user.id, query));
  yield put(actions.deleteTrainingSuccess(data));
}

export function* watchDeleteTrainingSaga() {
  yield takeLatest(actions.DELETE_TRAINING, deleteTraining);
}

function* updateTraining(action: AddTraining) {
  try {
    // @ts-ignore
    const trainingResponse: any = yield trainingService.updateTraining(
      action.payload
    );

    yield handleUpdateTrainingSuccess(trainingResponse.data.training);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleUpdateTrainingSuccess(data: TrainingType, withEmailSend?: boolean, withOpenNewMeeTab?: boolean) {
  yield put(actions.updateTrainingSuccess(data));
  if(withEmailSend){
    yield put(actions.sendLink({trainingId: data?.id!, timeZone: DateTime.now().zoneName}));
  }
  if(withOpenNewMeeTab){
    window.open(data.link,'_blank')
  }
}

export function* watchUpdateTrainingSaga() {
  yield takeLatest(actions.UPDATE_TRAINING, updateTraining);
}

function* updateTrainingUser(action: AddTrainingUser) {
  try {
    // @ts-ignore
    const trainingResponse: any = yield trainingService.updateTrainingUser(
      action.payload
    );

    yield handleUpdateTrainingUserSuccess(trainingResponse.data.trainingUser);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleUpdateTrainingUserSuccess(data: TrainingUserType) {
  let query: PageableQuery = {
    page: 1,
    limit: 10,
    sortBy: 'lastName:ASC',
  };
  yield put(actions.getTrainingUsers(data.training, query));
  yield put(actions.updateTrainingUserSuccess(data));
}

export function* watchUpdateTrainingUserSaga() {
  yield takeLatest(actions.UPDATE_TRAINING_USER, updateTrainingUser);
}

function* generateOneCertificate(action: GenerateOneCertificate) {
  try {
    // @ts-ignore
    const certificateResponse: any = yield trainingService.generateOneCertificate(
      action.payload
    );

    yield handleGenerateOneCertificateSuccess(certificateResponse.data.certificate, action.payload.traineeName);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleGenerateOneCertificateSuccess(data: string, traineeName: string) {
  const base64Certificate = `data:application/pdf;base64,${data}`;

  yield put(actions.generateOneCertificateSuccess(data));
  yield triggerBase64Download(base64Certificate, traineeName)
}

export function* watchGenerateOneCertificate() {
  yield takeLatest(actions.GENERATE_ONE_CERTIFICATE, generateOneCertificate);
}

function* generateManyCertificates(action: GenerateManyCertificates) {
  try {
    // @ts-ignore
    const certificateResponse: any = yield trainingService.generateManyCertificates(
      action.payload
    );

    yield handleGenerateManyCertificatesSuccess(certificateResponse.data.zip);
  } catch (e) {
    console.log(e)
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleGenerateManyCertificatesSuccess(data: string) {
  let zip = new JSZip();
  yield zip.loadAsync(data, { base64: true })
  yield zip.generateAsync({ type: "blob" }).then(function (blob) {
    saveAs(blob, "certificates.zip")
  });

  yield put(actions.generateManyCertificatesSuccess(data));
}
export function* watchGenerateManyCertificates() {
  yield takeLatest(actions.GENERATE_MANY_CERTIFICATES, generateManyCertificates);
}

function* doesLinkExist(action: DoesLinkExist) {
  try {
    // @ts-ignore
    const doesLinkExistResponse: any = yield trainingService.doesLinkExist(
      action.payload
    );

    yield handleDoesLinkExistSuccess(doesLinkExistResponse.data.doesLinkExist);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleDoesLinkExistSuccess(data: boolean) {
  yield put(actions.doesLinkExistSuccess(data));
}

export function* watchDoesLinkExist() {
  yield takeLatest(actions.DOES_LINK_EXIST, doesLinkExist);
}

function* createLink(action: CreateLink) {
  try {
    // @ts-ignore
    const trainingResponse: any = yield trainingService.createLink(
      action.payload.link
    );
    yield handleUpdateTrainingSuccess(trainingResponse.data.training, action.payload.withEmailSend, action.payload.withOpenNewMeetTab);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

export function* watchCreateLink() {
  yield takeLatest(actions.CREATE_LINK, createLink);
}

function* sendLink(action: SendLink) {
  try {
    // @ts-ignore
    yield trainingService.sendLink(
      action.payload
    );

    yield handleSendLinkSuccess();
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.trainingActionError(e));
  }
}

function* handleSendLinkSuccess() {
  yield put(networkActions.showMessage(i18next.t("trainingsScreen.linksWereSent")));
}

export function* watchSendLink() {
  yield takeLatest(actions.SEND_LINK, sendLink);
}
