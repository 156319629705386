/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { PaginationBar } from "./PaginationBar";
import { StyledSearchInput } from "./StyledSearchInput";

interface Props {
  children: any;
  label: any;
  id?: string;
  withPagination?: boolean;
  pages?: number;
  onLoadMore?: (page: number, limit: number) => void;
  withSearch?: boolean;
  additionalButton?: any;
  searchQuery?: string;
  page?: number;
  limit?: number;
  onSearchQueryChange?: (query: string) => void;
}

export const StyledSectionWrapper = (props: Props) => {
  const {
    id,
    label,
    children,
    withPagination,
    pages,
    page,
    onLoadMore,
    withSearch,
    searchQuery,
    onSearchQueryChange,
    limit,
    additionalButton,
  } = props;

  return (
    <div id={id} className="sectionWrapper">
      <div className="labelContainer">
        <span>{label}</span>
        {withSearch && (
          <>
            {additionalButton ?? null}
            <StyledSearchInput
              additionalButton = {!!additionalButton}
              value={searchQuery!}
              onChange={onSearchQueryChange}
            />
          </>
        )}
      </div>
      {children}
      {withPagination && (
        <PaginationBar
          pages={pages!}
          page={page!}
          limit={limit!}
          onLoadMore={onLoadMore}
        />
      )}
    </div>
  );
};
