/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import LocalizationContext from "../../../context/LocalizationContext";
import { StyledModal } from "../../components/StyledModal";
import { StyledTextInput } from "../../components/StyledTextInput";
import { DurationType } from "../../../enums/DurationType";
import ProductType from "../../../interfaces/payment/ProductType";
import { addProduct, updateProduct } from "../../../saga/payment/actions";
import { StyledDropdown } from "../../components/StyledDropdown";
import { onChangePrice } from "../../../utils/priceFormat.utils";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";

type FormValues = {
  duration: number;
  durationType: string;
  price: number;
  name: string;
  productStoreId: string;
};

interface Props {
  onCloseButtonClick: () => void;
  product?: ProductType | null;
  resetPage?: () => void;
  error: string | null;
  modalType: "ADD" | "EDIT" | null;
}

export const SubscriptionModal = (props: Props) => {
  const { onCloseButtonClick, product, resetPage, modalType } = props;
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const durationTypeOptions = [
    { label: t("subscriptionScreen.day"), value: DurationType.DAYS },
    { label: t("subscriptionScreen.week"), value: DurationType.WEEKS },
    { label: t("subscriptionScreen.month"), value: DurationType.MONTHS },
    { label: t("subscriptionScreen.year"), value: DurationType.YEARS },
  ];
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    //@ts-ignore
    defaultValues: {
      duration: 1,
      durationType: DurationType.MONTHS,
      price: 35,
      ...product,
    },
  });

  const gaEventTracker = useAnalyticsEventTracker("Subscription");

  const parseDurationTypeToText = (durationType: string) => {
    let text;
    switch (durationType) {
      case DurationType.DAYS:
        text = t("subscriptionScreen.pricePerDay")
        break;
      case DurationType.WEEKS:
        text = t("subscriptionScreen.pricePerWeek")
        break;
      case DurationType.MONTHS:
        text = t("subscriptionScreen.pricePerMonth")
        break;
      case DurationType.YEARS:
        text = t("subscriptionScreen.pricePerYear")
        break;
      default:
        console.log('Cannot parse enum');
    }
    return text;
  }

  const onSubmit = (data: FormValues) => {
    if (resetPage) resetPage();
    if (product) {
      dispatch(
        updateProduct({
          ...data,
          duration: Number(data.duration),
          price: Number(Number(data.price).toFixed(2)),
          id: product.id,
        })
      );
      gaEventTracker("Update Product");
    } else {
      dispatch(
        addProduct({
          ...data,
          duration: Number(data.duration),
          price: Number(data.price),
        })
      );
      gaEventTracker("Add Product");
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <StyledModal
        header={
          modalType === "ADD"
            ? t("subscriptionScreen.addProduct")
            : t("subscriptionScreen.editProduct")
        }
        onAcceptButtonClick={() => {}}
        acceptButtonType="submit"
        acceptButtonLabel={
          modalType === "ADD" ? t("global.add") : t("global.save")
        }
        onCloseButtonClick={onCloseButtonClick}
      >
        <div className="formWrapper">
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.name && errors.name.message}
                value={
                  !!value
                    ? value.charAt(0).toUpperCase() + value.slice(1)
                    : value
                }
                width={630}
                onChange={onChange}
                label={t("subscriptionScreen.name")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 55,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${55} ${t("formValidation.characters")}`,
              },
            }}
          />
          <Controller
            control={control}
            name="productStoreId"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.productStoreId && errors.productStoreId.message}
                value={value}
                width={630}
                onChange={onChange}
                label={t("subscriptionScreen.productStoreId")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 40,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${40} ${t("formValidation.characters")}`,
              },
            }}
          />
          <div className="durationPickerContainer">
            <Controller
              control={control}
              name="duration"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledTextInput
                  error={errors.duration && errors.duration.message}
                  value={String(value)}
                  type="number"
                  width={87}
                  style={{ marginBottom: errors.duration ? 20 : 0 }}
                  onChange={onChange}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                validate: (value) =>
                  Number(value) < 0
                    ? t("subscriptionScreen.wrongFormat")
                    : true,
              }}
            />
            <Controller
              control={control}
              name="durationType"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledDropdown
                  options={durationTypeOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 55,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${55} ${t("formValidation.characters")}`,
                },
              }}
            />
            <span>
              {parseDurationTypeToText(watch("durationType"))}
            </span>
            <Controller
              control={control}
              name="price"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledTextInput
                  error={errors.price && errors.price.message}
                  value={String(value)}
                  type="number"
                  width={87}
                  style={{
                    marginBottom: errors.price ? 20 : 0,
                  }}
                  onChange={(value) => onChangePrice(onChange, value)}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                validate: (value) =>
                  Number(value) < 0 ? t("subscriptionScreen.wrongPrice") : true,
              }}
            />
            <span>{t("subscriptionScreen.currency")}</span>
          </div>
        </div>
      </StyledModal>
    </form>
  );
};
