/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import LocalizationContext from "../../../context/LocalizationContext";
import { useForm, Controller } from "react-hook-form";
import { PreAuthFormWrapper } from "../PreAuthFormWrapper";
import { StyledTextInput } from "../../components/StyledTextInput";
import { emailPattern, passwordPattern } from "../../../utils/validation.utils";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import images from "../../../assets/images/images";
import { resetPassword, resetPasswordSendEmail } from "../../../saga/user/actions";
import { useQuery } from "../../../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";

type FormValues = {
  email: string;
  newPassword: string;
  repeatNewPassword: string;
};

export const ResetPasswordScreen = () => {
  const { t } = React.useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  let query = useQuery();
  const gaEventTracker = useAnalyticsEventTracker('Register');

  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <div
      id="screenContainer"
      style={{
        backgroundImage: `url(${images.authBackground})`,
      }}
    >
      <form
        className={"resetPasswordSendMailForm"}
        onSubmit={handleSubmit((data) => {
          if(query.get("token")){
            dispatch(
              resetPassword({
                newPassword: data.newPassword,
                resetPasswordToken: query.get("token")!,
                navigate,
              })
            )
            gaEventTracker("Reset Password");
          } else {
            dispatch(resetPasswordSendEmail(data.email));
            gaEventTracker("Send Email Reset Password");
          }
        })}
      >
        <PreAuthFormWrapper
          header={t("resetPasswordScreen.title")}
          buttonText={t("resetPasswordScreen.resetPassword")}
          type="resetPassword"
        >
          <div className="formWrapper">
            {query.get("token") ?
              <>
                <Controller
                  control={control}
                  name="newPassword"
                  render={({ field: { onChange, value } }) => (
                    <StyledTextInput
                      error={errors.newPassword && errors.newPassword.message}
                      value={value}
                      width={511}
                      type="password"
                      onChange={onChange}
                      label={t("resetPasswordScreen.newPassword")}
                    />
                  )}
                  rules={{
                    required: t("formValidation.required"),
                    pattern: {
                      value: passwordPattern,
                      message: t("formValidation.passwordPattern"),
                    },
                    validate: {
                      matchPassword: (value) => {
                        if (
                          value === watch("repeatNewPassword", "") &&
                          errors.repeatNewPassword &&
                          errors.repeatNewPassword.message ===
                          t("formValidation.passwordNotMatch")
                        ) {
                          setError("repeatNewPassword", {
                            message: "",
                          });
                        } else if (
                          !!watch("repeatNewPassword", "") &&
                          value !== watch("repeatNewPassword", "")
                        ) {
                          setError("repeatNewPassword", {
                            message: t("formValidation.passwordNotMatch"),
                          });
                        }
                        return true;
                      },
                    },
                  }}
                />
                <Controller
                  control={control}
                  name="repeatNewPassword"
                  render={({ field: { onChange, value } }) => (
                    <StyledTextInput
                      error={
                        errors.repeatNewPassword && errors.repeatNewPassword.message
                      }
                      value={value}
                      width={511}
                      type="password"
                      onChange={onChange}
                      label={t("resetPasswordScreen.repeatNewPassword")}
                    />
                  )}
                  rules={{
                    required: t("formValidation.required"),
                    pattern: {
                      value: passwordPattern,
                      message: t("formValidation.passwordPattern"),
                    },
                    maxLength: {
                      value: 255,
                      message: `${t(
                        "formValidation.textCannotBeLongerThan"
                      )} ${255} ${t("formValidation.characters")}`,
                    },
                    validate: {
                      matchPassword: (value) =>
                        value === watch("newPassword", "") ||
                        t("formValidation.passwordNotMatch"),
                    },
                  }}
                />
              </> :
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.email && errors.email.message}
                    value={value}
                    width={511}
                    onChange={onChange}
                    label={t("loginScreen.email")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  pattern: {
                    value: emailPattern,
                    message: t("formValidation.emailPattern"),
                  },
                }}
              />
            }
          </div>
        </PreAuthFormWrapper>
      </form>
    </div>
  );
};
