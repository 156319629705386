/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { AxiosResponse } from "axios";
import { PageableQuery } from "../interfaces/pagination/PageableQuery";
import AddProductRequest from "../interfaces/payment/AddProductRequest";
import ProductType from "../interfaces/payment/ProductType";
import { axiosService } from "./axios.service";

const root: string = "/payment";
const productRoot: string = "/product";
const subscriptionRoot: string = "/payment/subscription";

function getActiveSubscription(stylistId: string): Promise<AxiosResponse> {
  return axiosService.get(`${subscriptionRoot}/active/${stylistId}`);
}

function getAllProducts(query: PageableQuery): Promise<AxiosResponse> {
  return axiosService.get(`${productRoot}/all`, {
    params: query,
  });
}

function addProduct(data: AddProductRequest): Promise<AxiosResponse> {
  return axiosService.post(productRoot, data);
}

function updateProduct(data: ProductType): Promise<AxiosResponse> {
  return axiosService.put(productRoot, data);
}

function deleteProduct(productId: string): Promise<AxiosResponse> {
  return axiosService.delete(`${productRoot}/${productId}`);
}

function getPaymentsByUserId(userId: string, query: PageableQuery): Promise<AxiosResponse> {
  return axiosService.get(`${root}/all`, {
    params: {...query, userId}
  });
}

const paymentService = {
  getActiveSubscription,
  getAllProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  getPaymentsByUserId
};

export default paymentService;
