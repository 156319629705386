/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LocalizationContext from "../context/LocalizationContext";
import UserType from "../interfaces/user/UserType";
import { AppState } from "../store/store";

interface IProps {
  trainingMeetWasNotFound?: boolean;
}

export const PageNotFoundScreen = (props: IProps) => {
  const { t } = React.useContext(LocalizationContext);

  const authenticatedUser: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  return (
    <div
      id="pageNotFoundScreen"
      className={authenticatedUser ? "withTabs" : ""}
    >
      <span className="title">
        {props.trainingMeetWasNotFound
          ? t("pageNotFoundScreen.trainingNotFoundException")
          : t("pageNotFoundScreen.pageNotFound")}
      </span>
      <div>
        <span className="info">
          {authenticatedUser
            ? t("pageNotFoundScreen.pageNotFoundTip")
            : t("pageNotFoundScreen.pageNotFoundTipWithOr")}
        </span>
        {!authenticatedUser && (
          <Link to="/login" id="loginScreenRedirect">
            {t("pageNotFoundScreen.login")}
          </Link>
        )}
      </div>
    </div>
  );
};
