/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { Button } from "@mui/material";

interface Props {
  buttonText: string;
  type: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  disabled?: boolean;
}

export const StyledButton = (props: Props) => {
  const { buttonText, onClick, type, disabled } = props;
  return (
    <Button
      disabled={disabled}
      variant="contained"
      type={type}
      onClick={onClick}
    >
      <span className="buttonText">{buttonText}</span>
    </Button>
  );
};
