/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { AxiosResponse } from "axios";
import SurveyType from "../interfaces/survey/SurveyType";
import { GetAllTrainingRequest } from "../interfaces/training/GetAllTrainingRequest";
import { axiosService } from "./axios.service";

const root: string = "/survey";

function getAllSurveys(data: GetAllTrainingRequest): Promise<AxiosResponse> {
  return axiosService.get(`${root}/all/${data.userId}`, {
    params: data.query,
  });
}

function addSurvey(data: SurveyType): Promise<AxiosResponse> {
  return axiosService.post(`${root}`, data);
}

function deleteSurvey(surveyId: string): Promise<AxiosResponse> {
  return axiosService.delete(`${root}/${surveyId}`);
}

function updateSurvey(data: SurveyType): Promise<AxiosResponse> {
  return axiosService.put(`${root}`, data);
}

const surveyService = {
  getAllSurveys,
  addSurvey,
  deleteSurvey,
  updateSurvey,
};

export default surveyService;
