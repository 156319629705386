/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { PageableQuery } from "../../interfaces/pagination/PageableQuery";
import { SurveyActionTypes } from "../../interfaces/survey/SurveyActionTypes";
import SurveyType from "../../interfaces/survey/SurveyType";

export const GET_ALL_SURVEYS = "GET_ALL_SURVEYS";
export const GET_ALL_SURVEYS_SUCCESS = "GET_ALL_SURVEYS_SUCCESS";
export const SURVEY_ACTION_ERROR = "SURVEY_ACTION_ERROR";
export const ADD_SURVEY = "ADD_SURVEY";
export const ADD_SURVEY_SUCCESS = "ADD_SURVEY_SUCCESS";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const DELETE_SURVEY_SUCCESS = "DELETE_SURVEY_SUCCESS";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";

export function getAllSurveys(
  userId: string,
  query: PageableQuery
): SurveyActionTypes {
  return {
    type: GET_ALL_SURVEYS,
    payload: {
      userId,
      query,
    },
  };
}

export function getAllSurveysSuccess(
  surveys: SurveyType[],
  pages: number
): SurveyActionTypes {
  return {
    type: GET_ALL_SURVEYS_SUCCESS,
    payload: {
      surveys,
      pages,
    },
  };
}

export function surveyActionError(error: string | null): SurveyActionTypes {
  return {
    type: SURVEY_ACTION_ERROR,
    payload: error,
  };
}

export function addSurvey(data: SurveyType): SurveyActionTypes {
  return {
    type: ADD_SURVEY,
    payload: data,
  };
}

export function addSurveySuccess(survey: SurveyType): SurveyActionTypes {
  return {
    type: ADD_SURVEY_SUCCESS,
    payload: survey,
  };
}

export function deleteSurvey(surveyId: string): SurveyActionTypes {
  return {
    type: DELETE_SURVEY,
    payload: surveyId,
  };
}

export function deleteSurveySuccess(surveyId: string): SurveyActionTypes {
  return {
    type: DELETE_SURVEY_SUCCESS,
    payload: surveyId,
  };
}

export function updateSurvey(survey: SurveyType): SurveyActionTypes {
  return {
    type: UPDATE_SURVEY,
    payload: survey,
  };
}

export function updateSurveySuccess(survey: SurveyType): SurveyActionTypes {
  return {
    type: UPDATE_SURVEY_SUCCESS,
    payload: survey,
  };
}
