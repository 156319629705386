/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/
import i18next from "i18next";
import { DurationType } from "../enums/DurationType";
import { SubscriptionStatusType } from "../enums/SubscriptionStatusType";
import ProductType from "../interfaces/payment/ProductType";
import SubscriptionType from "../interfaces/payment/SubscriptionType";
import UserType from "../interfaces/user/UserType";

export const trainingUserTableOptions = () => ({
  headers: [
    i18next.t("global.firstName"),
    i18next.t("global.lastName"),
    i18next.t("global.email"),
  ],
  keys: ["firstName", "lastName", "email"],
});

const getDayText = (number: number) => {
  //@ts-ignore
  number = Number(number);
  switch (true) {
    case number === 1:
      return i18next.t("subscriptionScreen.day");
    case number > 1:
      return i18next.t("subscriptionScreen.days");
  }
};

const getWeekText = (number: number) => {
  //@ts-ignore
  const splitNumber = number.split("");
  const lastNumber = Number(splitNumber[splitNumber.length - 1]);
  number = Number(number);
  switch (true) {
    case number === 1:
      return i18next.t("subscriptionScreen.week");
    case number > 1 && number < 5:
      return i18next.t("subscriptionScreen.week2_4");
    case number > 21 && lastNumber > 1 && lastNumber < 5:
      return i18next.t("subscriptionScreen.week2_4");
    case number > 4:
      return i18next.t("subscriptionScreen.week4>");
  }
};

const getMonthText = (number: number) => {
  //@ts-ignore
  const splitNumber = number.split("");
  const lastNumber = Number(splitNumber[splitNumber.length - 1]);
  number = Number(number);
  switch (true) {
    case number === 1:
      return i18next.t("subscriptionScreen.month");
    case number > 1 && number < 5:
      return i18next.t("subscriptionScreen.month2_4");
    case number > 21 && lastNumber > 1 && lastNumber < 5:
      return i18next.t("subscriptionScreen.month2_4");
    case number > 4:
      return i18next.t("subscriptionScreen.month4>");
  }
};

const getYearText = (number: number) => {
  //@ts-ignore
  const splitNumber = number.split("");
  const lastNumber = Number(splitNumber[splitNumber.length - 1]);
  number = Number(number);
  switch (true) {
    case number === 1:
      return i18next.t("subscriptionScreen.year");
    case number > 1 && number < 5:
      return i18next.t("subscriptionScreen.year2_4");
    case number > 21 && lastNumber > 1 && lastNumber < 5:
      return i18next.t("subscriptionScreen.year2_4");
    case number > 4:
      return i18next.t("subscriptionScreen.year4>");
  }
};

const parseDurationTypeToText = (durationType: string, duration: number) => {
  let text;
  switch (durationType) {
    case DurationType.DAYS:
      text = getDayText(duration)
      break;
    case DurationType.WEEKS:
      text = getWeekText(duration)
      break;
    case DurationType.MONTHS:
      text = getMonthText(duration)
      break;
    case DurationType.YEARS:
      text = getYearText(duration)
      break;
    default:
      console.log('Cannot parse enum');
  }
  return text;
}

const formatPrice = (price: number) => {
  price = Number(price);
  const formattedPrice = price.toFixed(2);
  return formattedPrice.replace(".", ",");
};

export const productTableOptions = () => ({
  headers: [
    i18next.t("subscriptionScreen.title"),
    i18next.t("subscriptionScreen.duration"),
    i18next.t("subscriptionScreen.price"),
    i18next.t("subscriptionScreen.productStoreId"),
  ],
  keys: ["name", "duration", "price", "productStoreId"],
  customComponents: {
    price: (row: ProductType) =>
      `${formatPrice(row.price)} ${i18next.t("subscriptionScreen.currency")}`,
    duration: (row: ProductType) =>
      `${row.duration} ${
        parseDurationTypeToText(row.durationType, row.duration)
      }`,
  },
});

export const usersTableOptions = () => ({
  headers: [
    i18next.t("usersScreen.firstName"),
    i18next.t("usersScreen.lastName"),
    i18next.t("usersScreen.email"),
    i18next.t("usersScreen.subscriptionStatus"),
  ],
  keys: ["firstName", "lastName", "email", "subscriptionStatus"],
});

export const userPaymentsTableOptions = () => ({
  headers: [
    i18next.t("usersScreen.productName"),
    i18next.t("usersScreen.paymentDate"),
    i18next.t("usersScreen.ammount"),
  ],
  keys: [],
});
