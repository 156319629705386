/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { all, fork } from "redux-saga/effects";
import {
  watchAddProductSaga,
  watchDeleteProductSaga,
  watchGetActiveSubscriptionSaga,
  watchGetAllProductsSaga,
  watchGetPaymentsByUserId,
  watchUpdateProductSaga,
} from "./payment/payment.saga";
import {
  watchAddSurveySaga,
  watchDeleteSurveySaga,
  watchGetAllSurveysSaga,
  watchUpdateSurveySaga,
} from "./survey/survey.saga";
import {
  watchAddTrainingSaga,
  watchAddTrainingUserSaga,
  watchDeleteTrainingSaga,
  watchDeleteTrainingUserSaga,
  watchGenerateManyCertificates,
  watchGenerateOneCertificate,
  watchGetAllTrainingsSaga,
  watchGetAllTrainingsWithTrainingUsersSaga,
  watchGetTrainingUsersSaga,
  watchUpdateTrainingSaga,
  watchUpdateTrainingUserSaga,
  watchDoesLinkExist,
  watchCreateLink,
  watchSendLink
} from "./training/training.saga";
import {
  watchUserLoginSaga,
  watchUserRegistrationSaga,
  watchGetUserSaga,
  watchUpdateUserSaga,
  watchGetAvatarSaga,
  watchUpdateAvatarSaga,
  watchResetPasswordSendEmail,
  watchResetPassword,
  watchEmailVerificationSaga,
  watchGetAllUsersSaga,
  watchToggleUserStatus,
  watchDeleteUser,
} from "./user/user.saga";

export function* rootSaga() {
  yield all([
    fork(watchUserLoginSaga),
    fork(watchUserRegistrationSaga),
    fork(watchGetAllTrainingsSaga),
    fork(watchAddTrainingSaga),
    fork(watchAddTrainingUserSaga),
    fork(watchGetTrainingUsersSaga),
    fork(watchDeleteTrainingSaga),
    fork(watchDeleteTrainingUserSaga),
    fork(watchUpdateTrainingSaga),
    fork(watchUpdateTrainingUserSaga),
    fork(watchGetAllSurveysSaga),
    fork(watchAddSurveySaga),
    fork(watchDeleteSurveySaga),
    fork(watchUpdateSurveySaga),
    fork(watchGetUserSaga),
    fork(watchUpdateUserSaga),
    fork(watchGetAvatarSaga),
    fork(watchUpdateAvatarSaga),
    fork(watchGetActiveSubscriptionSaga),
    fork(watchResetPasswordSendEmail),
    fork(watchResetPassword),
    fork(watchGetAllProductsSaga),
    fork(watchAddProductSaga),
    fork(watchDeleteProductSaga),
    fork(watchUpdateProductSaga),
    fork(watchEmailVerificationSaga),
    fork(watchGetAllUsersSaga),
    fork(watchToggleUserStatus),
    fork(watchDeleteUser),
    fork(watchGetPaymentsByUserId),
    fork(watchGenerateOneCertificate),
    fork(watchGetAllTrainingsWithTrainingUsersSaga),
    fork(watchGenerateManyCertificates),
    fork(watchDoesLinkExist),
    fork(watchCreateLink),
    fork(watchSendLink)
  ]);
}
