/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  GET_ACTIVE_SUBSCRIPTION,
  GET_ACTIVE_SUBSCRIPTION_SUCCESS,
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_PAYMENTS_BY_USER_ID,
  GET_PAYMENTS_BY_USER_ID_SUCCESS,
  PAYMENT_ACTION_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
} from "../saga/payment/actions";
import { PaymentReducer } from "../interfaces/payment/PaymentReducer";
import { PaymentActionTypes } from "../interfaces/payment/PaymentActionTypes";

export const initialState: PaymentReducer = {
  isFetching: false,
  error: null,
  activeSubscription: null,
  products: null,
  productPages: 0,
  payments: null,
  paymentPages: 0
};

export const paymentReducer = (
  state: PaymentReducer = { ...initialState },
  action: PaymentActionTypes
): PaymentReducer => {
  switch (action.type) {
    case GET_ACTIVE_SUBSCRIPTION:
      return { ...state, isFetching: true, error: null };
    case GET_ACTIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activeSubscription: action.payload,
      };
    case PAYMENT_ACTION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case GET_ALL_PRODUCTS:
      return { ...state, isFetching: true, error: null };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        products: action.payload.products,
        productPages: action.payload.pages,
      };
    case ADD_PRODUCT:
      return { ...state, isFetching: true, error: null };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case DELETE_PRODUCT:
      return { ...state, isFetching: true, error: null };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_PRODUCT:
      return { ...state, isFetching: true, error: null };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case GET_PAYMENTS_BY_USER_ID:
      return { ...state, isFetching: true, error: null };
    case GET_PAYMENTS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payments: action.payload.payments,
        paymentPages: action.payload.pages,
      };
    default:
      return state;
  }
};
