/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { put, takeLatest, select } from "redux-saga/effects";
import surveyService from "../../services/survey.service";
import * as actions from "./actions";
import * as networkActions from "../network/actions";
import SurveyType from "../../interfaces/survey/SurveyType";
import { PageableQuery } from "../../interfaces/pagination/PageableQuery";
import { AppState } from "../../store/store";
import UserType from "../../interfaces/user/UserType";
import {
  AddSurvey,
  DeleteSurvey,
  GetAllSurveys,
  UpdateSurvey,
} from "../../interfaces/survey/SurveyActionTypes";
import { ExceptionUtil } from "../../utils/exception.util";

function* getAllSurveys(action: GetAllSurveys) {
  try {
    // @ts-ignore
    const surveysResponse: any = yield surveyService.getAllSurveys(
      action.payload
    );

    if (surveysResponse.data.meta.totalItems === 0) {
      yield handleGetAllSurveysSuccess(
        [],
        surveysResponse.data.meta.totalPages
      );
      return;
    }

    yield handleGetAllSurveysSuccess(
      surveysResponse.data.data,
      surveysResponse.data.meta.totalPages
    );
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.surveyActionError(e));
  }
}

function* handleGetAllSurveysSuccess(data: SurveyType[], pages: number) {
  yield put(actions.getAllSurveysSuccess(data, pages));
}

export function* watchGetAllSurveysSaga() {
  yield takeLatest(actions.GET_ALL_SURVEYS, getAllSurveys);
}

function* addSurvey(action: AddSurvey) {
  try {
    // @ts-ignore
    const surveyResponse: any = yield surveyService.addSurvey(action.payload);

    yield handleAddSurveySuccess(surveyResponse.data.survey);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.surveyActionError(e));
  }
}

function* handleAddSurveySuccess(data: SurveyType) {
  let user: UserType = yield select(
    (state: AppState) => state.userReducer.user
  );

  let query: PageableQuery = {
    page: 1,
    limit: 10,
  };
  yield put(actions.addSurveySuccess(data));
  yield put(actions.getAllSurveys(user.id, query));
}

export function* watchAddSurveySaga() {
  yield takeLatest(actions.ADD_SURVEY, addSurvey);
}

function* deleteSurvey(action: DeleteSurvey) {
  try {
    // @ts-ignore
    yield surveyService.deleteSurvey(action.payload);

    yield handleDeleteSurveySuccess(action.payload);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.surveyActionError(e));
  }
}

function* handleDeleteSurveySuccess(data: string) {
  let user: UserType = yield select(
    (state: AppState) => state.userReducer.user
  );

  let query: PageableQuery = {
    page: 1,
    limit: 10,
  };
  yield put(actions.getAllSurveys(user.id, query));
  yield put(actions.deleteSurveySuccess(data));
}

export function* watchDeleteSurveySaga() {
  yield takeLatest(actions.DELETE_SURVEY, deleteSurvey);
}

function* updateSurvey(action: UpdateSurvey) {
  try {
    // @ts-ignore
    const surveyResponse: any = yield surveyService.updateSurvey(
      action.payload
    );

    yield handleUpdateSurveySuccess(surveyResponse.data.survey);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.surveyActionError(e));
  }
}

function* handleUpdateSurveySuccess(data: SurveyType) {
  yield put(actions.updateSurveySuccess(data));
}

export function* watchUpdateSurveySaga() {
  yield takeLatest(actions.UPDATE_SURVEY, updateSurvey);
}
