/* eslint-disable import/no-anonymous-default-export */
/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

export default {
  logo: require("./logo.png").default,
  logo2: require("./logo2.png").default,
  authBackground: require("./authBackground/authBackground.png").default,
  certificateBackground: require("./certificate/certyfikat.png").default,
  backgroundGradient: require("./backgroundGradient/backgroundGradient.png")
    .default,
  subscriptionStarIcon: require("./icons/subscriptionStarIcon.svg").default,
  priceTagIcon: require("./icons/priceTagIcon.svg").default,
  emailIcon: require("./icons/emailIcon.svg").default,
  fabButtonIcon: require("./icons/fabButtonIcon.svg").default,
  phoneIcon: require("./icons/phoneIcon.svg").default,
  wwwIcon: require("./icons/wwwIcon.svg").default,
  uploadIcon: require("./icons/uploadIcon.svg").default,
  searchIcon: require("./icons/searchIcon.svg").default,
  copyIcon: require("./icons/copyIcon.svg").default,
  clockIcon: require("./icons/clockIcon.svg").default,
  statusActive: require("./icons/statusActive.svg").default,
  statusInactive: require("./icons/statusInactive.svg").default,
  subtraction: require("./icons/subtraction.svg").default,
  defaultAvatar: require("./certificate/defaultAvatar.svg").default,
  trainingDefaults: {
    default1: require("./trainingDefaultPhotos/default1/default1@3x.png")
      .default,
    default2: require("./trainingDefaultPhotos/default2/default2@3x.png")
      .default,
    default3: require("./trainingDefaultPhotos/default3/default3@3x.png")
      .default,
    default4: require("./trainingDefaultPhotos/default4/default4@3x.png")
      .default,
    default5: require("./trainingDefaultPhotos/default5/default5@3x.png")
      .default,
    default6: require("./trainingDefaultPhotos/default6/default6@3x.png")
      .default,
    default7: require("./trainingDefaultPhotos/default7/default7@3x.png")
      .default,
    default8: require("./trainingDefaultPhotos/default8/default8@3x.png")
      .default,
  }
};
