/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

export enum TrainingTypeType {
  PDF = "PDF",
  LIVE = "LIVE",
  POLL = "POLL",
}
