export const onChangePrice = (onChange: (...event: any[]) => void, value: string) => {
  const maxLength = 15;
  const price = value.length <= maxLength ? value : parseInt(value.toString().substring(0, maxLength));
  if (price) {
    const afterCommaString = String(price).split('.')[1];
    if (afterCommaString && afterCommaString.length >= 3) {
      const formattedPrice = Math.floor(Number(price) * 100) / 100;
      onChange(formattedPrice.toFixed(2));
    } else {
      onChange(price);
    }
  } else {
    onChange(price)
  }
}