/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { UserReducer } from "../interfaces/user/UserReducer";
import { UserActionTypes } from "../interfaces/user/UserActionTypes";
import {
  CLEAR_AUTHENTICATED_USER,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  GET_AVATAR,
  GET_AVATAR_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SEND_EMAIL,
  RESET_PASSWORD_SEND_EMAIL_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SET_TOKEN,
  TOGGLE_USER_STATUS,
  TOGGLE_USER_STATUS_SUCCESS,
  UPDATE_AVATAR,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  USER_ACTION_ERROR,
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTRATION,
  USER_REGISTRATION_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
} from "../saga/user/actions";
import { UserRoleType } from "../enums/UserRoleType";

export const initialState: UserReducer = {
  isFetching: false,
  user: null,
  avatar: null,
  isFetchingAvatar: false,
  error: null,
  userRole: null,
  creatorId: "",
  userProfile: null,
  token: "",
  users: null,
  userPages: 0,
};

export const userReducer = (
  state: UserReducer = { ...initialState },
  action: UserActionTypes
): UserReducer => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, isFetching: true, error: null };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        userRole: action.payload.user.role,
        token: action.payload.accessToken,
      };
    case CLEAR_AUTHENTICATED_USER:
      return {
        ...state,
        user: null,
        isFetching: false,
        error: "",
        userRole: null,
        creatorId: "",
      };
    case USER_ACTION_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        isFetchingAvatar: false,
      };
    case USER_REGISTRATION:
      return { ...state, isFetching: true, error: null };
    case USER_REGISTRATION_SUCCESS:
      return { ...state, isFetching: false, error: "" };
    case USER_LOGOUT:
      return { ...state, ...initialState };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case GET_USER:
      return { ...state, isFetching: true, error: null };
    case GET_USER_SUCCESS:
      return { ...state, isFetching: false, userProfile: action.payload };
    case UPDATE_USER:
      return { ...state, isFetching: true, error: null };
    case UPDATE_USER_SUCCESS:
      return { ...state, isFetching: false, userProfile: action.payload };
    case GET_AVATAR:
      return { ...state, isFetchingAvatar: true, error: null };
    case GET_AVATAR_SUCCESS:
      return { ...state, isFetchingAvatar: false, avatar: action.payload };
    case UPDATE_AVATAR:
      return { ...state, isFetchingAvatar: true, error: null };
    case UPDATE_AVATAR_SUCCESS:
      return { ...state, isFetchingAvatar: false, avatar: action.payload };
    case RESET_PASSWORD_SEND_EMAIL:
      return { ...state, isFetching: true, error: null };
    case RESET_PASSWORD_SEND_EMAIL_SUCCESS:
      return { ...state, isFetching: false, error: "" };
    case RESET_PASSWORD:
      return { ...state, isFetching: true, error: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, isFetching: false, error: "" };
    case VERIFY_EMAIL:
      return { ...state, isFetching: true, error: null };
    case VERIFY_EMAIL_SUCCESS:
      return { ...state, isFetching: false, error: "" };
    case GET_ALL_USERS:
      return { ...state, isFetching: true, error: null };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action.payload.users,
        userPages: action.payload.pages,
      };
    case TOGGLE_USER_STATUS:
      return { ...state, isFetching: true, error: null };
    case TOGGLE_USER_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: state.users!.map((user) =>
          user.id === action.payload.id ? action.payload : user
        ),
        error: null,
      };
    case DELETE_USER:
      return { ...state, isFetching: true, error: null };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: state.users!.filter((user) => user.id !== action.payload),
        error: null,
      };
    default:
      return state;
  }
};
