import classNames from 'classnames'
import { t } from 'i18next'
import images from '../../../assets/images/images'
import { DateTime } from "luxon";

interface Props {
    formState: any;
}

export const CertificatePromo = (props: Props) => {
    const { stylistName, title, traineeName, avatar, date } = props.formState;

    return (
        <div className={classNames("topContainer")}>
            <div id="certificatePromoContainer">
                <div className="sectionWrapper">
                    <div className="labelContainer">
                        <span>{t("certificatesScreen.certificatePromo")}</span>
                    </div>
                    <div style={{ height: "max-content", position: "relative", textAlign: "center", margin: "5%" }}>
                        <img className="certificate" src={images.certificateBackground} alt="" />
                        <div className="certificateTitle">{title && title.toUpperCase()}</div>
                        <div className="traineeName">{traineeName}</div>
                        <div className="stylistName">{stylistName}</div>
                        {avatar && <img className="certificateAvatar" src={`data:image/png;base64,${avatar}`} alt="" />}
                        <div className="certificateDate">{(DateTime.fromISO(date).toFormat("dd.LL.yyyy") === 'Invalid DateTime' ? "" : DateTime.fromISO(date).toFormat("dd.LL.yyyy")) + " " + stylistName}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
