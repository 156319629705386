/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

export enum ExceptionType {
  BadRequestException = 'BadRequestException',
  ValidationException = 'ValidationException',
  IllegalArgumentException = 'IllegalArgumentException',
  UnauthorizedException = 'UnauthorizedException',
  AccessDeniedException = 'AccessDeniedException',
  NotFoundException = 'NotFoundException',
  EntityNotFoundException = 'EntityNotFoundException',
  RequestTimeoutException = 'RequestTimeoutException',
  EntityAlreadyExistsException = 'EntityAlreadyExistsException',
  UnexpectedApiException = 'UnexpectedApiException',
  ServiceNotAvailableException = 'ServiceNotAvailableException',
  ConfirmationException = 'ConfirmationException',
  RefreshTokenExpiredException = 'RefreshTokenExpiredException',
  NoTokenException = 'NoTokenException',
  EntityAlreadyExistsWithCodeException = 'EntityAlreadyExistsWithCodeException',
  NetworkException = 'NetworkException',
  TokenExpiredException = 'TokenExpiredException',
}
