/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { put, takeLatest } from "redux-saga/effects";
import LoginResponse from "../../interfaces/auth/LoginResponse";
import {
  DeleteUser,
  GetAllUsers,
  GetAvatar,
  GetUser,
  ResetPassword,
  ResetPasswordSendEmail,
  ToggleUserStatus,
  UpdateAvatar,
  UpdateUser,
  UserLogin,
  UserRegistration,
  VerifyEmail,
} from "../../interfaces/user/UserActionTypes";
import authService from "../../services/auth.service";
import * as actions from "./actions";
import * as networkActions from "../network/actions";
import i18next from "i18next";
import { Cookies as ReactCookies } from "react-cookie";
import { appConstants } from "../../constants/app.constants";
import { ExceptionUtil } from "../../utils/exception.util";
import { sha512 } from "js-sha512";
import userService from "../../services/user.service";
import UserType from "../../interfaces/user/UserType";
import { UserActionResponse } from "../../interfaces/user/UserActionResponse";
import { GetAvatarResponse } from "../../interfaces/user/GetAvatarResponse";
import { UserStatusType } from "../../enums/UserStatusType";
import { PageableQuery } from "../../interfaces/pagination/PageableQuery";

function* userLogin(action: UserLogin) {
  try {
    const hash = sha512.create();
    hash.update(action.payload.password);

    const loginRequest = {
      ...action.payload,
      password: hash.hex(),
    };
    // @ts-ignore
    const loginResponse: any = yield authService.userLogin(loginRequest);

    yield handleLoginSuccess(loginResponse.data);
  } catch (e) {
    // @ts-ignore
    yield put(actions.userActionError(e));
  }
}

function* handleLoginSuccess(data: LoginResponse) {
  const cookies = new ReactCookies();
  cookies.set(appConstants.REFRESH_TOKEN_KEY, data.refreshToken, {
    path: "/",
    httpOnly: true,
    secure: true,
  });

  yield put(actions.loginSuccess(data));
  yield put(networkActions.showMessage(i18next.t("loginScreen.success")));
}

export function* watchUserLoginSaga() {
  yield takeLatest(actions.USER_LOGIN, userLogin);
}

function* userRegistration(action: UserRegistration) {
  try {
    const hashPassword = sha512.create();
    hashPassword.update(action.payload.password);

    const hashRepeatPassword = sha512.create();
    hashRepeatPassword.update(action.payload.repeatPassword);

    const registrationRequest = {
      ...action.payload,
      password: hashPassword.hex(),
      repeatPassword: hashRepeatPassword.hex(),
    };
    // @ts-ignore
    yield authService.userRegistration(registrationRequest);
    yield handleRegistrationSuccess();
  } catch (e) {
    // @ts-ignore
    yield put(actions.userActionError(e));
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
  }
}

function* handleRegistrationSuccess() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  yield put(actions.registerSuccess());
  yield put(
    networkActions.showMessage(i18next.t("registrationScreen.success"))
  );
}

export function* watchUserRegistrationSaga() {
  yield takeLatest(actions.USER_REGISTRATION, userRegistration);
}

function* resetPasswordSendEmail(action: ResetPasswordSendEmail) {
  try {
    // @ts-ignore
    yield authService.resetPasswordSendEmail(action.payload);
    // @ts-ignore
    yield handleResetPasswordSendEmailSuccess();
  } catch (e) {
    // @ts-ignore
    yield put(actions.userActionError(e));
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
  }
}
export function* handleResetPasswordSendEmailSuccess() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  yield put(actions.handleResetPasswordSendEmailSuccess());
  yield put(
    networkActions.showMessage(
      i18next.t("resetPasswordScreen.sendEmailSuccess")
    )
  );
}

export function* watchResetPasswordSendEmail() {
  yield takeLatest(actions.RESET_PASSWORD_SEND_EMAIL, resetPasswordSendEmail);
}

function* resetPassword(action: ResetPassword) {
  try {
    const hashPassword = sha512.create();
    hashPassword.update(action.payload.newPassword);

    const resetPasswordRequest = {
      resetPasswordToken: action.payload.resetPasswordToken,
      newPassword: hashPassword.hex(),
    };
    // @ts-ignore
    yield authService.resetPassword(resetPasswordRequest);
    // @ts-ignore
    yield handleResetPasswordSuccess();

    yield action.payload.navigate("/login");
  } catch (e) {
    // @ts-ignore
    yield put(actions.userActionError(e));
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
  }
}
export function* handleResetPasswordSuccess() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  yield put(actions.handleResetPasswordSuccess());
  yield put(
    networkActions.showMessage(i18next.t("resetPasswordScreen.success"))
  );
}

export function* watchResetPassword() {
  yield takeLatest(actions.RESET_PASSWORD, resetPassword);
}

function* getUser(action: GetUser) {
  try {
    // @ts-ignore
    const userResponse: {
      data: UserActionResponse;
    } = yield userService.getUser(action.payload);

    yield handleGetUserSuccess(userResponse.data.user);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.userActionError(e));
  }
}

function* handleGetUserSuccess(user: UserType) {
  yield put(actions.getUserSuccess(user));
}

export function* watchGetUserSaga() {
  yield takeLatest(actions.GET_USER, getUser);
}

function* updateUser(action: UpdateUser) {
  try {
    // @ts-ignore
    const userResponse: {
      data: UserActionResponse;
    } = yield userService.updateUser(action.payload);
    yield handleUpdateUserSuccess(userResponse.data.user);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.userActionError(e));
  }
}

function* handleUpdateUserSuccess(user: UserType) {
  yield put(actions.updateUserSuccess(user));
  yield put(
    networkActions.showMessage(
      i18next.t("salonInfoScreen.savingProfileSuccess")
    )
  );
}

export function* watchUpdateUserSaga() {
  yield takeLatest(actions.UPDATE_USER, updateUser);
}

function* getAvatar(action: GetAvatar) {
  try {
    // @ts-ignore
    const avatarResponse: {
      data: GetAvatarResponse;
    } = yield userService.getAvatar(action.payload);

    yield handleGetAvatarSuccess(avatarResponse.data.avatarBase64);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.userActionError(e));
  }
}

function* handleGetAvatarSuccess(avatar: string) {
  yield put(actions.getAvatarSuccess(avatar));
}

export function* watchGetAvatarSaga() {
  yield takeLatest(actions.GET_AVATAR, getAvatar);
}

function* updateAvatar(action: UpdateAvatar) {
  try {
    yield userService.updateAvatar(action.payload);
    // @ts-ignore
    yield handleUpdateAvatarSuccess(action.payload);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.userActionError(e));
  }
}

function* handleUpdateAvatarSuccess(avatarBase64: string) {
  yield put(actions.updateAvatarSuccess(avatarBase64));
}

export function* watchUpdateAvatarSaga() {
  yield takeLatest(actions.UPDATE_AVATAR, updateAvatar);
}

function* emailVerification(action: VerifyEmail) {
  try {
    // @ts-ignore
    yield authService.verifyEmail(action.payload);

    yield handleEmailVerificationSuccess();
  } catch (e) {
    // @ts-ignore
    yield put(actions.userActionError(e));
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
  }
}

function* handleEmailVerificationSuccess() {
  yield put(actions.verifyEmailSuccess());
  yield put(
    networkActions.showMessage(
      i18next.t("loginScreen.emailVerificationSuccess")
    )
  );
}

export function* watchEmailVerificationSaga() {
  yield takeLatest(actions.VERIFY_EMAIL, emailVerification);
}

function* getAllUsers(action: GetAllUsers) {
  try {
    // @ts-ignore
    const usersResponse: any = yield userService.getAllUsers(action.payload);

    if (usersResponse.data.meta.totalItems === 0) {
      yield handleGetAllUsersSuccess([], usersResponse.data.meta.totalPages);
      return;
    }

    yield handleGetAllUsersSuccess(
      usersResponse.data.data,
      usersResponse.data.meta.totalPages
    );
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.userActionError(e));
  }
}

function* handleGetAllUsersSuccess(data: UserType[], pages: number) {
  yield put(actions.getAllUsersSuccess(data, pages));
}

export function* watchGetAllUsersSaga() {
  yield takeLatest(actions.GET_ALL_USERS, getAllUsers);
}

function* toggleUserStatus(action: ToggleUserStatus) {
  try {
    // @ts-ignore
    const usersResponse: any = action.payload.currentStatus === 'ACTIVE'? 
    // @ts-ignore
    yield userService.deactivateUser(action.payload.userId): yield userService.activateUser(action.payload.userId)

    yield handleToggleUserStatusSuccess(usersResponse.data.user);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.userActionError(e));
  }
}

function* handleToggleUserStatusSuccess(user: UserType) {
  yield put(actions.toggleUserStatusSuccess(user));
  const activateUserSuccessMessage = i18next.t(
    "usersScreen.activateUserSuccess"
  );
  const deactivateUserSuccessMessage = i18next.t(
    "usersScreen.deactivateUserSuccess"
  );
  yield put(
    networkActions.showMessage(
      user.status === UserStatusType.ACTIVE
        ? activateUserSuccessMessage
        : deactivateUserSuccessMessage
    )
  );
}

export function* watchToggleUserStatus() {
  yield takeLatest(actions.TOGGLE_USER_STATUS, toggleUserStatus);
}

function* deleteUser(action: DeleteUser) {
  try {
    // @ts-ignore
    yield userService.deleteUser(action.payload);

    yield handleDeleteUserSuccess(action.payload);
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.userActionError(e));
  }
}

function* handleDeleteUserSuccess(userId: string) {
  let query: PageableQuery = {
    page: 1,
    limit: 10,
    sortBy: 'subscriptionStatus:ASC'
  };

  yield put(actions.getAllUsers(query));
  yield put(actions.deleteUserSuccess(userId));
  yield put(
    networkActions.showMessage(i18next.t("usersScreen.deleteUserSuccess"))
  );
}

export function* watchDeleteUser() {
  yield takeLatest(actions.DELETE_USER, deleteUser);
}
