/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import Resizer from "react-image-file-resizer";

export async function urlToBase64(
  url: string,
  callback: (base64data: string) => void
) {
  await fetch(url)
    .then((r) => r.blob())
    .then((blob) => {
      console.log(blob);
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        const base64data = reader.result;
        //@ts-ignore
        callback(base64data);
      };
    });
}

export const resizeFile = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
