/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import { StyledTextInput } from "../../components/StyledTextInput";
import { Controller, useForm } from "react-hook-form";
import LocalizationContext from "../../../context/LocalizationContext";
import { StyledDatePicker } from '../../components/StyledDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import UserType from '../../../interfaces/user/UserType';
import { AppState } from '../../../store/store';
import { StyledButton } from '../../components/StyledButton';
import images from '../../../assets/images/images';
import Dropzone from 'react-dropzone';
import { resizeFile } from '../../../utils/color.util';
import { DateTime } from 'luxon';
import { GetManyCertificatesModal } from './GetManyCertificatesModal';
import { generateOneCertificate } from '../../../saga/training/actions';
import GenerateOneCertificateType from '../../../interfaces/training/GenerateOneCertificateType';
import { DefaultAvatar } from '../../components/DefaultAvatar';
import useAnalyticsEventTracker from '../../../hooks/useAnaliticsTracker';

type FormValues = {
  stylistName: string;
  title: string;
  traineeName: string;
  date: string;
};

interface Props {
  setFormState: any;
  seGetManyCertificatesModalOpen: any
}

export const CertificatesInputForm = (props: Props) => {
  const userProfile: UserType | null = useSelector(
    (state: AppState) => state.userReducer.userProfile
  );
  const avatar: string | null = useSelector(
    (state: AppState) => state.userReducer.avatar
  );
  const isFetching: boolean = useSelector(
    (state: AppState) => state.userReducer.isFetching
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues
    //@ts-ignore
  } = useForm<FormValues>({
    defaultValues: {
      stylistName: userProfile?.firstName + ' ' + userProfile?.lastName,
      date: DateTime.now().toString()
    }
  });

  const { t } = React.useContext(LocalizationContext);

  const [tempAvatar, setTempAvatar] = useState<string | null>(null);
  const dispatch = useDispatch();
  const gaEventTracker = useAnalyticsEventTracker('Certificates');
  
  useEffect(() => {
    const subscription = watch((value) => {
      props.setFormState({ ...value, avatar: tempAvatar })
    })
    return () => subscription.unsubscribe();
  }, [props, watch, tempAvatar, setTempAvatar])

  useEffect(() => {
    props.setFormState({ ...getValues(), avatar: tempAvatar })
  }, [tempAvatar])

  useEffect(() => {
    if (avatar) {
      setTempAvatar(avatar);
    }
  }, [avatar]);

  const onSubmit = (data: GenerateOneCertificateType) => {
    dispatch(generateOneCertificate(data));
    gaEventTracker("Generate one certificate");
  }

  return (
    <div className={classNames("topContainer")}>
      <div id="certificateInputForm">
        <div className="sectionWrapper">
          <div className="labelContainer">
            <span>{t("certificatesScreen.inputData")}</span>
          </div>
          <div className="inputFormContainter">
            <form onSubmit={handleSubmit((data) => onSubmit({ ...data, avatar: tempAvatar }))}>
              <Controller
                control={control}
                name="stylistName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.stylistName && errors.stylistName.message}
                    value={value}
                    width={"100%"}
                    onChange={onChange}
                    label={t("certificatesScreen.stylistName")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: {
                    value: 70,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${70} ${t("formValidation.characters")}`,
                  },
                }}
              />
              <Controller
                control={control}
                name="title"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.title && errors.title.message}
                    value={value}
                    width={"100%"}
                    onChange={onChange}
                    label={t("certificatesScreen.title")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: {
                    value: 55,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${55} ${t("formValidation.characters")}`,
                  },
                }}
              />
              <Controller
                control={control}
                name="traineeName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.traineeName && errors.traineeName.message}
                    value={value}
                    width={"100%"}
                    onChange={onChange}
                    label={t("certificatesScreen.traineeName")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: {
                    value: 70,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${70} ${t("formValidation.characters")}`,
                  },
                }}
              />
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledDatePicker
                    error={errors.date && errors.date.message}
                    value={value}
                    onChange={onChange}
                    label={t("certificatesScreen.date")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: 35,
                  validate: (value) =>
                    value === "Invalid DateTime"
                      ? t("formValidation.wrongDate")
                      : true
                }}
              />
              <div style={{ display: "flex" }}>
                <div className="imageContainer">
                  {!tempAvatar ?
                    <DefaultAvatar />
                    :
                    <img
                      className="avatar"
                      src={`data:image/png;base64,${tempAvatar}`}
                      alt=""
                    />
                  }
                  {tempAvatar &&
                    <img
                      src={images.subtraction}
                      className="subtraction"
                      alt=""
                      onClick={() => setTempAvatar(null)}
                    />
                  }
                </div>
                <div style={{ flexShrink: 0, flexGrow: 1, marginBottom: "4em", maxWidth: "100%" }}>
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      const base64 = resizeFile(acceptedFiles[0]);
                      base64.then((result) => {
                        setTempAvatar(String(result).split(",")[1]);
                      });
                    }}
                    maxFiles={1}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div {...getRootProps()} className="dropzone">
                          <img src={images.uploadIcon} alt="" />
                          <span style={{ color: "#75615e", margin: "1em", wordBreak: "break-all" }}>{t("certificatesScreen.addLogo")}</span>
                          <StyledButton
                            buttonText={t("certificatesScreen.fileSelect")}
                            type="button"
                            onClick={() => { }} />
                        </div><input {...getInputProps()} />
                      </>
                    )}
                  </Dropzone>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "right", columnGap: "1em" }}>
                <StyledButton
                  buttonText={t("certificatesScreen.generateManyCertificates")}
                  type="button"
                  onClick={() => props.seGetManyCertificatesModalOpen(true)} />
                <StyledButton
                  buttonText={t("certificatesScreen.download")}
                  type="submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};