/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React, { useEffect, useState } from "react";
import TrainingType from "../../../interfaces/training/TrainingType";
import { TrainingItemRow } from "../../components/TrainingItemRow";
import { StyledSectionWrapper } from "../../components/StyledSectionWrapper";
import { TrainingInfo } from "./TrainingInfo";
import { StyledTable } from "../../components/StyledTable";
import { AddStudentModal } from "./AddStudentModal";
import { AddTrainingModal } from "./AddTrainingModal";
import images from "../../../assets/images/images";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  createLink,
  deleteTraining,
  deleteTrainingUser,
  getAllTrainings,
  getTrainingUsers,
  sendLink,
} from "../../../saga/training/actions";
import { AppState } from "../../../store/store";
import UserType from "../../../interfaces/user/UserType";
import { PageableQuery } from "../../../interfaces/pagination/PageableQuery";
import TrainingUserType from "../../../interfaces/training/TrainingUserType";
import { StyledModal } from "../../components/StyledModal";
import LocalizationContext from "../../../context/LocalizationContext";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import colors from "../../../assets/styles/_colors.module.scss";
import { trainingUserTableOptions } from "../../../constants/table.constants";
import { SectionWithFabButton } from "../../components/SectionWithFabButton";
import { StyledButton } from "../../components/StyledButton";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";
import { DateTime } from "luxon";

export const TrainingsScreen = () => {
  const dispatch: Dispatch = useDispatch();
  const { t } = React.useContext(LocalizationContext);
  const [trainingPage, setTrainingPage] = useState(0);
  const [trainingLimit, setTrainingLimit] = useState(10);
  const [trainingUserPage, setTrainingUserPage] = useState(0);
  const [trainingUserLimit, setTrainingUserLimit] = useState(10);
  const [trainingSearchQuery, setTrainingSearchQuery] = useState("");
  const [trainingUserSearchQuery, setTrainingUserSearchQuery] = useState("");
  const [selectedTraining, setSelectedTraining] = useState<TrainingType | null>(
    null
  );
  const [selectedUser, setSelectedUser] = useState<TrainingUserType | null>(
    null
  );
  const [withEmailSend, setWithEmailSend] = useState<boolean>(false);
  const [withOpenNewMeetTab, setWithOpenNewMeetTab] = useState<boolean>(false);

  const [modalType, setModalType] = useState<
    | "ADD_TRAINING"
    | "EDIT_TRAINING"
    | "ADD_STUDENT"
    | "EDIT_STUDENT"
    | "DELETE_TRAINING"
    | "DELETE_USER"
    | "GENERATE_LINK"
    | null
  >(null);

  const authenticatedUser: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );
  const trainings: TrainingType[] = useSelector(
    (state: AppState) => state.trainingReducer.trainings
  );

  const trainingUsers: TrainingUserType[] = useSelector(
    (state: AppState) => state.trainingReducer.trainingUsers
  );

  const trainingsPages: number = useSelector(
    (state: AppState) => state.trainingReducer.trainingsPages
  );

  const trainingUsersPages: number = useSelector(
    (state: AppState) => state.trainingReducer.trainingUsersPages
  );

  const isFetchingTrainingUsers: boolean = useSelector(
    (state: AppState) => state.trainingReducer.isFetchingTrainingUsers
  );

  const isFetching: boolean = useSelector(
    (state: AppState) => state.trainingReducer.isFetching
  );

  const error: string | null = useSelector(
    (state: AppState) => state.trainingReducer.error
  );

  const fetchTrainings = (page: number, limit: number) => {
    setTrainingPage(page - 1);
    setTrainingLimit(limit);
    let query: PageableQuery = {
      page,
      limit,
    };

    if (trainingSearchQuery && trainingSearchQuery.length > 0) {
      query = {
        ...query,
        search: trainingSearchQuery,
      };
    }

    dispatch(getAllTrainings(authenticatedUser!.id, query));
  };

  const fetchTrainingUsers = (page: number, limit: number) => {
    setTrainingUserPage(page - 1);
    setTrainingUserLimit(limit);
    let query: PageableQuery = {
      page,
      limit,
      sortBy: "lastName:ASC",
    };

    if (trainingUserSearchQuery && trainingUserSearchQuery.length > 0) {
      query = {
        ...query,
        search: trainingUserSearchQuery,
      };
    }

    dispatch(getTrainingUsers(selectedTraining!.id!, query));
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => {
          fetchTrainings(1, trainingLimit);
          gaEventTracker("Training search");
      },
      trainingSearchQuery && trainingSearchQuery.length > 0 ? 500 : 0
    );
    
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, trainingSearchQuery]);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        if(selectedTraining){
          fetchTrainingUsers(1, trainingUserLimit);
          gaEventTracker("Training user search");
        }
      },
      trainingUserSearchQuery && trainingUserSearchQuery.length > 0 ? 500 : 0
    );

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedTraining, trainingUserSearchQuery]);

  useEffect(() => {
    if (selectedTraining && !isFetching) {
      const training = trainings.find((t) => t.id! === selectedTraining.id!);
      if (training) setSelectedTraining(training);
    }

    if (!isFetching && !error && !!modalType) {
      setModalType(null);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingTrainingUsers && !error && !!modalType) {
      setModalType(null);
    }
  }, [isFetchingTrainingUsers]);

  const onTrainingClick = (training: TrainingType) => {
    if (selectedTraining && selectedTraining.id === training.id) {
      setSelectedTraining(null);
    } else {
      setSelectedTraining(training);
    }
  };

  const gaEventTracker = useAnalyticsEventTracker('Training');
  
  const renderModal = () => {
    switch (modalType) {
      case "ADD_STUDENT":
        return (
          <AddStudentModal
            training={selectedTraining!}
            onCloseButtonClick={() => setModalType(null)}
            resetPage={() => setTrainingUserPage(0)}
          />
        );
      case "EDIT_STUDENT":
        return (
          <AddStudentModal
            training={selectedTraining!}
            user={selectedUser!}
            resetPage={() => setTrainingUserPage(0)}
            onCloseButtonClick={() => {
              setModalType(null);
              setSelectedUser(null);
            }}
          />
        );
      case "ADD_TRAINING":
        return (
          <AddTrainingModal
            onCloseButtonClick={() => setModalType(null)}
            resetPage={() => setTrainingPage(0)}
          />
        );
      case "EDIT_TRAINING":
        return (
          <AddTrainingModal
            training={selectedTraining!}
            onCloseButtonClick={() => setModalType(null)}
          />
        );
      case "DELETE_TRAINING":
        return (
          <StyledModal
            header={t("trainingsScreen.deleteTrainingLabel")}
            onAcceptButtonClick={() => {
              dispatch(deleteTraining(selectedTraining!.id!));
              setModalType(null);
              setTrainingLimit(10);
              setTrainingSearchQuery("");
              setTrainingPage(0);
              setSelectedTraining(null);
              gaEventTracker("Delete training");
            }}
            acceptButtonLabel={t("global.delete")}
            acceptButtonType="button"
            onCloseButtonClick={() => setModalType(null)}
          >
            <div className="deleteModalContent">
              <span>{t("trainingsScreen.deleteTrainingInfo")}</span>
            </div>
          </StyledModal>
        );
      case "GENERATE_LINK":
        return (
          <StyledModal
            header={t("trainingsScreen.linkGeneration")}
            onAcceptButtonClick={() => {
              setModalType(null);
              dispatch(createLink(selectedTraining!.id!, withEmailSend, withOpenNewMeetTab))
              setWithEmailSend(false);
              setWithOpenNewMeetTab(false);
              gaEventTracker("Generate Link");
            }}
            acceptButtonLabel={t("trainingsScreen.generate")}
            acceptButtonType="button"
            onCloseButtonClick={() => setModalType(null)}
          >
            <div className="deleteModalContent">
              <span>{t("trainingsScreen.areYouSureToGenerate")}</span>
            </div>
          </StyledModal>
        );
      case "DELETE_USER":
        return (
          <StyledModal
            header={t("trainingsScreen.deleteTrainingUserLabel")}
            onAcceptButtonClick={() => {
              dispatch(deleteTrainingUser(selectedUser!.id!));
              setModalType(null);
              setTrainingUserLimit(10);
              setSelectedUser(null);
              setTrainingUserSearchQuery("");
              gaEventTracker("Delete training user");
            }}
            acceptButtonType="button"
            acceptButtonLabel={t("global.delete")}
            onCloseButtonClick={() => setModalType(null)}
          >
            <div className="deleteModalContent">
              <span>{t("trainingsScreen.deleteTrainingUserInfo")}</span>
            </div>
          </StyledModal>
        );
    }
  };

  return (
    <div
      id="trainingsScreen"
      className={selectedTraining ? "withSelected" : ""}
    >
      <div className={classNames("topContainer", { selectedTraining })}>
        <StyledSectionWrapper
          id="trainingsListContainer"
          label={t("trainingsScreen.trainingsList")}
          withPagination={true}
          pages={trainingsPages}
          onLoadMore={(page: number, limit: number) =>
            fetchTrainings(page, limit)
          }
          withSearch={true}
          page={trainingPage}
          limit={trainingLimit}
          searchQuery={trainingSearchQuery}
          onSearchQueryChange={setTrainingSearchQuery}
        >
          {isFetching ? (
            <div className="progressWrapper">
              <CircularProgress style={{ color: colors.primary }} />
            </div>
          ) : (
            <>
              <div className="trainingRows">
                {trainings && trainings.length > 0 ? (
                  trainings.map((item, idx) => (
                    <TrainingItemRow
                      key={`trainingRow${idx}`}
                      training={item}
                      selectedTraining={selectedTraining}
                      onClick={onTrainingClick}
                    />
                  ))
                ) : (
                  <div className="emptyTable">
                    {t("trainingsScreen.noTrainings")}
                  </div>
                )}
                <div style={{ height: 56, marginRight: 18 }}>
                  <img
                    className="fabButtonIcon trainingTable"
                    src={images.fabButtonIcon}
                    alt=""
                    onClick={() => setModalType("ADD_TRAINING")}
                  />
                </div>
              </div>
            </>
          )}
        </StyledSectionWrapper>
        {selectedTraining && (
          <StyledSectionWrapper
            id="trainingDetailsContainer"
            label={t("trainingsScreen.trainingInfo")}
          >
            {isFetching ? (
              <div className="progressWrapper">
                <CircularProgress style={{ color: colors.primary }} />
              </div>
            ) : (
              <TrainingInfo
                training={selectedTraining}
                setWithOpenNewMeeTab = {setWithOpenNewMeetTab}
                onDelete={() => setModalType("DELETE_TRAINING")}
                onEdit={() => setModalType("EDIT_TRAINING")}
                onGenerateLink={() => setModalType("GENERATE_LINK")}
              />
            )}
          </StyledSectionWrapper>
        )}
      </div>
      {selectedTraining && (
        <StyledSectionWrapper
          id="bottomContainer"
          label={t("trainingsScreen.students")}
          withPagination={true}
          pages={trainingUsersPages}
          onLoadMore={(page: number, limit: number) =>
            fetchTrainingUsers(page, limit)
          }
          withSearch={true}
          page={trainingUserPage}
          limit={trainingUserLimit}
          searchQuery={trainingUserSearchQuery}
          additionalButton={
            <div style={{ flexGrow: "1" }}>
              <div style={{ float: "right" }}>
                <StyledButton
                  buttonText={t("trainingsScreen.sendLink")}
                  type="button"
                  onClick={() => {
                    if (selectedTraining!.link) {
                      dispatch(sendLink({trainingId: selectedTraining!.id!, timeZone: DateTime.now().zoneName}));
                      gaEventTracker("Send link to training users");
                    } else {
                      setWithEmailSend(true);
                      setModalType("GENERATE_LINK");
                    }
                  }}
                />
              </div>
            </div>
          }
          onSearchQueryChange={setTrainingUserSearchQuery}
        >
          {isFetchingTrainingUsers ? (
            <div className="progressWrapper">
              <CircularProgress style={{ color: colors.primary }} />
            </div>
          ) : (
            <SectionWithFabButton onAdd={() => setModalType("ADD_STUDENT")}>
              <div className="tableWrapper">
                <StyledTable
                  tableOptions={trainingUserTableOptions()}
                  data={trainingUsers}
                  emptyInfo={t("trainingsScreen.noStudents")}
                  onDelete={(item) => {
                    setSelectedUser(item);
                    setModalType("DELETE_USER");
                    setTrainingUserPage(0);
                  }}
                  onEdit={(item) => {
                    setSelectedUser(item);
                    setModalType("EDIT_STUDENT");
                  }}
                />
              </div>
            </SectionWithFabButton>
          )}
        </StyledSectionWrapper>
      )}
      {modalType && renderModal()}
    </div>
  );
};
