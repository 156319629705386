/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import {NetworkActionTypes} from '../interfaces/network/NetworkActionTypes';
import {NetworkReducer} from '../interfaces/network/NetworkReducer';
import {
  CLEAR_NETWORK_ERROR,
  CLEAR_NETWORK_MESSAGE,
  NETWORK_ACTION_ERROR,
  SHOW_NETWORK_MESSAGE,
} from '../saga/network/actions';
import {ExceptionUtil} from '../utils/exception.util';

export const initialState: NetworkReducer = {
  isFetching: false,
  error: '',
  message: '',
};

export const networkReducer = (
  state: NetworkReducer = {...initialState},
  action: NetworkActionTypes,
): NetworkReducer => {
  switch (action.type) {
    case NETWORK_ACTION_ERROR:
      let payload: string =
        action.payload && action.payload.message
          ? action.payload.message.toLowerCase()
          : action.payload.toLowerCase();

      const error = ExceptionUtil.setErrorType(payload);

      if (error && error !== '') {
        return {
          ...state,
          isFetching: false,
          error: error,
        };
      }
      return {...state, isFetching: false, error: ''};
    case CLEAR_NETWORK_ERROR:
      return {...state, isFetching: false, error: ''};
    case SHOW_NETWORK_MESSAGE:
      return {...state, isFetching: false, message: action.payload};
    case CLEAR_NETWORK_MESSAGE:
      return {...state, isFetching: false, message: ''};
    default:
      return state;
  }
};
