/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { FaceMesh } from "@mediapipe/face_mesh";
import React, { useRef, useEffect, useState } from "react";
import * as Facemesh from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";
import Webcam from "react-webcam";
import { hexToRgb, rgbToHex } from "../../utils/color.util";
import html2canvas from "html2canvas";
import tinycolor from "tinycolor2";

var nj = require("numjs");

// const brow_area = [8, 9, 151, 337, 299, 333, 298, 301, 389, 368, 383, 353, 445, 444, 443, 442, 441, 417];
const brow_area = [
  8, 285, 295, 282, 283, 276, 300, 293, 334, 296, 336, 9, 151, 337, 299, 333,
  298, 301, 389, 368, 383, 353, 445, 444, 443, 442, 441, 417,
];
const only_left_brow = [285, 295, 282, 283, 276, 300, 293, 334, 296, 336];
let ua = navigator.userAgent;
var isSafari =
  !!ua.match(/Macintosh/i) || !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const CameraScreen = (props) => {
  const { base64Image } = props;
  const [color, setColor] = useState("#e3dccf");
  const [
    defaultEyebrowColorFromImage,
    setDefaultEyebrowColorFromImage,
  ] = useState(null);
  const [eyebrow, setEyebrow] = useState("");
  const [brightness, setBrightness] = useState(0);
  const colorRef = React.useRef(null);
  const defaultEyebrowColorFromImageRef = React.useRef(
    defaultEyebrowColorFromImage
  );
  const previousColorWithTempRef = React.useRef(color);
  const previousColorWithBrRef = React.useRef(color);

  const spacingRef = React.useRef(0);
  const heightRef = React.useRef(0);
  const sizeRef = React.useRef(0);
  const eyebrowRef = React.useRef(null);

  const webcamRef = useRef(null);
  const divRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasRef2 = useRef(null);
  const canvasInColorRef = useRef(null);
  var camera = null;

  useEffect(() => {
    if (colorRef.current) {
      changeColor();
    }
  }, [color]);

  const changeColor = async () => {
    const canvasElement = canvasInColorRef.current;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    let image = document.getElementById("source");
    canvasCtx.drawImage(image, 0, 0, canvasElement.width, canvasElement.height);
    const imgData = canvasCtx.getImageData(
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    let lerpMixAmount = 0.5;
    let brightnessOffset = brightness;
    if(brightness < 0) {
      brightnessOffset *= -1;
    }
    let restOfMixAmount = 0.8 - lerpMixAmount;
    lerpMixAmount += (restOfMixAmount/100) * brightnessOffset;
    for (let i = 0; i < imgData.data.length; i += 4) {
      const hexFromRBG = rgbToHex(
        imgData.data[i],
        imgData.data[i + 1],
        imgData.data[i + 2]
      );
      const colorHex = colorRef.current;
      const hexFromLerp = lerpColor(hexFromRBG, colorHex, lerpMixAmount);
      const newRGB = hexToRgb(hexFromLerp);

      imgData.data[i] = newRGB.r;
      imgData.data[i + 1] = newRGB.g;
      imgData.data[i + 2] = newRGB.b;
    }
    await createImageBitmap(imgData).then((imgBitmap) =>
      canvasCtx.drawImage(
        imgBitmap,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      )
    );

    const imageInColor = document.getElementById("source_in_color");
    imageInColor.src = canvasElement.toDataURL();
  };

  const onResults = (results) => {
    canvasRef.current.width = window.innerWidth;
    canvasRef.current.height = window.innerHeight;
    canvasRef2.current.width = window.innerWidth;
    canvasRef2.current.height = window.innerHeight;

    const canvasElement = canvasRef.current;
    canvasElement.height = canvasElement.width;
    const canvasElement2 = canvasRef2.current;
    canvasElement2.height = canvasElement2.width;
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.drawImage(
      results.image,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    let image = document.getElementById("source_in_color");

    if (results.multiFaceLandmarks && image) {
      for (const landmarks of results.multiFaceLandmarks) {
        drawBrow("L", image, landmarks, canvasElement2);
        drawBrow("R", image, landmarks, canvasElement2);
      }
    }
    canvasCtx.restore();
  };

  const formatPoint = (point, canvasElement) => {
    return {
      x: Math.ceil(point.x * canvasElement.width),
      y: Math.ceil(point.y * canvasElement.width),
      z: point.z,
    };
  };

  function rotatePoint(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (x - cx) + sin * (y - cy) + cx,
      ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [nx, ny];
  }

  const drawBrow = (side, image, landmarks, canvasElement) => {
    const canvasCtx = canvasElement.getContext("2d");
    canvasCtx.imageSmoothingEnabled = true;
    canvasCtx.imageSmoothingQuality = "high";

    let checkLineLeft = formatPoint(landmarks[21], canvasElement);
    let checkLineRight = formatPoint(landmarks[251], canvasElement);
    let checkLineLength = Math.floor(
      Math.sqrt(
        Math.pow(checkLineLeft.x - checkLineRight.x, 2) +
          Math.pow(checkLineLeft.y - checkLineRight.y, 2)
      )
    );

    let angle = Math.asin(
      (checkLineRight.y - checkLineLeft.y) / checkLineLength
    );
    let { minX, maxX, maxY, minY, width, height } = calculateMinAndMax(
      side === "L"
        ? Facemesh.FACEMESH_LEFT_EYEBROW
        : Facemesh.FACEMESH_RIGHT_EYEBROW,
      landmarks,
      canvasElement,
      angle * 57.29577951308,
      side
    );
    let rightBrowPoint = formatPoint(landmarks[107], canvasElement);
    let leftBrowPoint = formatPoint(landmarks[336], canvasElement);
    let distanceBetweenBrows = Math.floor(
      Math.sqrt(
        Math.pow(leftBrowPoint.x - rightBrowPoint.x, 2) +
          Math.pow(leftBrowPoint.y - rightBrowPoint.y, 2)
      )
    );

    let x = minX + Math.floor(Math.abs(maxX - minX) / 2);
    let y = minY + Math.floor(Math.abs(maxY - minY) / 2);
    const spacingDiff = (spacingRef.current * distanceBetweenBrows) / 2 / 100;
    const XPointDiff = ((width / 2) * sizeRef.current) / 100 / 2;
    const YPointDiff = (-heightRef.current * height) / 2 / 100;
    const widthDiff = ((width / 2) * sizeRef.current) / 100;
    const heightDiff = ((height / 2) * sizeRef.current) / 100;

    if (side === "R") {
      canvasCtx.scale(-1, 1);
      canvasCtx.translate(-x, y);
      canvasCtx.rotate(-angle);
      canvasCtx.drawImage(
        image,
        -width / 2 + spacingDiff - XPointDiff,
        -height / 2 + YPointDiff,
        width + widthDiff,
        height + heightDiff
      );
      const imgData = canvasCtx.getImageData(
        -width / 2 + spacingDiff - XPointDiff,
        -height / 2 + YPointDiff,
        canvasRef2.current.width,
        canvasRef2.current.height
      );
      let r = 0,
        g = 0,
        b = 0;
      let rCounter = 0,
        gCounter = 0,
        bCounter = 0;
      for (let i = 0; i < imgData.data.length; i += 4) {
        if (imgData.data[i] !== 0) rCounter++;
        if (imgData.data[i + 1] !== 0) gCounter++;
        if (imgData.data[i + 2] !== 0) bCounter++;
        r += imgData.data[i];
        g += imgData.data[i + 1];
        b += imgData.data[i + 2];
      }

      if (
        defaultEyebrowColorFromImageRef.current === null &&
        (rCounter !== 0 || gCounter !== 0 || b !== 0)
      ) {
        const avgR = rCounter !== 0 ? r / rCounter : 0;
        const avgG = gCounter !== 0 ? g / gCounter : 0;
        const avgB = bCounter !== 0 ? b / bCounter : 0;
        const hex = rgbToHex(avgR, avgG, avgB);
        setDefaultEyebrowColorFromImage(hex);
        defaultEyebrowColorFromImageRef.current = hex;

        setColor(hex);
        colorRef.current = hex;
        previousColorWithTempRef.current = colorRef.current;
        previousColorWithBrRef.current = colorRef.current;
      }

      if (!isSafari) {
        canvasCtx.translate(x, -y);
      }
    } else {
      canvasCtx.translate(x, y);
      canvasCtx.rotate(angle);
      canvasCtx.drawImage(
        image,
        -width / 2 + spacingDiff - XPointDiff,
        -height / 2 + YPointDiff,
        width + widthDiff,
        height + heightDiff
      );
      if (!isSafari) {
        canvasCtx.rotate(-angle);
        canvasCtx.translate(-x, -y);
      }
    }
  };

  function lerpColor(a, b, amount) {
    var ah = +a.replace("#", "0x"),
      ar = (ah & 0xff0000) >> 16,
      ag = ((ah & 0x00ff00) >> 8) & 0xff,
      ab = ah & 0x0000ff & 0xff,
      bh = +b.replace("#", "0x"),
      br = (bh & 0xff0000) >> 16,
      bg = ((bh & 0x00ff00) >> 8) & 0xff,
      bb = bh & 0x0000ff & 0xff,
      rr = ar + amount * (br - ar),
      rg = ag + amount * (bg - ag),
      rb = ab + amount * (bb - ab);

    return (
      "#" +
      (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
    );
  }

  const calculateMinAndMax = (
    browPoints,
    allLandmarks,
    canvasElement,
    angle,
    side
  ) => {
    let leftBrowPoints = browPoints
      .flat(1)
      .filter((item, pos) => browPoints.flat(1).indexOf(item) === pos);
    leftBrowPoints = leftBrowPoints.map((i) => allLandmarks[i]);

    let minX = 1,
      maxX = 0,
      maxY = 0,
      minY = 1;

    let A = allLandmarks[side === "R" ? 107 : 336]; //punkty potrzebne do wyznaczenia środka brwi
    let B = allLandmarks[side === "R" ? 46 : 276];

    let tempMinX = 1,
      tempMaxX = 0,
      tempMaxY = 0,
      tempMinY = 1;

    let cX = B.x + (A.x - B.x) / 2; //punkt, względem którego będziemy obracać brew do pomiaru szerokości i wysokości
    let cY = B.y + (B.y - A.y) / 2;

    for (const point of leftBrowPoints) {
      const rotatedPoint = rotatePoint(cX, cY, point.x, point.y, angle);
      if (rotatedPoint[0] < tempMinX) tempMinX = rotatedPoint[0];
      if (rotatedPoint[0] > tempMaxX) tempMaxX = rotatedPoint[0];
      if (rotatedPoint[1] > tempMaxY) tempMaxY = rotatedPoint[1];
      if (rotatedPoint[1] < tempMinY) tempMinY = rotatedPoint[1];
    }
    tempMinX = Math.ceil(tempMinX * canvasElement.width);
    tempMaxX = Math.ceil(tempMaxX * canvasElement.width);
    tempMinY = Math.ceil(tempMinY * canvasElement.height);
    tempMaxY = Math.ceil(tempMaxY * canvasElement.height); //kawałek z temp jest potrzebny tylko do wyznaczania szerokości i wysokości brwi, z uwzględnieniem obrotu głowy

    const width = side === "L" ? tempMaxX - tempMinX : -(tempMaxX - tempMinX);
    const height = tempMaxY - tempMinY;

    for (const point of leftBrowPoints) {
      if (point.x < minX) minX = point.x;
      if (point.x > maxX) maxX = point.x;
      if (point.y > maxY) maxY = point.y;
      if (point.y < minY) minY = point.y;
    }

    minX = Math.ceil(minX * canvasElement.width);
    maxX = Math.ceil(maxX * canvasElement.width);
    minY = Math.ceil(minY * canvasElement.height);
    maxY = Math.ceil(maxY * canvasElement.height);

    return {
      minX,
      maxX,
      maxY,
      minY,
      width,
      height,
    };
  };

  const format_response = (response, canvasElement) => {
    const mapped_response = [];
    for (const face of response) {
      for (const landmark of face) {
        const x = landmark.x;
        const y = landmark.y;

        mapped_response.push({
          x: x * canvasElement.width,
          y: y * canvasElement.height,
        });
      }
      return mapped_response;
    }
  };

  const takePicture = () => {
    html2canvas(divRef.current, { allowTaint: true, scale: 1 }).then(
      (canvas) => {
        let canvasURL = canvas.toDataURL();
        let base64Canvas = canvasURL.split(",")[1];
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: "picture", value: base64Canvas })
          );
        }
      }
    );
  };

  const colorTemperatureToHsb = (tempDeg, hexColor) => {
    const tinyObject = tinycolor(hexColor);
    const hsv = tinyObject.toHsv();
    let h = hsv.h;
    let s = hsv.s;
    let b = hsv.v;

    if (h < 90 || h > 270) {
      h = parseFloat(h) + Number(tempDeg) / 4;
    } else {
      h = parseFloat(h) - Number(tempDeg) / 4;
    }
    const finalHex = tinycolor({ h, s, v: b }).toHex().toString();
    setColor(finalHex);
    colorRef.current = finalHex;
    previousColorWithBrRef.current = colorRef.current;
  };

  const colorBrightnessToHsb = (brightness, hexColor) => {
    const tinyObject = tinycolor(hexColor);

    let colorWithBritnessChange;
    brightness = Number(brightness);
    if (brightness < 0) {
      colorWithBritnessChange = tinyObject
        .darken(-brightness)
        .toString();
    } else {
      colorWithBritnessChange = tinyObject
        .brighten(brightness)
        .toString();
    }

    setColor(colorWithBritnessChange);
    colorRef.current = colorWithBritnessChange;
    previousColorWithTempRef.current = colorRef.current;
  };

  useEffect(() => {
    const handleMessage = (event) => {
      //alert(JSON.stringify(event.data))
      //TODO - to jest tylko test koloru, należy zrobić obsługę różnych typów wiadomości.
      // struktura wiadomości z mobilki znajduje się w pliku eventMessages.txt w głównym katalogu projektu.
      if (event && event.data) {
        switch (event.data.type) {
          case "color":
            setBrightness(0);
            setColor(event.data.value);
            colorRef.current = event.data.value;
            previousColorWithTempRef.current = colorRef.current;
            previousColorWithBrRef.current = colorRef.current;
            break;
          case "spacing":
            spacingRef.current = event.data.value;
            break;
          case "height":
            heightRef.current = event.data.value;
            break;
          case "size":
            sizeRef.current = event.data.value;
            break;
          case "eyebrow":
            setDefaultEyebrowColorFromImage(null);
            eyebrowRef.current = event.data.value;
            setEyebrow(event.data.value);
            break;
          case "takepicture":
            takePicture();
            break;
          case "temperature":
            colorTemperatureToHsb(
              event.data.value,
              previousColorWithTempRef.current
            );
            break;
          case "brightness":
            setBrightness(event.data.value);
            colorBrightnessToHsb(
              event.data.value,
              previousColorWithBrRef.current
            );
            break;
          default:
            break;
        }
      }
    };

    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
      },
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    faceMesh.onResults((result) => onResults(result, color));

    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      camera = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          if (webcamRef.current)
            await faceMesh.send({ image: webcamRef.current.video });
        },
        facingMode: "user",
      });
      camera.start();
    }
    window.addEventListener("message", handleMessage, { passive: true });
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  const videoConstraints = {
    width: { min: 720 },
    height: { min: 720 },
    aspectRatio: 1,
    facingMode: "user",
  };

  const cameraWidth = window.innerWidth;
  const cameraHeight = (window.innerWidth / 3) * 4;

  return (
    <center>
      <div ref={divRef} id="App">
        <Webcam
          ref={webcamRef}
          style={{
            // position: "absolute",
            // marginLeft: "auto",
            // marginRight: "auto",
            // left: 0,
            // right: 0,
            // textAlign: "center",
            // zindex: 9,
            // // height: "100%",
            display: "none",
          }}
          videoConstraints={videoConstraints}
          width={cameraWidth}
          height={cameraHeight}
        />
        <img
          id="source"
          src={`data:image/png;base64,${eyebrow ? eyebrow : base64Image}`}
          style={{ display: "none" }}
          alt=""
        />
        <img
          id="source_in_color"
          style={{ display: "none" }}
          src={`data:image/png;base64,${eyebrow ? eyebrow : base64Image}`}
          alt=""
        />
        <canvas
          ref={canvasRef}
          id="output_canvas"
          className="output_canvas"
          // height="100%"
          style={{
            //position: "absolute",
            //marginLeft: "auto",
            //marginRight: "auto",
            left: 0,
            transform: "scaleX(-1)",
            // right: 0,
            // textAlign: "center",
            // zindex: 9,
            //height: '100%',
            height: cameraHeight,
            width: cameraWidth,
          }}
        ></canvas>
        <canvas
          ref={canvasRef2}
          id="output_canvas2"
          className="output_canvas"
          // height="100%"
          style={{
            position: "absolute",
            transform: "scaleX(-1)",
            //marginLeft: "auto",
            //marginRight: "auto",
            left: 0,
            top: 0,
            // right: 0,
            // textAlign: "center",
            // zindex: 9,
            //height: '100%',
            height: cameraHeight,
            width: cameraWidth,
          }}
        ></canvas>
        <canvas
          ref={canvasInColorRef}
          id="canvas_in_color"
          className="output_canvas"
          style={{
            display: "none",
            top: 0,
            left: 0,
            transform: "scaleX(-1)",
          }}
        ></canvas>
      </div>
    </center>
  );
};

export default CameraScreen;
