/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { AxiosResponse } from "axios";
import { AddTrainingUserRequest } from "../interfaces/training/AddTrainingUserRequest";
import GenerateManyCertificatesType from "../interfaces/training/GenerateManyCertificatesType";
import GenerateOneCertificateType from "../interfaces/training/GenerateOneCertificateType";
import { GetAllTrainingRequest } from "../interfaces/training/GetAllTrainingRequest";
import { GetTrainingUsersRequest } from "../interfaces/training/GetTrainingUsersRequest";
import { SendLinkRequestType } from "../interfaces/training/SendLinkRequestType";
import TrainingType from "../interfaces/training/TrainingType";
import { axiosService, axiosServiceWithoutToken } from "./axios.service";

const rootEyebrowCare: string = "/eyebrowCare";
const trainingRoot: string = "/training";
const rootTrainingUser: string = "/trainingUser";

function getAllTrainings(data: GetAllTrainingRequest): Promise<AxiosResponse> {
  return axiosService.get(`${trainingRoot}/all/${data.userId}`, {
    params: data.query,
  });
}

function getAllTrainingsWithTrainingUsers(data: GetAllTrainingRequest): Promise<AxiosResponse> {
  return axiosService.get(`${trainingRoot}/withTrainingUsers/${data.userId}`, {
    params: data.query,
  });
}

function addTraining(data: TrainingType): Promise<AxiosResponse> {
  return axiosService.post(`${trainingRoot}`, data);
}

function addTrainingUser(data: AddTrainingUserRequest): Promise<AxiosResponse> {
  return axiosService.post(`${rootTrainingUser}`, data);
}

function getTrainingUsers(
  data: GetTrainingUsersRequest
): Promise<AxiosResponse> {
  return axiosService.get(`${rootTrainingUser}/training/${data.trainingId}`, {
    params: data.query,
  });
}

function deleteTrainingUser(trainignUserId: string): Promise<AxiosResponse> {
  return axiosService.delete(`${rootTrainingUser}/${trainignUserId}`);
}

function deleteTraining(trainignId: string): Promise<AxiosResponse> {
  return axiosService.delete(`${trainingRoot}/${trainignId}`);
}

function updateTraining(data: TrainingType): Promise<AxiosResponse> {
  return axiosService.put(`${trainingRoot}`, data);
}

function createLink(id: string): Promise<AxiosResponse> {
  return axiosService.put(`${trainingRoot}/createLink/${id}`);
}

function sendLink(data: SendLinkRequestType): Promise<AxiosResponse> {
  return axiosService.post(`${rootTrainingUser}/link/training/${data.trainingId}?timeZone=${data.timeZone}`);
}

function doesLinkExist(link: string): Promise<AxiosResponse> {
  return axiosServiceWithoutToken.post(`${trainingRoot}/doesLinkExist`, {link});
}

function updateTrainingUser(
  data: AddTrainingUserRequest
): Promise<AxiosResponse> {
  return axiosService.put(`${rootTrainingUser}`, data);
}

function generateOneCertificate(data: GenerateOneCertificateType): Promise<AxiosResponse> {
  return axiosService.post(`${rootTrainingUser}/certificate`, data);
}

function generateManyCertificates(data: GenerateManyCertificatesType): Promise<AxiosResponse> {
  return axiosService.post(`${rootTrainingUser}/manyCertificates`, data);
}

const trainingService = {
  getAllTrainings,
  addTraining,
  addTrainingUser,
  getTrainingUsers,
  deleteTrainingUser,
  deleteTraining,
  updateTraining,
  updateTrainingUser,
  generateOneCertificate,
  generateManyCertificates,
  getAllTrainingsWithTrainingUsers,
  doesLinkExist,
  createLink,
  sendLink
};

export default trainingService;
