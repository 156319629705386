/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import LoginRequest from "../../interfaces/auth/LoginRequest";
import RegistrationRequest from "../../interfaces/auth/RegistrationRequest";
import { PageableQuery } from "../../interfaces/pagination/PageableQuery";
import { ResetPasswordRequest } from "../../interfaces/reset-password/ResetPasswordRequest";
import UpdateUserRequest from "../../interfaces/user/UpdateUserRequest";
import { UserActionTypes } from "../../interfaces/user/UserActionTypes";
import UserType from "../../interfaces/user/UserType";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_REGISTRATION = "USER_REGISTRATION";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";
export const CLEAR_AUTHENTICATED_USER = "CLEAR_AUTHENTICATED_USER";
export const USER_ACTION_ERROR = "USER_ACTION_ERROR";
export const SET_TOKEN = "SET_TOKEN";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const GET_AVATAR = "GET_AVATAR";
export const GET_AVATAR_SUCCESS = "GET_AVATAR_SUCCESS";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const RESET_PASSWORD_SEND_EMAIL = "RESET_PASSWORD_SEND_EMAIL";
export const RESET_PASSWORD_SEND_EMAIL_SUCCESS = "RESET_PASSWORD_SEND_EMAIL_SUCCESS"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const VERIFY_EMAIL = "VALIDATE_EMAIL";
export const VERIFY_EMAIL_SUCCESS="VALIDATE_EMAIL_SUCCESS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const TOGGLE_USER_STATUS = "TOGGLE_USER_STATUS";
export const TOGGLE_USER_STATUS_SUCCESS = "TOGGLE_USER_STATUS_SUCCESS";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export function login(data: LoginRequest): UserActionTypes {
  return {
    type: USER_LOGIN,
    payload: data,
  };
}

export function loginSuccess(user: any): UserActionTypes {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: user,
  };
}

export function register(data: RegistrationRequest): UserActionTypes {
  return {
    type: USER_REGISTRATION,
    payload: data,
  };
}

export function registerSuccess(): UserActionTypes {
  return {
    type: USER_REGISTRATION_SUCCESS,
  };
}

export function logout(): UserActionTypes {
  return {
    type: USER_LOGOUT,
  };
}

export function clearAuthenticatedUser(): UserActionTypes {
  return {
    type: CLEAR_AUTHENTICATED_USER,
  };
}

export function userActionError(error: string | null): UserActionTypes {
  return {
    type: USER_ACTION_ERROR,
    payload: error,
  };
}

export function setToken(token: string): UserActionTypes {
  return {
    type: SET_TOKEN,
    payload: token,
  };
}

export function getUser(id: string): UserActionTypes {
  return {
    type: GET_USER,
    payload: id,
  };
}

export function getUserSuccess(user: UserType): UserActionTypes {
  return {
    type: GET_USER_SUCCESS,
    payload: user,
  };
}

export function updateUser(data: UpdateUserRequest): UserActionTypes {
  return {
    type: UPDATE_USER,
    payload: data,
  };
}

export function updateUserSuccess(user: UserType): UserActionTypes {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user,
  };
}

export function getAvatar(userId: string): UserActionTypes {
  return {
    type: GET_AVATAR,
    payload: userId,
  };
}

export function getAvatarSuccess(avatar: string): UserActionTypes {
  return {
    type: GET_AVATAR_SUCCESS,
    payload: avatar,
  };
}

export function updateAvatar(avatar: string): UserActionTypes {
  return {
    type: UPDATE_AVATAR,
    payload: avatar,
  };
}

export function updateAvatarSuccess(avatarBase64: string): UserActionTypes {
  return {
    type: UPDATE_AVATAR_SUCCESS,
    payload: avatarBase64,
  };
}

export function resetPasswordSendEmail(email: string): UserActionTypes {
  return {
    type: RESET_PASSWORD_SEND_EMAIL,
    payload: email,
  };
}

export function handleResetPasswordSendEmailSuccess(): UserActionTypes {
  return {
    type: RESET_PASSWORD_SEND_EMAIL_SUCCESS,
  };
}

export function resetPassword(data: ResetPasswordRequest): UserActionTypes {
  return {
    type: RESET_PASSWORD,
    payload: data,
  };
}

export function handleResetPasswordSuccess(): UserActionTypes {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}

export function verifyEmail(token: string): UserActionTypes {
  return {
    type: VERIFY_EMAIL,
    payload: token,
  };
}

export function verifyEmailSuccess(): UserActionTypes {
  return {
    type: VERIFY_EMAIL_SUCCESS,
  };
}


export function getAllUsers(
  query: PageableQuery
): UserActionTypes {
  return {
    type: GET_ALL_USERS,
    payload: query,
  };
}

export function getAllUsersSuccess(
  users: UserType[],
  pages: number
): UserActionTypes {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: {
      users,
      pages,
    },
  };
}

export function toggleUserStatus(userId:string, currentStatus:string): UserActionTypes {
  return {
    type: TOGGLE_USER_STATUS,
    payload: {
      userId,
      currentStatus,
    },
  };
}

export function toggleUserStatusSuccess(user: UserType): UserActionTypes {
  return {
    type: TOGGLE_USER_STATUS_SUCCESS,
    payload: user,
  };
}

export function deleteUser(userId:string): UserActionTypes {
  return {
    type: DELETE_USER,
    payload: userId
  };
}

export function deleteUserSuccess(userId: string): UserActionTypes {
  return {
    type: DELETE_USER_SUCCESS,
    payload: userId
  };
}

