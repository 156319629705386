/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import images from "../../../assets/images/images";
import LocalizationContext from "../../../context/LocalizationContext";
import { TrainingTypeType } from "../../../enums/TrainingTypeType";
import { emailPattern, phonePattern, pricePattern } from "../../../utils/validation.utils";
import { ImagePicker } from "../../components/ImagePicker";
import { StyledModal } from "../../components/StyledModal";
import { StyledTextInput } from "../../components/StyledTextInput";
import { ToggleButtons } from "../../components/ToggleButtons";
import classNames from "classnames";
import { StyledDatePicker } from "../../components/StyledDatePicker";
import { StyledTimePicker } from "../../components/StyledTimePicker";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { addTraining, updateTraining } from "../../../saga/training/actions";
import TrainingType from "../../../interfaces/training/TrainingType";
import { urlToBase64 } from "../../../utils/image.util";
import validator from "validator";
import { onChangePrice } from "../../../utils/priceFormat.utils";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";

type FormValues = {
  title: string;
  description: string;
  link: string;
  type: TrainingTypeType;
  duration: number;
  date: any;
  time: any;
  phone: string;
  email: string;
  price: number;
};

interface Props {
  onCloseButtonClick: () => void;
  training?: TrainingType;
  resetPage?: () => void;
}

export const AddTrainingModal = (props: Props) => {
  const { onCloseButtonClick, training, resetPage } = props;
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const trainingTypeToggleButtons = [
    {
      value: TrainingTypeType.LIVE,
      label: t(`trainingsScreen.${TrainingTypeType.LIVE}`),
    },
    {
      value: TrainingTypeType.PDF,
      label: t(`trainingsScreen.${TrainingTypeType.PDF}`),
    },
    {
      value: TrainingTypeType.POLL,
      label: t(`trainingsScreen.${TrainingTypeType.POLL}`),
    },
  ];

  const [trainingType, setTrainingType] = React.useState<TrainingTypeType>(
    training ? training.type : TrainingTypeType.LIVE
  );
  const [selectedImage, setSelectedImage] = React.useState<string>("");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    //@ts-ignore
    defaultValues: {
      date:
        training && training.startDate
          ? DateTime.fromISO(training.startDate).toString()
          : null,
      time:
        training && training.startDate
          ? DateTime.fromISO(training.startDate).toString()
          : null,
      ...training,
    },
  });

  const getDefaultImages = () => {
    return Object.keys(images.trainingDefaults).map((key: string) => {
      // @ts-ignore
      return images.trainingDefaults[key];
    });
  };

  useEffect(() => {
    if (training) {
      setSelectedImage(training.avatar!);
    } else {
      urlToBase64(getDefaultImages()[0], (base64) => {
        setSelectedImage(base64.split(",")[1]);
      });
    }
  }, [training]);

  const gaEventTracker = useAnalyticsEventTracker('Training');

  const onSubmit = (data: FormValues) => {
    let startDate = undefined;
    if (trainingType === TrainingTypeType.LIVE) {
      startDate = data.date.slice(0, 11) + data.time.slice(11);
      startDate = DateTime.fromISO(startDate).toUTC().toString();
    }
    const { title, description, link, duration, phone, email, price } = data;

    if (resetPage) resetPage();

    if (training) {
      dispatch(
        updateTraining({
          id: training!.id!,
          title,
          description,
          link,
          type: trainingType,
          duration,
          phone,
          email,
          price,
          startDate,
          avatar: selectedImage,
        })
      );
    } else {
      dispatch(
        addTraining({
          title,
          description,
          link,
          type: trainingType,
          duration,
          phone,
          email,
          price,
          startDate,
          avatar: selectedImage,
        })
      );
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => {
      onSubmit(data);
      gaEventTracker(training? "Edit training": "Add training")
    })}>
      <StyledModal
        header={training ? t("trainingsScreen.editTraining") : t("trainingsScreen.addNewTraining")}
        onAcceptButtonClick={() => { }}
        acceptButtonType="submit"
        acceptButtonLabel={training ? t("global.save") : t("global.add")}
        onCloseButtonClick={onCloseButtonClick}
      >
        <div className="addTraining_formWrapper">
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.title && errors.title.message}
                value={!!value ? value.charAt(0).toUpperCase() + value.slice(1) : value}
                onChange={onChange}
                label={t("trainingsScreen.trainingTitle")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 55,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${55} ${t("formValidation.characters")}`,
              },
            }}
          />
          <ToggleButtons
            label={t("trainingsScreen.trainingType")}
            value={trainingType}
            onChange={setTrainingType}
            data={trainingTypeToggleButtons}
          />
          {trainingType !== TrainingTypeType.LIVE && (
            <Controller
              control={control}
              name="link"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledTextInput
                  error={errors.link && errors.link.message}
                  value={value}
                  onChange={onChange}
                  label={t("trainingsScreen.link")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 125,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${125} ${t("formValidation.characters")}`,
                },
                validate: (value) =>
                  !validator.isURL(value) ? t("formValidation.wrongURL") : true,
              }}
            />
          )}
          <ImagePicker
            images={getDefaultImages()}
            style={{
              marginTop: trainingType === TrainingTypeType.LIVE ? 22 : 0,
            }}
            selectedImage={selectedImage}
            onChange={setSelectedImage}
          />
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.description && errors.description.message}
                value={value}
                style={{
                  height: 220,
                  marginBottom: errors.description ? 6 : 26,
                }}
                multiline={true}
                onChange={onChange}
                label={t("trainingsScreen.description")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 2000,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${2000} ${t("formValidation.characters")}`,
              },
            }}
          />
          <div className="inputsRow">
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledTextInput
                  error={errors.email && errors.email.message}
                  value={value}
                  onChange={onChange}
                  label={t("trainingsScreen.mail")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 255,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${255} ${t("formValidation.characters")}`,
                },
                pattern: {
                  value: emailPattern,
                  message: t("formValidation.emailPattern"),
                },
              }}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledTextInput
                  error={errors.phone && errors.phone.message}
                  value={value}
                  onChange={onChange}
                  label={t("trainingsScreen.phone")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 15,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${15} ${t("formValidation.characters")}`,
                },
                pattern: {
                  value: phonePattern,
                  message: t("formValidation.wrongMobilePhone")
                }
              }}
            />
          </div>
          {trainingType === TrainingTypeType.LIVE ? (
            <div className="inputsRow">
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledDatePicker
                    error={errors.date && errors.date.message}
                    value={value}
                    onChange={onChange}
                    label={t("trainingsScreen.date")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: 35,
                  validate: (value) =>
                    value === "Invalid DateTime"
                      ? t("formValidation.wrongDate")
                      : new Date(value) < new Date()
                        ? (new Date(value).toLocaleDateString() === new Date().toLocaleDateString() ? true : t("formValidation.pastDate"))
                        : true,
                }}
              />

              {/* <div className="inputsRow"> */}
              <Controller
                control={control}
                name="time"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTimePicker
                    error={errors.time && errors.time.message}
                    value={value}
                    onChange={onChange}
                    label={t("trainingsScreen.time")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: 35,
                }}
              />
              <Controller
                control={control}
                name="price"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.price && errors.price.message}
                    //@ts-ignore
                    value={value}
                    type="number"
                    onChange={(value) => onChangePrice(onChange, value)}
                    label={t("trainingsScreen.price")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                }}
              />
              <Controller
                control={control}
                name="duration"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.duration && errors.duration.message}
                    //@ts-ignore
                    value={value}
                    type="number"
                    onChange={onChange}
                    label={t("trainingsScreen.duration")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  validate: (value) =>
                    Number(value) < 0 ? t("formValidation.wrongFromat") : true,
                }}
              />
            </div>
          ) : (
            <div className={classNames("inputsRow")}>
              <Controller
                control={control}
                name="price"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.price && errors.price.message}
                    //@ts-ignore
                    value={value}
                    type="number"
                    onChange={onChange}
                    label={t("trainingsScreen.price")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  pattern: {
                    value: pricePattern,
                    message: t("formValidation.pricePattern"),
                  },
                }}
              />
              <Controller
                control={control}
                name="duration"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.duration && errors.duration.message}
                    //@ts-ignore
                    value={value}
                    type="number"
                    onChange={onChange}
                    label={t("trainingsScreen.longDuration")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  validate: (value) =>
                    Number(value) < 0 ? t("formValidation.wrongFromat") : true,
                }}
              />
            </div>
          )}
        </div>
      </StyledModal>
    </form>
  );
};
