/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React, {useEffect, useState} from "react";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DateTime } from "luxon";
import LocalizationContext from "../../context/LocalizationContext";

interface Props {
  error: string | undefined | boolean;
  label: string;
  value: string;
  onChange: (v: string) => void;
  width?: number;
  style?: any;
  inputStyle?: any;
}

export const StyledTimePicker = (props: Props) => {
  const { error, value, onChange, width, style } = props;
  const { t } = React.useContext(LocalizationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState<string>(value);

  const handleChange = (newValue: DateTime | null) => {
    if (newValue) {
      setCurrentDate(newValue.toISO());
    }
  };

  useEffect(() => {
    if(!isOpen && value !== currentDate) {
      setCurrentDate(value);
    }
  }, [isOpen]);

  return (
    <div className="textInput" style={{ width, ...style }}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <MobileTimePicker
          {...props}
          value={currentDate}
          onChange={handleChange}
          ampm={false}
          inputFormat="HH:mm"
          open={isOpen}
          renderInput={(params) => <TextField {...params} InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={isOpen}
                  style={{color: 'rgba(0, 0, 0, 0.54)'}}
                  onClick={() => {
                    if(!isOpen) setIsOpen(true)
                  }}>
                  <AccessTimeIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              paddingRight: 2
            }
          }}/>}
          onClose={() => {
            setIsOpen(false);
          }}
          onAccept={() => {
            onChange(currentDate);
          }}
          cancelText={t('global.cancel')}
          okText={t('global.ok')}
          DialogProps={{
            PaperProps: {
              sx: { '& *:focus': { outline: 'none' }}
            },
          }}

        />
      </LocalizationProvider>
      {error && <p className="errorMessage">{error}</p>}
    </div>
  );
};
