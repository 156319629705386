/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { AxiosResponse } from "axios";
import { PageableQuery } from "../interfaces/pagination/PageableQuery";
import { GetAllUsersRequest } from "../interfaces/user/GetAllUsersRequest";
import UpdateUserRequest from "../interfaces/user/UpdateUserRequest";
import { axiosService } from "./axios.service";
const FormData = require("form-data");

const root: string = "/users";

function updateUser(data: UpdateUserRequest): Promise<AxiosResponse> {
  return axiosService.put(`${root}`, data);
}

function getUser(id: string): Promise<AxiosResponse> {
  return axiosService.get(`${root}/${id}`);
}

function getAvatar(userId: string): Promise<AxiosResponse> {
  return axiosService.get(`${root}/avatar/${userId}`);
}

function updateAvatar(avatar: string): Promise<AxiosResponse> {
  return axiosService.put(`${root}/avatar`, { avatar });
}

function getAllUsers(query: PageableQuery): Promise<AxiosResponse> {
  return axiosService.get(`${root}/all`, {
    params: query,
  });
}

function activateUser(userId: string): Promise<AxiosResponse>{
  return axiosService.put(`${root}/activate/${userId}`, {});
}

function deactivateUser(userId: string): Promise<AxiosResponse>{
  return axiosService.put(`${root}/deactivate/${userId}`, {});
}

function deleteUser(userId: string): Promise<AxiosResponse>{
  return axiosService.delete(`${root}/${userId}`);
}

const userService = {
  updateUser,
  getUser,
  getAvatar,
  updateAvatar,
  getAllUsers,
  activateUser,
  deactivateUser,
  deleteUser
};

export default userService;
