/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { put, takeLatest } from "redux-saga/effects";
import paymentService from "../../services/payment.service";
import * as actions from "./actions";
import * as networkActions from "../network/actions";
import { ExceptionUtil } from "../../utils/exception.util";
import {
  AddProduct,
  DeleteProduct,
  GetActiveSubscription,
  GetPaymentsByUserId,
  UpdateProduct,
} from "../../interfaces/payment/PaymentActionTypes";
import SubscriptionType from "../../interfaces/payment/SubscriptionType";
import ProductType from "../../interfaces/payment/ProductType";
import { PageableQuery } from "../../interfaces/pagination/PageableQuery";
import PaymentType from "../../interfaces/payment/PaymentType";

function* getActiveSubscription(action: GetActiveSubscription) {
  try {
    const subscriptionResponse: any =
      //@ts-ignore
      yield paymentService.getActiveSubscription(action.payload);

    yield handleGetActiveSubscriptionSuccess(
      subscriptionResponse.data.subscription
    );
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.paymentActionError(e));
  }
}

function* handleGetActiveSubscriptionSuccess(data: SubscriptionType) {
  yield put(actions.getActiveSubscriptionSuccess(data));
}

export function* watchGetActiveSubscriptionSaga() {
  yield takeLatest(actions.GET_ACTIVE_SUBSCRIPTION, getActiveSubscription);
}

function* getAllProducts(action: GetActiveSubscription) {
  try {
    const productsResponse: any =
      //@ts-ignore
      yield paymentService.getAllProducts(action.payload);

    if (productsResponse.data.meta.totalItems === 0) {
      yield handleGetAllProductsSuccess(
        [],
        productsResponse.data.meta.totalPages
      );
      return;
    }

    yield handleGetAllProductsSuccess(
      productsResponse.data.data,
      productsResponse.data.meta.totalPages
    );
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.paymentActionError(e));
  }
}

function* handleGetAllProductsSuccess(data: ProductType[], pages: number) {
  yield put(actions.getAllProductsSuccess(data, pages));
}

export function* watchGetAllProductsSaga() {
  yield takeLatest(actions.GET_ALL_PRODUCTS, getAllProducts);
}

function* addProduct(action: AddProduct) {
  try {
    yield paymentService.addProduct(action.payload);

    yield handleAddProductSuccess();
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.paymentActionError(e));
  }
}

function* handleAddProductSuccess() {
  let query: PageableQuery = {
    page: 1,
    limit: 10,
  };
  yield put(actions.addProductSuccess());
  yield put(actions.getAllProducts(query));
}

export function* watchAddProductSaga() {
  yield takeLatest(actions.ADD_PRODUCT, addProduct);
}

function* deleteProduct(action: DeleteProduct) {
  try {
    yield paymentService.deleteProduct(action.payload);

    yield handleDeleteProductSuccess();
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.paymentActionError(e));
  }
}

function* handleDeleteProductSuccess() {
  let query: PageableQuery = {
    page: 1,
    limit: 10,
  };
  yield put(actions.deleteProductSuccess());
  yield put(actions.getAllProducts(query));
}

export function* watchDeleteProductSaga() {
  yield takeLatest(actions.DELETE_PRODUCT, deleteProduct);
}

function* updateProduct(action: UpdateProduct) {
  try {
    yield paymentService.updateProduct(action.payload);

    yield handleUpdateProductSuccess();
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.paymentActionError(e));
  }
}

function* handleUpdateProductSuccess() {
  let query: PageableQuery = {
    page: 1,
    limit: 10,
  };
  yield put(actions.updateProductSuccess());
  yield put(actions.getAllProducts(query));
}

export function* watchUpdateProductSaga() {
  yield takeLatest(actions.UPDATE_PRODUCT, updateProduct);
}

function* getPaymentsByUserId(action: GetPaymentsByUserId) {
  try {
    const paymentsResponse: any =
      //@ts-ignore
      yield paymentService.getPaymentsByUserId(action.payload.userId, action.payload.query);

    if (paymentsResponse.data.meta.totalItems === 0) {
      yield handleGetPaymentsByUserIdSuccess(
        [],
        paymentsResponse.data.meta.totalPages
      );
      return;
    }

    yield handleGetPaymentsByUserIdSuccess(
      paymentsResponse.data.data,
      paymentsResponse.data.meta.totalPages
    );
  } catch (e) {
    yield put(
      networkActions.networkActionError(ExceptionUtil.getErrorResponse(e))
    );
    // @ts-ignore
    yield put(actions.paymentActionError(e));
  }
}

function* handleGetPaymentsByUserIdSuccess(data: PaymentType[], pages: number) {
  yield put(actions.getPaymentsByUserIdSuccess(data, pages));
}

export function* watchGetPaymentsByUserId() {
  yield takeLatest(actions.GET_PAYMENTS_BY_USER_ID, getPaymentsByUserId);
}
