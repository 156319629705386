/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { TextField } from "@mui/material";
import DateAdapter from "@mui/lab/AdapterLuxon";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LuxonUtils from "@mui/lab/AdapterLuxon";

interface Props {
  error: string | undefined | boolean;
  label: string;
  value: string;
  onChange: (v: string) => void;
  width?: number;
  style?: any;
  inputStyle?: any;
}

export const StyledDatePicker = (props: Props) => {
  const { error, value, onChange, width, style } = props;

  const handleChange = (newValue: Date | null) => {
    if (newValue) {
      onChange(newValue.toString());
    }
  };

  return (
    <div className="textInput" style={{ width, ...style }}>
      <LocalizationProvider dateAdapter={DateAdapter} utils={LuxonUtils}>
        <DesktopDatePicker
          {...props}
          inputFormat="dd/MM/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      {error && <p className="errorMessage">{error}</p>}
    </div>
  );
};
