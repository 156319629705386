/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { NetworkActionTypes } from "../../interfaces/network/NetworkActionTypes";
import { AxiosError } from "axios";
export const NETWORK_ACTION_ERROR = "NETWORK_ACTION_ERROR";
export const CLEAR_NETWORK_ERROR = "CLEAR_NETWORK_ERROR";
export const CLEAR_NETWORK_MESSAGE = "CLEAR_NETWORK_MESSAGE";
export const SHOW_NETWORK_MESSAGE = "SHOW_NETWORK_MESSAGE";

export function networkActionError(
  error: string | AxiosError | { message: string } | null
): NetworkActionTypes {
  return {
    type: NETWORK_ACTION_ERROR,
    payload: error,
  };
}

export function clearNetworkError(): NetworkActionTypes {
  return {
    type: CLEAR_NETWORK_ERROR,
  };
}

export function clearNetworkMessage(): NetworkActionTypes {
  return {
    type: CLEAR_NETWORK_MESSAGE,
  };
}

export function showMessage(message: string | null): NetworkActionTypes {
  return {
    type: SHOW_NETWORK_MESSAGE,
    payload: message,
  };
}
