/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import {
  userReducer,
  initialState as userInitialState,
} from "../reducers/user.reducer";
import { networkReducer } from "../reducers/network.reducer";
import { trainingReducer } from "../reducers/training.reducer";
import { surveyReducer } from "../reducers/survey.reducer";
import { paymentReducer } from "../reducers/payment.reducer";
import { rootSaga } from "../saga/root.saga";
import loggerMiddleware from "../middleware/logger";
import storage from "redux-persist/lib/storage";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["userReducer"],
};

export const rootReducer = combineReducers({
  userReducer,
  networkReducer,
  trainingReducer,
  surveyReducer,
  paymentReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [loggerMiddleware, sagaMiddleware];

const store = createStore(persistedReducer, applyMiddleware(...middlewares));
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export { store, persistor };

export const initialStore = {
  userReducer: userInitialState,
};

export type AppState = ReturnType<typeof rootReducer>;
