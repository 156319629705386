/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import LoginRequest from "../interfaces/auth/LoginRequest";
import axios, { AxiosResponse } from "axios";
import { axiosServiceWithoutToken, requiredHeaders } from "./axios.service";
import RegistrationRequest from "../interfaces/auth/RegistrationRequest";
import LoginResponse from "../interfaces/auth/LoginResponse";
import { Cookies as ReactCookies } from "react-cookie";
import { appConstants } from "../constants/app.constants";
import { store } from "../store/store";
import { setToken } from "../saga/user/actions";
import { ResetPasswordRequest } from "../interfaces/reset-password/ResetPasswordRequest";

const root: string = "/auth";

function userLogin(data: LoginRequest) {
  return axiosServiceWithoutToken.post(`${root}/login`, data);
}

function userRegistration(data: RegistrationRequest): Promise<AxiosResponse> {
  return axiosServiceWithoutToken.post(`${root}/register`, data);
}

function refreshTokenRequest(refreshToken: string) {
  const loginUrl = `${process.env.REACT_APP_API_URL}${root}/refresh`;
  const config = {
    headers: requiredHeaders,
    timeout: 15000,
  };

  return axios
    .post(
      loginUrl,
      {
        refreshToken: refreshToken,
      },
      config
    )
    .then((response) => {
      let loginResponse: LoginResponse = response.data;
      store.dispatch(setToken(loginResponse.accessToken));

      const cookies = new ReactCookies();
      cookies.set(appConstants.REFRESH_TOKEN_KEY, loginResponse.refreshToken, {
        path: "/",
        httpOnly: true,
        secure: true,
      });
      return loginResponse.accessToken;
    });
}

function resetPasswordSendEmail(email: string): Promise<AxiosResponse> {
  return axiosServiceWithoutToken.post(`${root}/resetPassword`, { email });
}

function resetPassword(data: ResetPasswordRequest): Promise<AxiosResponse> {
  return axiosServiceWithoutToken.put(`${root}/resetPassword`, data);
}

function verifyQR(token: string): Promise<AxiosResponse> {
  return axiosServiceWithoutToken.post(`${root}/verify-qr`, { token });
}

function verifyEmail(token: string): Promise<AxiosResponse> {
  return axiosServiceWithoutToken.get(`/verify-email?token=${token}`);
}

const authService = {
  userLogin,
  userRegistration,
  refreshTokenRequest,
  resetPasswordSendEmail,
  resetPassword,
  verifyQR,
  verifyEmail
};

export default authService;
