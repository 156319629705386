/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import classNames from "classnames";
import { CertificatePromo } from "./CertificatePromo";
import { CertificatesInputForm } from "./CertificateInputForm";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store/store";
import colors from "../../../assets/styles/_colors.module.scss";
import { GetManyCertificatesModal } from "./GetManyCertificatesModal";
import { getUser } from "../../../saga/user/actions";
import UserType from "../../../interfaces/user/UserType";

export const CertificatesScreen = () => {
  const [formState, setFormState] = useState({ stylistName: null, traineeName: null, date: null, title: null, avatar: null })
  const isFetching: boolean = useSelector(
    (state: AppState) => state.trainingReducer.isFetching
  );

  const isUserFetching: boolean = useSelector(
    (state: AppState) => state.userReducer.isFetching
  );

  const dispatch = useDispatch();
  const [getManyCertificatesModalOpen, seGetManyCertificatesModalOpen] = useState<boolean>(false);

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  useEffect(() => {
    dispatch(getUser(user!.id));
  }, [dispatch]);

  return (
    <div id="certificatesScreen" >
      {isFetching || isUserFetching ? (
        <div className="progressWrapper">
          <CircularProgress style={{ color: colors.primary }} />
        </div>
      ) : (<>
        <CertificatePromo formState={formState} />
        <CertificatesInputForm setFormState={setFormState} seGetManyCertificatesModalOpen={seGetManyCertificatesModalOpen} />
      </>
      )}
      {getManyCertificatesModalOpen && <GetManyCertificatesModal seGetManyCertificatesModalOpen={seGetManyCertificatesModalOpen} />}
    </div>
  );
}
