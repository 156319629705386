/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React, { useEffect } from "react";
import LocalizationContext from "../../context/LocalizationContext";
import { useForm, Controller } from "react-hook-form";
import { StyledTextInput } from "../components/StyledTextInput";
import { emailPattern, passwordPattern } from "../../utils/validation.utils";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import images from "../../assets/images/images";
import { PreAuthFormWrapper } from "./PreAuthFormWrapper";
import { register } from "../../saga/user/actions";
import { AppState } from "../../store/store";
import { useNavigate } from "react-router";
import { showMessage } from "../../saga/network/actions";
import { StyledCheckBox } from "../components/StyledCheckBox";
import colors from "../../assets/styles/_colors.module.scss";
import { useState } from 'react'
import { StyledModal } from '../components/StyledModal';
//@ts-ignore
import { Document, Page, pdfjs } from 'react-pdf';
//@ts-ignore
import pdfTerms from "../../assets/pdf-terms/AcceptTerms.pdf";
import { CircularProgress } from "@mui/material";
import useAnalyticsEventTracker from "../../hooks/useAnaliticsTracker";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type FormValues = {
  firstName: string;
  lastName: string;
  firmName: string;
  address: string;
  zip: string;
  city: string;
  email: string;
  password: string;
  repeatPassword: string;
  acceptTerms: boolean;
};

export const RegistrationScreen = () => {
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    watch,
    setError,
    setValue,
  } = useForm<FormValues>();

  const [numPages, setNumPages] = useState<number | null>(null);
  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);

  const networkMessage: string = useSelector(
    (state: AppState) => state.networkReducer.message
  );

  const isFetching: boolean = useSelector(
    (state: AppState) => state.userReducer.isFetching
  );

  const gaEventTracker = useAnalyticsEventTracker('Register');
  
  useEffect(() => {
    dispatch(showMessage(null));
  }, [dispatch]);

  useEffect(() => {
    if (networkMessage && !isFetching && isSubmitted) {
      navigate("/login", { replace: true });
    }
  }, [navigate, isFetching, networkMessage]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  const onSubmit = (data: FormValues) => {
    dispatch(
      register({
        email: data.email,
        password: data.password,
        repeatPassword: data.repeatPassword,
        firstName: data.firstName,
        lastName: data.lastName,
        firmName: data.firmName,
        address: {
          line1: data.address,
          city: data.city,
          zip: data.zip,
        },
      })
    );
  };

  return (
    <div
      id="screenContainer"
      style={{
        backgroundImage: `url(${images.authBackground})`,
      }}
    >
      <form
        className={"registrationForm"}
        onSubmit={handleSubmit((data) => {
            onSubmit(data); 
            gaEventTracker('Register')
        })}
      >
        <PreAuthFormWrapper
          header={t("registrationScreen.title")}
          buttonText={t("loginScreen.register")}
          type="register"
        >
          <div className="formWrapper">
            <div className="userData">
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.firstName && errors.firstName.message}
                    value={value}
                    width={420}
                    onChange={onChange}
                    label={t("registrationScreen.firstName")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: {
                    value: 35,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${35} ${t("formValidation.characters")}`,
                  },
                }}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <StyledTextInput
                    error={errors.lastName && errors.lastName.message}
                    value={value}
                    width={420}
                    onChange={onChange}
                    label={t("registrationScreen.lastName")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: {
                    value: 35,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${35} ${t("formValidation.characters")}`,
                  },
                }}
              />
              <Controller
                control={control}
                name="firmName"
                render={({ field: { onChange, value } }) => (
                  <StyledTextInput
                    error={errors.firmName && errors.firmName.message}
                    value={value}
                    width={420}
                    onChange={onChange}
                    label={t("registrationScreen.firmName")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: {
                    value: 35,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${35} ${t("formValidation.characters")}`,
                  },
                }}
              />
              <Controller
                control={control}
                name="address"
                render={({ field: { onChange, value } }) => (
                  <StyledTextInput
                    error={errors.address && errors.address.message}
                    value={value}
                    width={420}
                    onChange={onChange}
                    label={t("registrationScreen.address")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: {
                    value: 124,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${124} ${t("formValidation.characters")}`,
                  },
                }}
              />
              <div className="inputsRow">
                <Controller
                  control={control}
                  name="zip"
                  render={({ field: { onChange, value } }) => (
                    <StyledTextInput
                      error={errors.zip && errors.zip.message}
                      value={value}
                      width={150}
                      mask="99-999"
                      onChange={onChange}
                      label={t("registrationScreen.zip")}
                    />
                  )}
                  rules={{
                    required: t("formValidation.required"),
                    validate: (value) =>
                      value.replace("_", "").length < 6
                        ? t("formValidation.wrongZip")
                        : true,
                  }}
                />
                <Controller
                  control={control}
                  name="city"
                  render={({ field: { onChange, value } }) => (
                    <StyledTextInput
                      error={errors.city && errors.city.message}
                      value={value}
                      onChange={onChange}
                      label={t("registrationScreen.city")}
                    />
                  )}
                  rules={{
                    required: t("formValidation.required"),
                    maxLength: {
                      value: 45,
                      message: `${t(
                        "formValidation.textCannotBeLongerThan"
                      )} ${45} ${t("formValidation.characters")}`,
                    },
                  }}
                />
              </div>
            </div>
            <div className="loginData" style={{ position: "relative" }}>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <StyledTextInput
                    error={errors.email && errors.email.message}
                    value={value}
                    width={420}
                    onChange={onChange}
                    label={t("loginScreen.email")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  pattern: {
                    value: emailPattern,
                    message: t("formValidation.emailPattern"),
                  },
                  maxLength: {
                    value: 255,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${255} ${t("formValidation.characters")}`,
                  },
                }}
              />
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, value } }) => (
                  <StyledTextInput
                    error={errors.password && errors.password.message}
                    value={value}
                    width={420}
                    type="password"
                    style={{
                      marginBottom:
                        errors.password && errors.password.message!.length > 20
                          ? 43
                          : 26,
                    }}
                    onChange={onChange}
                    label={t("loginScreen.password")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  pattern: {
                    value: passwordPattern,
                    message: t("formValidation.passwordPattern"),
                  },
                  maxLength: {
                    value: 255,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${255} ${t("formValidation.characters")}`,
                  },
                  validate: {
                    matchPassword: (value) => {
                      if (
                        value === watch("repeatPassword", "") &&
                        errors.repeatPassword &&
                        errors.repeatPassword.message ===
                        t("formValidation.passwordNotMatch")
                      ) {
                        setError("repeatPassword", {
                          message: "",
                        });
                      } else if (
                        !!watch("repeatPassword", "") &&
                        value !== watch("repeatPassword", "")
                      ) {
                        setError("repeatPassword", {
                          message: t("formValidation.passwordNotMatch"),
                        });
                      }
                      return true;
                    },
                  },
                }}
              />
              <Controller
                control={control}
                name="repeatPassword"
                render={({ field: { onChange, value } }) => (
                  <StyledTextInput
                    error={
                      errors.repeatPassword && errors.repeatPassword.message
                    }
                    value={value}
                    width={420}
                    type="password"
                    onChange={onChange}
                    label={t("registrationScreen.repeatPassword")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  pattern: {
                    value: passwordPattern,
                    message: t("formValidation.passwordPattern"),
                  },
                  maxLength: {
                    value: 255,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${255} ${t("formValidation.characters")}`,
                  },
                  validate: {
                    matchPassword: (value) =>
                      value === watch("password", "") ||
                      t("formValidation.passwordNotMatch"),
                  },
                }}
              />
              <Controller
                control={control}
                name="acceptTerms"
                render={({ field: { onChange, value } }) => (
                  <>
                    <div style={{ position: "absolute", bottom: 11 }}>
                      <StyledCheckBox
                        onChange={onChange}
                        value={value}
                        label={
                          <>
                            <span>{t("registrationScreen.accept") + ' '}</span>
                            <span style={{ color: colors.primary, cursor: 'pointer' }} onClick={() => setTermsModalOpen(true)}>
                              {t("registrationScreen.terms").toLowerCase()}
                            </span>
                            <span>{' ' + t("registrationScreen.system")}</span>
                          </>
                        }
                      />
                      <p className='errorMessage' style={{ height: 12 }}>{errors.acceptTerms && t("formValidation.required")}</p>
                    </div>
                  </>
                )}
                defaultValue={false}
                rules={{
                  validate: (value) => value
                }}
              />
            </div>
          </div>
        </PreAuthFormWrapper>
      </form>
      {termsModalOpen &&
        <StyledModal
          header={t("registrationScreen.terms")}
          onAcceptButtonClick={() => {
            setValue('acceptTerms', true);
            setTermsModalOpen(false);
          }}
          acceptButtonLabel={t("registrationScreen.accept")}
          acceptButtonType="button"
          onCloseButtonClick={() => setTermsModalOpen(false)}
        >
          <div className="termsModalBody">
            <Document file={pdfTerms}
              onLoadSuccess={onDocumentLoadSuccess}
              renderMode="svg"
              loading={
                <div className="progressWrapper">
                  <CircularProgress style={{ color: colors.primary }} />
                </div>
              }>
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map(page => <div style={{ margin: 10 }}><Page pageNumber={page} /></div>)}
            </Document>
          </div>
        </StyledModal>
      }
    </div>
  );
};
