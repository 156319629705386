/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import LocalizationContext from "../../../context/LocalizationContext";
import SurveyType from "../../../interfaces/survey/SurveyType";
import { addSurvey, updateSurvey } from "../../../saga/survey/actions";
import { StyledModal } from "../../components/StyledModal";
import { StyledTextInput } from "../../components/StyledTextInput";
import validator from "validator";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";

type FormValues = {
  title: string;
  description: string;
  link: string;
};

interface Props {
  onCloseButtonClick: () => void;
  survey?: SurveyType | null;
  resetPage?: () => void;
  error: string | null;
}

export const SurveyModal = (props: Props) => {
  const { onCloseButtonClick, survey, resetPage } = props;
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    //@ts-ignore
    defaultValues: {
      ...survey,
    },
  });
  const gaEventTracker = useAnalyticsEventTracker('Survey');

  const onSubmit = (data: FormValues) => {
    if (resetPage) resetPage();

    if (survey) {
      dispatch(
        updateSurvey({
          ...data,
          id: survey.id,
        })
      );
      gaEventTracker("Update Survey");
    } else {
      dispatch(addSurvey(data));
      gaEventTracker("Add Survey");
    }
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <StyledModal
        header={!!survey ? t("surveyScreen.editSurvey") : t("surveyScreen.addNewSurvey")}
        onAcceptButtonClick={() => {}}
        acceptButtonType="submit"
        acceptButtonLabel={!!survey ? t("global.save") : t("global.add")}
        onCloseButtonClick={onCloseButtonClick}
      >
        <div className="formWrapper">
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.title && errors.title.message}
                value={!!value ? value.charAt(0).toUpperCase() + value.slice(1) : value}
                width={511}
                onChange={onChange}
                label={t("surveyScreen.title")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 55,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${55} ${t("formValidation.characters")}`,
              },
            }}
          />
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.description && errors.description.message}
                value={value}
                width={511}
                multiline={true}
                style={{
                  height: 220,
                  marginBottom: errors.description ? 6 : 26,
                }}
                onChange={onChange}
                label={t("trainingsScreen.description")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 2000,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${2000} ${t("formValidation.characters")}`,
              },
            }}
          />
          <Controller
            control={control}
            name="link"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.link && errors.link.message}
                value={value}
                width={511}
                onChange={onChange}
                label={t("trainingsScreen.link")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 125,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${125} ${t("formValidation.characters")}`,
              },
              validate: (value) =>
                !validator.isURL(value) ? t("formValidation.wrongURL") : true,
            }}
          />
        </div>
      </StyledModal>
    </form>
  );
};
