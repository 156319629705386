/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import TrainingType from "../../interfaces/training/TrainingType";
import classNames from "classnames";
import images from "../../assets/images/images";
import LocalizationContext from "../../context/LocalizationContext";

interface Props {
  training: TrainingType;
  selectedTraining: TrainingType | null;
  onClick: (training: TrainingType) => void;
}

export const TrainingItemRow = (props: Props) => {
  const { training, onClick, selectedTraining } = props;
  const { t } = React.useContext(LocalizationContext);

  return (
    <div
      className={classNames("trainingItemRow", {
        selected: selectedTraining && selectedTraining.id === training.id,
      })}
      onClick={() => onClick(training)}
    >
      {training.avatar ? (
        <img
          src={`data:image/png;base64,${training.avatar}`}
          className="trainingAvatar"
          alt=""
        />
      ) : (
        <div className="trainingAvatarContainer" />
      )}

      <span>{training.title}</span>
      <div className="detailsContainer">
        <div className="priceContainer">
          <img className="priceTag" src={images.priceTagIcon} alt="priceTag" />
          <span className="price">{`${Number(training.price).toFixed(2)} ${t(
            "subscriptionScreen.currency"
          )}`}</span>
        </div>
        <span className="type">{t(`trainingsScreen.${training.type}`)}</span>
      </div>
    </div>
  );
};
