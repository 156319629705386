/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import Dropzone from "react-dropzone";
import images from "../../assets/images/images";
import LocalizationContext from "../../context/LocalizationContext";
import { resizeFile } from "../../utils/image.util";
import { StyledButton } from "./StyledButton";

interface Props {
  onChange: (image: any) => void;
}

export const ImageUploadComponent = (props: Props) => {
  const { onChange } = props;
  const { t } = React.useContext(LocalizationContext);

  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        const base64 = resizeFile(acceptedFiles[0]);
        base64.then((result) => {
          onChange(String(result).split(",")[1]);
        });
      }}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className="uploadComponent">
          <img src={images.uploadIcon} alt="" />
          <p>{t("trainingsScreen.addPhoto")}</p>

          <input {...getInputProps()} />
          <StyledButton
            buttonText={t("trainingsScreen.selectFile")}
            type="button"
          />
        </div>
      )}
    </Dropzone>
  );
};
