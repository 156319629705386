/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import i18next from "i18next";
import { ExceptionType } from "../enums/ExceptionType";
import { ErrorCodesType } from "../enums/ErrorCodesType";

interface ErrorResponse {
  status: number;
  message: string;
  code: string;
}

const checkIfGlobalException = (error: { message: string }) => {
  const message = error.message.toLowerCase();
  return (
    message.includes("timeout") ||
    message.includes("network") ||
    message.includes("bad_request") ||
    message.includes("expired")
  );
};

const checkIfBadRequestException = (error: { message: string }) => {
  return error.message.toLowerCase().includes("400");
};

const getErrorMessage = (error: ExceptionType | ErrorCodesType | string) => {
  let message = "";

  if (error && error !== "") {
    switch (error) {
      case ExceptionType.RequestTimeoutException:
        message = i18next.t("global.error.timeout");
        break;
      case ExceptionType.NetworkException:
        message = i18next.t("global.error.network");
        break;
      case ExceptionType.BadRequestException:
        message = i18next.t("global.error.badRequest");
        break;
      case ExceptionType.TokenExpiredException:
        message = i18next.t("global.sessionExpired");
        break;
      default:
        if (i18next.exists(`error.${error}`)) {
          message = i18next.t(`error.${error}`);
        } else {
          message = i18next.t("global.error.unexpected");
        }
        break;
    }
  }
  return message;
};

const setErrorType = (payload: any) => {
  let error = "";

  if (payload.includes("timeout")) {
    return ExceptionType.RequestTimeoutException;
  } else if (payload.includes("network")) {
    return ExceptionType.NetworkException;
  } else if (payload.includes("expired")) {
    return ExceptionType.TokenExpiredException;
  }

  let parsedPayload: ErrorResponse | null = null;
  try {
    parsedPayload = JSON.parse(payload);
  } catch (e) {
    return ExceptionType.UnexpectedApiException;
  }

  if (parsedPayload && parsedPayload.code && parsedPayload.code !== "") {
    return parsedPayload.code;
  }

  if (
    parsedPayload &&
    !parsedPayload.code &&
    parsedPayload.status &&
    parsedPayload?.status === 400
  ) {
    return ExceptionType.BadRequestException;
  }

  if (payload !== "") {
    return payload;
  }

  return error;
};

const getErrorResponse = (e: any) => {
  if (e && e.response && e.response.data) {
    return JSON.stringify(e.response.data);
  }

  return e;
};

export const ExceptionUtil = {
  checkIfGlobalException,
  getErrorMessage,
  setErrorType,
  checkIfBadRequestException,
  getErrorResponse,
};
