/* eslint-disable import/no-anonymous-default-export */
/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { Checkbox } from '@mui/material'
import colors from "../../assets/styles/_colors.module.scss";

interface Props {
    label: any;
    value: boolean;
    onChange: (v: any) => void;
}
export const StyledCheckBox = (props: Props) => {
    const { label, value, onChange } = props
 console.log(value)
    return (
        <>
            <Checkbox
                onChange={onChange}
                value={value}
                checked={value}
                sx={{
                    color: colors.primary,
                    '&.Mui-checked': {
                        color: colors.primary,
                    },
                }}
            />
            <span style={{ color: colors.textGray }}>{label}</span>
        </>
    )
}
