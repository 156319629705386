/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyleft 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LocalizationContext from "../../../context/LocalizationContext";
import TableOptionsType from "../../../interfaces/TableOptionsType";
import { StyledButton } from "../../components/StyledButton";
import { StyledStatusIcon } from "../../components/StyledStatusIcon";
import i18next from "i18next";
import { SubscriptionStatusType } from "../../../enums/SubscriptionStatusType";
import { UserRoleType } from "../../../enums/UserRoleType";

interface Props {
  tableOptions: TableOptionsType;
  data: any[];
  onDelete: (item: any) => void;
  emptyInfo: string;
  onToggle: (item: any) => void;
  redirectTo: (item: any) => void;
}

export const UserTable = (props: Props) => {
  const {
    tableOptions,
    data,
    onDelete,
    emptyInfo,
    onToggle,
    redirectTo,
  } = props;
  const { t } = React.useContext(LocalizationContext);

  const subscriptionStatusToCell = (subscriptionStatus: string) => {
    switch (true) {
      case subscriptionStatus === SubscriptionStatusType.ACTIVE:
        return (
          <>
            <span className="greenDot"></span>
            <span>{i18next.t("usersScreen.active")}</span>
          </>
        );
      case subscriptionStatus === SubscriptionStatusType.INACTIVE:
        return (
          <>
            <span className="redDot"></span>
            <span>{i18next.t("usersScreen.inactive")}</span>
          </>
        );
    }
  };

  return (
    <>
      {data && data.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{}} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableOptions.headers.map((item, idx) => (
                  <TableCell key={`tableHead${idx}`} align="left">
                    {item}
                  </TableCell>
                ))}
                <TableCell className="buttonsHeader" />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, idx) => (
                <TableRow
                  key={`tableRow-${idx}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {tableOptions.keys.map((key) => (
                    <TableCell
                      key={`tableCell-${idx}-${key}`}
                      component="th"
                      scope="row"
                    >
                      {key === "subscriptionStatus"
                        ? subscriptionStatusToCell(row[key])
                        : row[key]}
                    </TableCell>
                  ))}
                  <TableCell
                    component="th"
                    scope="row"
                    className={"buttonsCell"}
                  >
                    <StyledButton
                      buttonText={`${t("usersScreen.payments").toUpperCase()}`}
                      type="button"
                      disabled={row.role === UserRoleType.ADMIN}
                      onClick={() =>
                        row.role !== UserRoleType.ADMIN ? redirectTo(row) : {}
                      }
                    />
                    <StyledStatusIcon
                      isActive={row.status === "ACTIVE"}
                      disabled={row.role === UserRoleType.ADMIN}
                      onClick={() =>
                        row.role !== UserRoleType.ADMIN ? onToggle(row) : {}
                      }
                    />
                    <StyledButton
                      buttonText={t("global.delete")}
                      disabled={row.role === UserRoleType.ADMIN}
                      type="button"
                      onClick={() =>
                        row.role !== UserRoleType.ADMIN ? onDelete(row) : {}
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="emptyTable">{emptyInfo}</div>
      )}
    </>
  );
};
