/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

interface Option {
  value: string;
  label: string;
}

interface Props {
  options: (string | Option)[];
  value: any;
  onChange: (value: string) => void;
}

export const StyledDropdown = (props: Props) => {
  const { options, value, onChange } = props;

  const handleOnChange = (option: any) => {
    if (onChange) {
      onChange(option.value);
    }
  };

  return (
    <div>
      <Dropdown options={options} onChange={handleOnChange} value={value} />
    </div>
  );
};
