/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { TextField } from "@mui/material";
import InputMask from "react-input-mask";
import classNames from "classnames";

interface Props {
  error: string | undefined | boolean;
  label?: string;
  type?: "password" | "tel" | "email" | "number";
  value: string;
  onChange: (v: any) => void;
  width?: number | string;
  multiline?: boolean;
  style?: any;
  inputStyle?: any;
  mask?: string | (string | RegExp)[];
}

export const StyledTextInput = (props: Props) => {
  const { error, value, onChange, width, style, inputStyle, mask, multiline } =
    props;

  return (
    <div
      className={classNames("textInput", {
        multiline: multiline && "multiline",
      })}
      style={{ width, maxWidth: width, ...style }}
    >
      {mask ? (
        <InputMask
          mask={mask}
          value={value}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        >
          {(inputProps: any) => {
            return (
              <TextField
                {...inputProps}
                style={inputStyle}
                label={props.label}
                variant="outlined"
                className="loginInput"
                error={!!error}
              />
            );
          }}
        </InputMask>
      ) : (
        <TextField
          {...props}
          value={value}
          style={inputStyle}
          inputProps={{
            autoComplete: "off",
            form: {
              autoComplete: "off",
            },
          }}
          label={props.label}
          onChange={(event) => onChange(event.target.value)}
          variant="outlined"
          className="loginInput"
          error={!!error}
        />
      )}
      {error && <p className="errorMessage">{error}</p>}
    </div>
  );
};
