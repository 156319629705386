/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React, {ReactNode} from 'react';
import {withTranslation} from 'react-i18next';
import '../i18n/i18n';
import LocalizationContext, {
  LocalizationContextType,
} from './LocalizationContext';

interface LocalLizationProviderType extends LocalizationContextType {
  children: ReactNode;
}

const LocalizationProvider = (props: LocalLizationProviderType) => {
  const {t, i18n, children} = props;

  return (
    <LocalizationContext.Provider value={{t, i18n}}>
      {children}
    </LocalizationContext.Provider>
  );
};

export default withTranslation()(LocalizationProvider);
