import { useNavigate, useParams } from "react-router-dom";
import UserType from "../../../interfaces/user/UserType";
//@ts-ignore
import { Jutsu } from "react-jutsu";
import { CircularProgress } from "@mui/material";
import colors from "../../../assets/styles/_colors.module.scss";
import { PageNotFoundScreen } from "../../../screens/PageNotFoundScreen";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store/store";
import { useEffect } from "react";
import { doesLinkExist } from "../../../saga/training/actions";
import images from "../../../assets/images/images";

export const MeetScreen = () => {
  const authenticatedUser: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const doesExist: boolean = useSelector(
    (state: AppState) => state.trainingReducer.doesLinkExist
  );
  const isFetching: boolean = useSelector(
    (state: AppState) => state.trainingReducer.isFetching
  );

  useEffect(() => {
    console.log(window.location);
    dispatch(doesLinkExist(window.location.href));
  }, []);

  const renderMeetWindow = () => {
    return (
      <Jutsu
        containerStyles={{
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
        }}
        configOverwrite={{
          recordingService: {
            enabled: true,
            sharingEnabled: true,
            hideStorageWarning: true,
          },
        }}
        roomName={params.id}
        displayName={
          authenticatedUser
            ? `${authenticatedUser.firstName} ${authenticatedUser.lastName}`
            : ""
        }
        onMeetingEnd={() => (authenticatedUser ? navigate("/trainings") : null)}
        loadingComponent={
          <div className="progressWrapper">
            <CircularProgress style={{ color: colors.primary }} />
          </div>
        }
        errorComponent={
          <div
            id="pageTemplate"
            style={{
              backgroundImage: `url(${images.backgroundGradient})`,
            }}
          >
            <PageNotFoundScreen trainingMeetWasNotFound />
          </div>
        }
      />
    );
  };

  return isFetching ? (
    <div className="progressWrapper">
      <CircularProgress style={{ color: colors.primary }} />
    </div>
  ) : doesExist ? (
    renderMeetWindow()
  ) : (
    <div
      id="pageTemplate"
      style={{
        backgroundImage: `url(${images.backgroundGradient})`,
      }}
    >
      <PageNotFoundScreen trainingMeetWasNotFound />
    </div>
  );
};
