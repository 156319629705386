/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import LocalizationContext from "../../context/LocalizationContext";
import { AppState } from "../../store/store";
import { StyledTextInput } from "../components/StyledTextInput";
import validator from "validator";
import { StyledButton } from "../components/StyledButton";
import UserType from "../../interfaces/user/UserType";
import { getUser, updateAvatar, updateUser } from "../../saga/user/actions";
import Dropzone from "react-dropzone";
import { resizeFile } from "../../utils/image.util";
import { SubscriptionPopup } from "../components/SubscriptionPopup";
import SubscriptionType from "../../interfaces/payment/SubscriptionType";
import {phonePattern} from "../../utils/validation.utils";
import useAnalyticsEventTracker from "../../hooks/useAnaliticsTracker";

type FormValues = {
  firstName: string;
  lastName: string;
  firmName: string;
  address: string;
  zip: string;
  city: string;
  www: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  description: string;
  phone: string;
};

export const SalonInfoScreen = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const [isEdited, setIsEdited] = useState(false);
  const [tempAvatar, setTempAvatar] = useState<string | null>(null);

  const isFetching: boolean = useSelector(
    (state: AppState) => state.userReducer.isFetching
  );

  const error: string | null = useSelector(
    (state: AppState) => state.userReducer.error
  );

  const user: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );

  const userProfile: UserType | null = useSelector(
    (state: AppState) => state.userReducer.userProfile
  );

  const avatar: string | null = useSelector(
    (state: AppState) => state.userReducer.avatar
  );

  const activeSubscription: SubscriptionType | null = useSelector(
    (state: AppState) => state.paymentReducer.activeSubscription
  );
  
  const gaEventTracker = useAnalyticsEventTracker('Profile');

  useEffect(() => {
    dispatch(getUser(user!.id));
  }, [dispatch]);

  useEffect(() => {
    if (avatar) {
      setTempAvatar(avatar);
    }
  }, [avatar]);

  useEffect(() => {
    if (isFetching && isEdited && !error) {
      setIsEdited(false);
    }
  }, [isFetching]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    //@ts-ignore
  } = useForm<FormValues>();

  const setInputValues = () => {
    if(userProfile) {
      setValue('firstName', userProfile.firstName ? userProfile.firstName : '');
      setValue('lastName', userProfile.lastName ? userProfile.lastName : '');
      setValue('firmName', userProfile.firmName ? userProfile.firmName : '');
      setValue('address', userProfile.address && userProfile.address.line1 ? userProfile.address.line1 : '');
      setValue('city', userProfile.address && userProfile.address.city ? userProfile.address.city : '');
      setValue('zip', userProfile.address && userProfile.address.zip ? userProfile.address.zip : '');
      setValue('phone', userProfile.phone ? userProfile.phone : '');
      setValue('www', userProfile.www ? userProfile.www : '');
      setValue('description', userProfile.description ? userProfile.description : '');
      setValue('facebook', userProfile.facebook ? userProfile.facebook : '');
      setValue('instagram', userProfile.instagram ? userProfile.instagram : '');
      setValue('tiktok', userProfile.tiktok ? userProfile.tiktok : '');
    }
  }

  useEffect(() => {
    setInputValues()
  }, [userProfile]);

  const onSubmit = (data: FormValues) => {
    dispatch(
      updateUser({
        ...data,
        id: user!.id,
        address: {
          line1: data.address,
          city: data.city,
          zip: data.zip,
        },
        facebook: data.facebook !== '' ? data.facebook : null,
        instagram: data.instagram !== '' ? data.instagram : null,
        tiktok: data.tiktok !== '' ? data.tiktok : null,
        www: data.www !== '' ? data.www : null,
      })
    );

    gaEventTracker("Update Profile");

    if (avatar !== tempAvatar && tempAvatar) {
      dispatch(updateAvatar(tempAvatar!));
      gaEventTracker("Update Avatar");
    }
  };

  const handleOnChange = (value: string, onChange: any) => {
    setIsEdited(true);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div id="salonInfoScreen">
      <form
        className={"salonInfoForm"}
        onSubmit={handleSubmit((data) => onSubmit(data))}
      >
        <Dropzone
          onDrop={(acceptedFiles) => {
            const base64 = resizeFile(acceptedFiles[0]);
            base64.then((result) => {
              setIsEdited(true);
              setTempAvatar(String(result).split(",")[1]);
            });
          }}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="salonAvatar">
              {tempAvatar && (
                <img
                  className="avatar"
                  src={`data:image/png;base64,${tempAvatar}`}
                  alt=""
                />
              )}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        <div className="formWrapper">
          <div className="firstRow">
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledTextInput
                  error={errors.firstName && errors.firstName.message}
                  value={value}
                  width={420}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("registrationScreen.firstName")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 35,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${35} ${t("formValidation.characters")}`,
                },
              }}
              defaultValue=""
            />
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.lastName && errors.lastName.message}
                  value={value}
                  width={420}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("registrationScreen.lastName")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 35,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${35} ${t("formValidation.characters")}`,
                },
              }}
              defaultValue=""
            />
            <Controller
              control={control}
              name="firmName"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.firmName && errors.firmName.message}
                  value={value}
                  width={420}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("registrationScreen.firmName")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 35,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${35} ${t("formValidation.characters")}`,
                },
              }}
              defaultValue=""
            />
            <Controller
              control={control}
              name="address"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.address && errors.address.message}
                  value={value}
                  width={420}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("registrationScreen.address")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 124,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${124} ${t("formValidation.characters")}`,
                },
              }}
              defaultValue=""
            />
            <div className="inputsRow">
              <Controller
                control={control}
                name="zip"
                render={({ field: { onChange, value } }) => (
                  <StyledTextInput
                    error={errors.zip && errors.zip.message}
                    value={value}
                    width={150}
                    mask="99-999"
                    onChange={(value: string) =>
                      handleOnChange(value, onChange)
                    }
                    label={t("registrationScreen.zip")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  validate: (value) =>
                    value.replace("_", t("formValidation.wrongZip")).length < 6
                      ? ""
                      : true,
                }}
                defaultValue=""
              />
              <Controller
                control={control}
                name="city"
                render={({ field: { onChange, value } }) => (
                  <StyledTextInput
                    error={errors.city && errors.city.message}
                    value={value}
                    onChange={(value: string) =>
                      handleOnChange(value, onChange)
                    }
                    label={t("registrationScreen.city")}
                  />
                )}
                rules={{
                  required: t("formValidation.required"),
                  maxLength: {
                    value: 45,
                    message: `${t(
                      "formValidation.textCannotBeLongerThan"
                    )} ${45} ${t("formValidation.characters")}`,
                  },
                }}
                defaultValue=""
              />
            </div>
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.phone && errors.phone.message}
                  value={value}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("salonInfoScreen.phone")}
                />
              )}
              rules={{
                required: t("formValidation.required"),
                maxLength: {
                  value: 15,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${15} ${t("formValidation.characters")}`,
                },
                pattern: {
                  value: phonePattern,
                  message: t("formValidation.wrongMobilePhone")
                }
              }}
              defaultValue=""
            />
          </div>
          <div className="secondRow">
            <Controller
              control={control}
              name="www"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <StyledTextInput
                  error={errors.www && errors.www.message}
                  value={value}
                  width={420}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("salonInfoScreen.www")}
                />
              )}
              rules={{
                maxLength: {
                  value: 255,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${255} ${t("formValidation.characters")}`,
                },
                validate: (value) =>
                  value && !validator.isURL(value)
                    ? t("formValidation.wrongURL")
                    : true,
              }}
              defaultValue=""
            />
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.description && errors.description.message}
                  value={value}
                  multiline={true}
                  style={{
                    height: 122,
                  }}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("salonInfoScreen.description")}
                />
              )}
              rules={{
                maxLength: {
                  value: 2000,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${2000} ${t("formValidation.characters")}`,
                },
              }}
              defaultValue=""
            />
            <Controller
              control={control}
              name="facebook"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.facebook && errors.facebook.message}
                  value={value}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("salonInfoScreen.facebook")}
                />
              )}
              rules={{
                maxLength: {
                  value: 255,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${255} ${t("formValidation.characters")}`,
                },
                validate: (value) =>
                  value && !validator.isURL(value)
                    ? t("formValidation.wrongURL")
                    : true,
              }}
              defaultValue=""
            />
            <Controller
              control={control}
              name="instagram"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.instagram && errors.instagram.message}
                  value={value}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("salonInfoScreen.instagram")}
                />
              )}
              rules={{
                maxLength: {
                  value: 255,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${255} ${t("formValidation.characters")}`,
                },
                validate: (value) =>
                  value && !validator.isURL(value)
                    ? t("formValidation.wrongURL")
                    : true,
              }}
              defaultValue=""
            />
            <Controller
              control={control}
              name="tiktok"
              render={({ field: { onChange, value } }) => (
                <StyledTextInput
                  error={errors.tiktok && errors.tiktok.message}
                  value={value}
                  onChange={(value: string) => handleOnChange(value, onChange)}
                  label={t("salonInfoScreen.tiktok")}
                />
              )}
              rules={{
                maxLength: {
                  value: 255,
                  message: `${t(
                    "formValidation.textCannotBeLongerThan"
                  )} ${255} ${t("formValidation.characters")}`,
                },
                validate: (value) =>
                  value && !validator.isURL(value)
                    ? t("formValidation.wrongURL")
                    : true,
              }}
              defaultValue=""
            />
            {isEdited && (
              <div className="buttonsContainer">
                <StyledButton
                  buttonText={t("global.cancel")}
                  type="button"
                  onClick={() => {
                    setInputValues()
                    setTempAvatar(avatar);
                    setIsEdited(false);
                  }}
                />
                <StyledButton buttonText={t("global.save")} type="submit" />
              </div>
            )}
          </div>
        </div>
      </form>
      {userProfile && userProfile.subscriptionStatus === "ACTIVE" && (
        <SubscriptionPopup subscription={activeSubscription} />
      )}
    </div>
  );
};
