/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import {
  TRAINING_ACTION_ERROR,
  GET_ALL_TRAININGS,
  GET_ALL_TRAININGS_SUCCESS,
  ADD_TRAINING,
  ADD_TRAINING_SUCCESS,
  ADD_TRAINING_USER,
  ADD_TRAINING_USER_SUCCESS,
  GET_TRAINING_USERS,
  GET_TRAINING_USERS_SUCCESS,
  DELETE_TRAINING_USER,
  DELETE_TRAINING,
  DELETE_TRAINING_USER_SUCCESS,
  DELETE_TRAINING_SUCCESS,
  UPDATE_TRAINING,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_TRAINING_USER,
  UPDATE_TRAINING_USER_SUCCESS,
  GENERATE_ONE_CERTIFICATE,
  GENERATE_ONE_CERTIFICATE_SUCCESS,
  GET_ALL_TRAININGS_WITH_TRAINING_USERS,
  GET_ALL_TRAININGS_WITH_TRAINING_USERS_SUCCESS,
  GENERATE_MANY_CERTIFICATES_SUCCESS,
  GENERATE_MANY_CERTIFICATES,
  DOES_LINK_EXIST,
  DOES_LINK_EXIST_SUCCESS,
  CREATE_LINK,
  SEND_LINK,
  SEND_LINK_SUCCESS,
} from "../saga/training/actions";
import { TrainingReducer } from "../interfaces/training/TrainingReducer";
import { TrainingActionTypes } from "../interfaces/training/TrainingActionTypes";

export const initialState: TrainingReducer = {
  isFetching: false,
  isFetchingTrainingUsers: false,
  error: null,
  trainings: [],
  trainingsPages: 0,
  trainingUsers: [],
  trainingUsersPages: 0,
  selectedTraining: null,
  trainingsWithTrainingUsers: [],
  trainingsWithTrainingUsersPages: 0,
  doesLinkExist: false
};

export const trainingReducer = (
  state: TrainingReducer = { ...initialState },
  action: TrainingActionTypes
): TrainingReducer => {
  switch (action.type) {
    case GET_ALL_TRAININGS:
      return { ...state, isFetching: true, error: null };
    case GET_ALL_TRAININGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        trainings: action.payload.trainings,
        trainingsPages: action.payload.pages,
      };
    case TRAINING_ACTION_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchingTrainingUsers: false,
        error: action.payload,
      };
    case ADD_TRAINING:
      return { ...state, isFetching: true, error: null };
    case ADD_TRAINING_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case ADD_TRAINING_USER:
      return {
        ...state,
        isFetchingTrainingUsers: true,
        error: null,
        selectedTraining: action.payload.training,
      };
    case ADD_TRAINING_USER_SUCCESS:
      return {
        ...state,
        isFetchingTrainingUsers: false,
      };
    case GET_TRAINING_USERS:
      return { ...state, isFetchingTrainingUsers: true, selectedTraining: action.payload.trainingId, error: null };
    case GET_TRAINING_USERS_SUCCESS:
      return {
        ...state,
        isFetchingTrainingUsers: false,
        trainingUsers: action.payload.trainingUsers,
        trainingUsersPages: action.payload.pages,
      };
    case GET_ALL_TRAININGS_WITH_TRAINING_USERS:
      return { ...state, error: null };
    case GET_ALL_TRAININGS_WITH_TRAINING_USERS_SUCCESS:
      return {
        ...state,
        trainingsWithTrainingUsers: action.payload.trainings,
        trainingsWithTrainingUsersPages: action.payload.pages,
      };
    case DELETE_TRAINING_USER:
      return { ...state, isFetchingTrainingUsers: true, error: null };
    case DELETE_TRAINING_USER_SUCCESS:
      return {
        ...state,
        isFetchingTrainingUsers: false,
        trainingUsers: state.trainingUsers.filter(
          (item) => item.id !== action.payload
        ),
      };
    case DELETE_TRAINING:
      return { ...state, isFetching: true, error: null };
    case DELETE_TRAINING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        trainings: state.trainings.filter((item) => item.id !== action.payload),
      };
    case UPDATE_TRAINING:
      return { ...state, isFetching: true, error: null };
    case UPDATE_TRAINING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        trainings: state.trainings.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case UPDATE_TRAINING_USER:
      return { ...state, isFetchingTrainingUsers: true, error: null };
    case UPDATE_TRAINING_USER_SUCCESS:
      return {
        ...state,
        isFetchingTrainingUsers: false,
        trainingUsers: state.trainingUsers.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case GENERATE_ONE_CERTIFICATE:
      return { ...state, isFetching: true }
    case GENERATE_ONE_CERTIFICATE_SUCCESS:
      return { ...state, isFetching: false }
    case GENERATE_MANY_CERTIFICATES:
      return { ...state, isFetching: true }
    case GENERATE_MANY_CERTIFICATES_SUCCESS:
      return { ...state, isFetching: false }
    case CREATE_LINK:
      return {...state, isFetching: true}
    case SEND_LINK:
      return {...state}
    case DOES_LINK_EXIST:
      return { ...state, isFetching: true }
    case DOES_LINK_EXIST_SUCCESS: 
      return { ...state, isFetching: false, doesLinkExist: action.payload }
    default:
      return state;
  }
};
