/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

export const appConstants = {
  TOKEN_KEY: "TOKEN_KEY",
  REFRESH_TOKEN_KEY: "REFRESH_TOKEN_KEY",
  TENANT_ID_KEY: "TENANT_ID_KEY",
  SHARED_PREFS: "visageSharedPrefs",
  KEYCHAIN: "visageKeychain",
};
