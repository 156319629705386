/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import { StyledSectionWrapper } from "./StyledSectionWrapper";
import { StyledButton } from "./StyledButton";
import LocalizationContext from "../../context/LocalizationContext";

interface Props {
  children: any;
  header: string;
  onAcceptButtonClick: () => void;
  onCloseButtonClick?: () => void;
  acceptButtonLabel: string;
  acceptButtonType?: "button" | "submit" | "reset" | undefined;
  withoutCancelButton?: boolean;
}

export const StyledModal = (props: Props) => {
  const {
    children,
    header,
    onCloseButtonClick,
    onAcceptButtonClick,
    acceptButtonLabel,
    acceptButtonType,
    withoutCancelButton,
  } = props;
  const { t } = React.useContext(LocalizationContext);
  return (
    <div className="background">
      <StyledSectionWrapper id="modalContent" label={header}>
        {children}
        <div className="buttonsContainer">
          <StyledButton
            buttonText={acceptButtonLabel}
            type={acceptButtonType ? acceptButtonType : "button"}
            onClick={onAcceptButtonClick}
          />
          {!withoutCancelButton && (
            <StyledButton
              buttonText={t("global.cancel")}
              type="button"
              onClick={onCloseButtonClick}
            />
          )}
        </div>
      </StyledSectionWrapper>
    </div>
  );
};
