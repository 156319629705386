/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import { StyledTextInput } from "../../components/StyledTextInput";
import { Controller, useForm } from "react-hook-form";
import LocalizationContext from "../../../context/LocalizationContext";
import { StyledDatePicker } from '../../components/StyledDatePicker';
import { useSelector, useDispatch } from 'react-redux';
import UserType from '../../../interfaces/user/UserType';
import { AppState } from '../../../store/store';
import { StyledButton } from '../../components/StyledButton';
import images from '../../../assets/images/images';
import Dropzone from 'react-dropzone';
import { resizeFile } from '../../../utils/color.util';
import { DateTime } from 'luxon';
import { StyledModal } from '../../components/StyledModal';
import { PageableQuery } from '../../../interfaces/pagination/PageableQuery';
import { generateManyCertificates, getAllTrainingsWithTrainingUsers } from '../../../saga/training/actions';
import TrainingsWithTrainingUsersType from '../../../interfaces/training/TrainingWithTrainingUsersType';
import { StyledDropdownSelectWithPagination } from '../../components/StyledDropdownSelectWithPagination';
import GenerateManyCertificatesType from '../../../interfaces/training/GenerateManyCertificatesType';
import { DefaultAvatar } from '../../components/DefaultAvatar';
import useAnalyticsEventTracker from '../../../hooks/useAnaliticsTracker';

interface Props {
  seGetManyCertificatesModalOpen: any
}
type FormValues = {
  stylistName: string;
  title: string;
  date: string;
  training: any;
  traineeNames: any;
};
export const GetManyCertificatesModal = (props: Props) => {
  const { t } = React.useContext(LocalizationContext);
  const userProfile: UserType | null = useSelector((state: AppState) => state.userReducer.userProfile);
  const avatar: string | null = useSelector((state: AppState) => state.userReducer.avatar);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue
    //@ts-ignore
  } = useForm<FormValues>({
    defaultValues: {
      stylistName: userProfile?.firstName + ' ' + userProfile?.lastName,
      date: DateTime.now().toString()
    }
  });
  const dispatch = useDispatch();
  const [tempAvatar, setTempAvatar] = useState<string | null>(null);
  const [trainingUserSearchQuery, setTrainingUserSearchQuery] = useState("");
  const [trainingUsers, setTrainingUsers] = useState([]);

  const authenticatedUser: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );
  const [selectedTraining, setSelectedTraining] = useState<any>(
    null
  );
  const trainingsWithTrainingUsers: TrainingsWithTrainingUsersType[] = useSelector(
    (state: AppState) => state.trainingReducer.trainingsWithTrainingUsers
  );
  const trainingsWithTrainingUsersPages: number = useSelector(
    (state: AppState) => state.trainingReducer.trainingsWithTrainingUsersPages
  );

  const gaEventTracker = useAnalyticsEventTracker('Certificates');
  
  async function loadTrainings(search: string, loadedOptions: any, { page }: any): Promise<any> {
    if (search) {
      setTrainingUserSearchQuery(search)
    }
    fetchTrainings(page, 10)

    return {
      options: trainingsWithTrainingUsers!.map(training => { return { value: training.id, label: training.title, trainingUsers: training.trainingUsers } }),
      hasMore: page <= trainingsWithTrainingUsersPages,
      additional: {
        page: page + 1,
      },
    };
  }

  useEffect(() => {
    if (avatar) {
      setTempAvatar(avatar);
    }
  }, [avatar]);

  useEffect(() => {
    const timeout = setTimeout(
      () => fetchTrainings(1, 10),
      trainingUserSearchQuery && trainingUserSearchQuery.length > 0 ? 500 : 0
    );

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, trainingUserSearchQuery]);

  useEffect(() => {
    const subscription = watch((opt) => {
      if (opt.training) {
        setSelectedTraining(opt.training.value);
        const results = opt.training.trainingUsers;
        setTrainingUsers(results);
      }
    })
    return () => subscription.unsubscribe();
  }, [props, watch])

  useEffect(() => {
    setValue('traineeNames', null)
  }, [selectedTraining])

  const fetchTrainings = (page: number, limit: number) => {
    let query: PageableQuery = {
      page,
      limit,
    };
    if (trainingUserSearchQuery && trainingUserSearchQuery.length > 0) {
      query = {
        ...query,
        search: trainingUserSearchQuery,
      };
    }
    dispatch(getAllTrainingsWithTrainingUsers(authenticatedUser!.id, query));
  };

  const onSubmit = (data: GenerateManyCertificatesType) => {
    dispatch(generateManyCertificates(data));
    gaEventTracker('Generate many certificates')
    props.seGetManyCertificatesModalOpen(false)
  }

  return (
    <form onSubmit={handleSubmit((data) =>
    (onSubmit({
      stylistName: data.stylistName,
      title: data.title,
      date: data.date,
      trainees: data.traineeNames.map((name: any) => { return { traineeName: name.value } }),
      avatar: tempAvatar
    } as any)))}>
      <StyledModal
        header={t("certificatesScreen.generateManyCertificates")}
        onAcceptButtonClick={() => { }}
        acceptButtonLabel={t("certificatesScreen.download")}
        acceptButtonType="submit"
        onCloseButtonClick={() => props.seGetManyCertificatesModalOpen(false)}
      >
        <div id="getMayCertificatesModalContent">
          <Controller
            control={control}
            name="stylistName"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.stylistName && errors.stylistName.message}
                value={value}
                width={"100%"}
                onChange={onChange}
                label={t("certificatesScreen.stylistName")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 70,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${70} ${t("formValidation.characters")}`,
              },
            }}
          />
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledTextInput
                error={errors.title && errors.title.message}
                value={value}
                width={"100%"}
                onChange={onChange}
                label={t("certificatesScreen.title")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 55,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${55} ${t("formValidation.characters")}`,
              },
            }}
          />
          <div style={{ display: "flex" }}>
            <div className="imageContainer">
              {!tempAvatar ?
                <DefaultAvatar />
                :
                <img
                  className="avatar"
                  src={`data:image/png;base64,${tempAvatar}`}
                  alt=""
                />
              }
              {tempAvatar &&
                <img
                  src={images.subtraction}
                  className="subtraction"
                  alt=""
                  onClick={() => setTempAvatar(null)}
                />
              }
            </div>
            <div style={{ flexShrink: 0, flexGrow: 1, marginBottom: "1.5em", maxWidth: "100%" }}>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  const base64 = resizeFile(acceptedFiles[0]);
                  base64.then((result) => {
                    setTempAvatar(String(result).split(",")[1]);
                  });
                }}
                maxFiles={1}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <div {...getRootProps()} className="dropzone">
                      <img src={images.uploadIcon} alt="" />
                      <span style={{ color: "#75615e", margin: "1em", wordBreak: "break-all" }}>{t("certificatesScreen.addLogo")}</span>
                      <StyledButton
                        buttonText={t("certificatesScreen.fileSelect")}
                        type="button"
                        onClick={() => { }} />
                    </div><input {...getInputProps()} />
                  </>
                )}
              </Dropzone>
            </div>
          </div>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledDatePicker
                error={errors.date && errors.date.message}
                value={value}
                onChange={onChange}
                label={t("certificatesScreen.date")}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: 35,
              validate: (value) =>
                value === "Invalid DateTime"
                  ? t("formValidation.wrongDate")
                  : true
            }}
          />
          <Controller
            control={control}
            name="training"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledDropdownSelectWithPagination
                isAsync
                value={value}
                loadOptions={loadTrainings}
                onChange={onChange}
                label={t("certificatesScreen.selectTraining")}
                error={errors.training ? t("formValidation.required") : null}
                additional={{
                  page: 2,
                }}
              />
            )}
            rules={{
              required: t("formValidation.required")
            }}
          />
          <Controller
            control={control}
            name="traineeNames"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <StyledDropdownSelectWithPagination
                label={t("certificatesScreen.selectTrainee")}
                value={value}
                loadOptions={trainingUsers.map((user: any) => { return { label: (user!.firstName + ' ' + user!.lastName), value: (user!.firstName + ' ' + user!.lastName) } })}
                isDisabled={!selectedTraining}
                isMulti
                onChange={onChange}
                error={(value && value.length) > 20 ? t("certificatesScreen.maxStudents") : errors.traineeNames ? t("formValidation.required") : null}
              />
            )}
            rules={{
              required: t("formValidation.required"),
              maxLength: {
                value: 70,
                message: `${t(
                  "formValidation.textCannotBeLongerThan"
                )} ${70} ${t("formValidation.characters")}`,
              },
            }}
          />
        </div>
      </StyledModal>
    </form>
  )
}
