/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import images from "../../assets/images/images";
import LocalizationContext from "../../context/LocalizationContext";

interface Props {
  value: string;
  additionalButton?: boolean;
  onChange?: (query: string) => void;
}

export const StyledSearchInput = (props: Props) => {
  const { value, onChange, additionalButton } = props;
  const { t } = React.useContext(LocalizationContext);

  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <Paper
      component="form"
      className="searchInput"
      style={{ marginLeft: additionalButton? 20: 150 }}
      sx={{ display: "flex", alignItems: "center"}}
    >
      <img className="searchInputIcon" src={images.searchIcon} alt="search" />
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={t("trainingsScreen.search")}
        inputProps={{ "aria-label": "search" }}
        value={value}
        onChange={handleOnChange}
      />
    </Paper>
  );
};
