/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { IconButton } from "@mui/material";
import images from "../../assets/images/images";
import colors from "../../assets/styles/_colors.module.scss";

interface Props {
  isActive: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const StyledStatusIcon = (props: Props) => {
  const { onClick, isActive, disabled } = props;
  return (
    <IconButton color="primary" onClick={onClick} disabled={disabled}>
      <div
        className="statusIcon"
        style={{ backgroundColor: isActive ? colors.white : colors.primary }}
      >
        <img
          src={isActive ? images.statusActive : images.statusInactive}
          alt=""
        />
      </div>
    </IconButton>
  );
};
