/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/
import CameraScreen from "../camera/CameraScreen";
// @ts-ignore
//import { OpenCvProvider, useOpenCv } from "opencv-react";
import eyebrowSource from "../../images/eyebrow2_color.png";
import { urlToBase64 } from "../../utils/image.util";
import { useEffect, useState } from "react";

export const FittingRoomScreen = () => {
  const [base64Image, setBase64Image] = useState("");

  useEffect(() => {
    urlToBase64(eyebrowSource, (base64) => {
      setBase64Image(base64.split(",")[1]);
    });
  }, []);

  return <CameraScreen base64Image={base64Image} />;
};
