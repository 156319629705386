/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import React, { useState } from "react";
import { Outlet, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { LoginScreen } from "./screens/pre-auth/login/LoginScreen";
import { RegistrationScreen } from "./screens/pre-auth/RegistrationScreen";
import { FittingRoomScreen } from "./screens/post-auth/FittingRoomScreen";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "./store/store";
import UserType from "./interfaces/user/UserType";
import { PageTemplate } from "./screens/hoc/PageTemplate";
import { TrainingsScreen } from "./screens/post-auth/training/TrainingsScreen";
import { SurveysScreen } from "./screens/post-auth/survey/SurveysScreen";
import { CertificatesScreen } from "./screens/post-auth/certificates/CertificatesScreen";
import { SalonInfoScreen } from "./screens/post-auth/SalonInfoScreen";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Dispatch } from "redux";
import { clearNetworkError, clearNetworkMessage } from "./saga/network/actions";
import { clearAuthenticatedUser, getAvatar, logout } from "./saga/user/actions";
import { ExceptionType } from "./enums/ExceptionType";
import { ExceptionUtil } from "./utils/exception.util";
import { getActiveSubscription } from "./saga/payment/actions";
import { ResetPasswordScreen } from "./screens/pre-auth/reset-password/ResetPasswordScreen";
import { SubscriptionsScreen } from "./screens/post-auth/subscription/SubscriptionsScreen";
import { UsersScreen } from "./screens/post-auth/users/UsersScreen";
import { UserRoleType } from "./enums/UserRoleType";
import { useQuery } from "./hooks/useQuery";
import { UserPaymentsScreen } from "./screens/post-auth/users/payments/UserPaymentsScreen";
import { StyledModal } from "./screens/components/StyledModal";
import LocalizationContext from "./context/LocalizationContext";
import SubscriptionType from "./interfaces/payment/SubscriptionType";
import { PageNotFoundScreen } from "./screens/PageNotFoundScreen";
import { MeetScreen } from "./screens/post-auth/meet/MeetScreen";
import ReactGA from 'react-ga';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const authenticatedUser: UserType | null = useSelector(
    (state: AppState) => state.userReducer.user
  );
  let query = useQuery();
  let token = query.get("token");

  const TRACKING_ID = "UA-246040069-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = "/dist/index.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!authenticatedUser) {
    return (
      <Routes>
        <Route path="/" element={<Layout user={authenticatedUser} />}>
          <Route index element={<LoginScreen />} />
          <Route path="registration" element={<RegistrationScreen />} />
          <Route path="eyebrows" element={<FittingRoomScreen />} />
          {/*poniższa linijka to obejście dla tymczasowej testówki tuneit.pl*/}
          <Route path="facemesh" element={<FittingRoomScreen />} />
          <Route path="meet/:id" element={<MeetScreen />} />
          <Route path="login" element={<LoginScreen />} />
          <Route path="reset-password" element={<ResetPasswordScreen />} />
          <Route
            path="verify-email"
            element={<Navigate to="/login" state={{ token: token }} />}
          />
        </Route>
        <Route path="/*" element={<PageNotFoundScreen />} />
      </Routes>
    );
  } else if (authenticatedUser.role === UserRoleType.STYLIST) {
    return (
      <Routes>
        <Route path="/login" element={<Navigate to="/trainings" />} />
        <Route path="/" element={<Layout user={authenticatedUser} />}>
          <Route index element={<Navigate to="/trainings" />} />
          <Route path="trainings" element={<TrainingsScreen />} />
          <Route path="surveys" element={<SurveysScreen />} />
          <Route path="meet/:id" element={<MeetScreen />} />
          <Route path="certificates" element={<CertificatesScreen />} />
          <Route path="salon" element={<SalonInfoScreen />} />
          <Route path="/*" element={<PageNotFoundScreen />} />
        </Route>
        <Route path="/eyebrows" element={<FittingRoomScreen />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/login" element={<Navigate to="/users" />} />
        <Route path="/" element={<Layout user={authenticatedUser} />}>
          <Route index element={<Navigate to="/users" />} />
          <Route path="users" element={<UsersScreen />} />
          <Route path="subscriptions" element={<SubscriptionsScreen />} />
          <Route path="payments/:id" element={<UserPaymentsScreen />} />
          <Route path="/*" element={<PageNotFoundScreen />} />
        </Route>
      </Routes>
    );
  }
}

function Layout(props: any) {
  const { t } = React.useContext(LocalizationContext);
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const [visibleError, setVisibleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const onToggleErrorSnackBar = (message: string) => {
    setVisibleError(true);
    setErrorMessage(message);
  };
  const onDismissErrorSnackBar = () => {
    dispatch(clearNetworkError());
    setVisibleError(false);
  };
  const onDismissMessageSnackBar = () => {
    dispatch(clearNetworkMessage());
  };
  const networkError: string = useSelector(
    (state: AppState) => state.networkReducer.error
  );

  const networkMessage: string = useSelector(
    (state: AppState) => state.networkReducer.message
  );

  const activeSubscription: SubscriptionType | null = useSelector(
    (state: AppState) => state.paymentReducer.activeSubscription
  );

  const isFetching: boolean = useSelector(
    (state: AppState) => state.paymentReducer.isFetching
  );

  const location = useLocation();

  React.useEffect(() => {
    let message = ExceptionUtil.getErrorMessage(networkError);
    if (message !== "") {
      onToggleErrorSnackBar(message);
      if (networkError === ExceptionType.TokenExpiredException) {
        dispatch(clearAuthenticatedUser());
      }
    }
  }, [networkError]);

  React.useEffect(() => {
    if (props.user && props.user.role === UserRoleType.STYLIST) {
      dispatch(getAvatar(props.user.id));
      dispatch(getActiveSubscription(props.user.id));
    }
  }, [props.user]);

  React.useEffect(() => {
    if (
      !activeSubscription &&
      props.user &&
      props.user.role === UserRoleType.STYLIST &&
      !isFetching
    ) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [isFetching]);

  return (
    <>
      {(!props.user || location.pathname.startsWith('/meet')) ? (
        <Outlet />
      ) : (
        <PageTemplate>
          <Outlet />
        </PageTemplate>
      )}
      <Snackbar
        open={visibleError}
        autoHideDuration={6000}
        onClose={onDismissErrorSnackBar}
      >
        <Alert
          onClose={onDismissErrorSnackBar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!networkMessage}
        autoHideDuration={6000}
        onClose={onDismissMessageSnackBar}
      >
        <Alert
          onClose={onDismissMessageSnackBar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {networkMessage}
        </Alert>
      </Snackbar>
      {modalVisible && (
        <StyledModal
          header={t("global.problemWithSubscription")}
          onAcceptButtonClick={() => {
            setModalVisible(false);
            dispatch(logout());
            navigate("/login");
          }}
          acceptButtonLabel={t("appBar.logout")}
          acceptButtonType="button"
          withoutCancelButton={true}
        >
          <div className="deleteModalContent">
            <span>{t("global.noActiveSubscription")}</span>
          </div>
        </StyledModal>
      )}
    </>
  );
}

export default App;
