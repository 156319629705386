/*******************************************************************************
 * Autorskie Prawa Majątkowe - ARHORIZON Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 ARHORIZON Spółka z ograniczoną odpowiedzialnością
 ******************************************************************************/

import { DateTime } from "luxon";
import React from "react";
import images from "../../../assets/images/images";
import LocalizationContext from "../../../context/LocalizationContext";
import { TrainingTypeType } from "../../../enums/TrainingTypeType";
import useAnalyticsEventTracker from "../../../hooks/useAnaliticsTracker";
import TrainingType from "../../../interfaces/training/TrainingType";
import { StyledButton } from "../../components/StyledButton";

interface Props {
  training: TrainingType;
  onDelete: () => void;
  onEdit: () => void;
  onGenerateLink: () => void;
  setWithOpenNewMeeTab: any;
}

export const TrainingInfo = (props: Props) => {
  const { training, onDelete, onEdit, onGenerateLink, setWithOpenNewMeeTab } =
    props;
  const { t } = React.useContext(LocalizationContext);
  const gaEventTracker = useAnalyticsEventTracker('Training');

  return (
    <div id="trainingInfoContainer">
      <div className="trainingMainInfo">
        {training.avatar ? (
          <img
            src={`data:image/png;base64,${training.avatar}`}
            className="trainingAvatar"
            alt=""
          />
        ) : (
          <div className="trainingAvatarContainer" />
        )}

        <div className="titleContainer">
          <span id="trainingTitleSpan">{training.title}</span>
          {training.startDate && (
            <div className="dateContainer">
              <span>
                {DateTime.fromISO(training.startDate).toFormat("dd.LL.yy")}
              </span>
              <span>
                {DateTime.fromISO(training.startDate).toFormat("HH:mm")}
              </span>
            </div>
          )}
        </div>
        <div className="topRightContainer">
          <div className="row">
            <img className="icon" src={images.priceTagIcon} alt="priceTag" />
            <span className="iconText">{`${Number(training.price).toFixed(
              2
            )} ${t("subscriptionScreen.currency")}`}</span>
          </div>
          <div className="row">
            <img className="icon" src={images.clockIcon} alt="clockIcon" />
            <span className="iconText">{`${training.duration} ${t(
              "trainingsScreen.minutes"
            )}`}</span>
          </div>
        </div>
      </div>
      <div className="descriptionContainer">
        <span>{t("trainingsScreen.details")}</span>
        <p>{training.description}</p>
      </div>
      <div className="contactContainer">
        {training.phone && (
          <div className="contactRow">
            <img src={images.phoneIcon} alt="" />
            <span>{training.phone}</span>
          </div>
        )}
        {training.email && (
          <div className="contactRow">
            <img src={images.emailIcon} alt="" />
            <span>{training.email}</span>
          </div>
        )}
        {training.link && (
          <div className="contactRow">
            <img src={images.wwwIcon} alt="" />
            <span>{training.link}</span>
          </div>
        )}
      </div>
      <div className="buttonsContainer">
        <StyledButton
          buttonText={t("global.delete")}
          type="button"
          onClick={onDelete}
        />
        <StyledButton
          buttonText={t("global.edit")}
          type="button"
          onClick={onEdit}
        />
        {training.type === TrainingTypeType.LIVE && (
          <>
            <StyledButton
              buttonText={t("trainingsScreen.startTraining")}
              type="button"
              onClick={() => {
                if (training.link) {
                  gaEventTracker("Start live training");
                  window.open(training.link, "_blank");
                } else {
                  setWithOpenNewMeeTab(true);
                  onGenerateLink();
                }
              }}
            />
            <StyledButton
              buttonText={t("trainingsScreen.generateLink")}
              type="button"
              onClick={onGenerateLink}
            />
          </>
        )}
      </div>
    </div>
  );
};
